import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalInstance } from './../msal';
import { ZService } from 'global/_zcom/ZSettingsfn';

export class AADAuth {
  static instance = null;
  scope = ''; //'api://491869e7-98d7-45ef-956e-2705d39904d7/user_impersonation'
  auth = ''; //'https://login.microsoftonline.com/microsoft.onmicrosoft.com'
  clientId = ''; //'491869e7-98d7-45ef-956e-2705d39904d7'

  msalI = null;
  msalLoggedOut = !localStorage.getItem('azureconfigurl') || (localStorage.getItem('msalLoggedOut') ?? 'false') === 'true';

  static xinit() {
    if (AADAuth.instance === null) {
      AADAuth.instance = new AADAuth();
    }

    return AADAuth.instance;
  }

  init() {
    ZService.debug(null, 'AADAuth.init');
    return AADAuth.xinit();
  }

  setScopeAuthId(scope: string, auth: string, clientId: string) {
    this.scope = scope;
    this.auth = auth;
    this.clientId = clientId;
    this.tokenRequest = { scopes: [this.scope] };
  }

  setLogged(isLoggedIn: boolean) {
    this.init();
    this.msalLoggedOut = !isLoggedIn;
    localStorage.setItem('msalLoggedOut', isLoggedIn ? 'false' : 'true');
  }

  tokenRequest = {
    scopes: [this.scope]
  };

  getInstance(forced = false): Promise<PublicClientApplication> {
    this.init();
    return new Promise(async (resolve, reject) => {
      if (this.msalLoggedOut && !forced) {
        return reject(new Error('MSAL is logged out'));
      }
      if (this.msalI === null) {
        this.msalI = await getMsalInstance(this.clientId, this.auth);
        await this.msalI.handleRedirectPromise();
      }
      resolve(this.msalI);
    });
  }

  async ckLoginPopup(tokenRequest) {
    this.init();
    if (!this.msalLoggedOut) {
      try {
        return await this.msalI.loginPopup(tokenRequest);
      }
      catch (err) {
        this.setLogged(false);
        throw err;
      }
    }

    return false;
  }

  async getAccountInfo() {
    this.init();
    if (this.msalLoggedOut) {
      return {};
    }

    if (this.msalI === null) {
      this.msalI = await getMsalInstance(this.clientId, this.auth);
    }
    const accounts = this.msalI.getAllAccounts();
    if (accounts.length === 0) {
      await this.ckLoginPopup(this.tokenRequest);
    }
    return this.msalI.getAccountByHomeId(accounts[0].homeAccountId);
  }

  async getMyUserName() {
    this.init();
    const accountInfo = await this.getAccountInfo();
    return accountInfo?.username;
  }

  async getMyName() {
    this.init();
    const accountInfo = await this.getAccountInfo();
    return accountInfo?.name;
  }

  async hasRole(role: string) {
    this.init();
    const accountInfo = await this.getAccountInfo();
    return accountInfo.idTokenClaims.roles.indexOf(role) > -1;
  }

  async getToken() {
    this.init();
    return new Promise(async (resolve, reject) => {
      // is user logged in?

      if (this.msalLoggedOut) {
        return reject(new Error('MSAL is logged out'));
      }

      if (this.msalI === null) {
        this.msalI = await getMsalInstance(this.clientId, this.auth);
      }
      const account = this.msalI.getAllAccounts()[0];
      if (account) {
        try {
          const response = await this.msalI.acquireTokenSilent({ ...this.tokenRequest, account });
          resolve(response.accessToken);
        } catch (err) {
          if (err.name === "InteractionRequiredAuthError") {
            try {
              const response = await this.msalI.acquireTokenPopup(this.tokenRequest);
              resolve(response.accessToken);
            } catch (err) {
              reject(err);
            }
          } else {
            reject(err);
          }
        }
      } else {
        try {
          await this.ckLoginPopup(this.tokenRequest);
          resolve(await this.getToken());
        } catch (err) {
          reject(err);
        }
      }
    });
  }

  async forceLogin(afterLogin: () => void) {
    this.init();
    localStorage.setItem('msalLoggedOut', 'false');
    this.msalLoggedOut = false;
    return await this.login(afterLogin);
  }

  async login(afterLogin: () => void, forced = false) {
    this.init();
    if (this.msalLoggedOut && !forced) {
      return;
    }
    const msalInstance = await this.getInstance(forced);
    msalInstance.loginPopup(this.tokenRequest)
      .then(response => {
        console.log('Login successful', response);
        afterLogin();
        // You can update the UI or store the user info here
      })
      .catch(err => {
        alert('Login failed - you may need to allow popups on this site (above), login, refresh the page');
        console.error('Login error', err);
        this.setLogged(false);
      });
  }

  async isLoggedIn() {
    this.init();
    try {
      const msalInstance = await this.getInstance();
      return !!msalInstance?.getAllAccounts()?.length;
    }
    catch (err) {
      return false;
    }
  }

  async logout(afterLogout: () => void) {
    this.init();
    const msalInstance = await this.getInstance();
    this.msalLoggedOut = true;
    localStorage.setItem('msalLoggedOut', 'true');
    msalInstance.logoutPopup()
      .then(response => {
        console.log('Logout successful', response);
        afterLogout();
        // You can update the UI or remove the user info here
      })
      .catch(err => {
        console.error('Logout error', err);
      });
  }

  //--------------------//

  async authRedirected() {
    this.init();
    //nothing?
  }
}
