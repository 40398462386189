import { Model } from "./Model";
import { OpenAIComm } from "./OpenAIComm";
import { ZService } from "./ZSettingsfn";

export class DirectOpenAIComm extends OpenAIComm {
  constructor(name: string, urlBase: string, model: string, key: string, proxyURL?: string) {
    super(name, urlBase, model, key, proxyURL);
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    // base implementation
    console.warn('getModelsClass not implemented:' + service.commType);
    const models: Model[] = [];
    this.addInServiceModels(models, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    return models;
  }

  updateData(data, apiURL, proxyURL): string {
    if (this.proxyURL) {
      // swap out the URL
      data.proxyURL = apiURL;
      return proxyURL;
    }

    return apiURL;
  }
}
