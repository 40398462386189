import { Comm } from "./Comm";
import { Model } from "./Model";
import { OpenAIComm } from "./OpenAIComm";
import { ZService } from "./ZSettingsfn";

export class OpenRouterComm extends OpenAIComm {
  constructor(name: string, urlBase: string, model: string, key: string) {
    super(name, urlBase, model, key);
    this.canHandleSpecialAttachments = false;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    const json = await Comm.getAllModelsJson();
    this.keyUrl = json.openrouter.keyUrl;
    const models = Model.loadModels(json.openrouter, this);
    // this.addInServiceModels(models, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    this.isConnected = true;
    return models;
  }
}
