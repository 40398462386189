import { Model, ModelSets } from "./Model";
import { Storage } from '../Storage';
import { ZService } from "./ZSettingsfn";
import { DirectEndpointAIComm } from "./DirectEndpointAIComm";
import { CoPilotComm } from "./CoPilotComm";
import { AzureOpenAIComm } from "./AzureOpenAIComm";
import { OpenAIComm } from "./OpenAIComm";
import { OpenRouterComm } from "./OpenRouterComm";
import { GroqAIComm } from "./GroqAIComm";
import { UrlCache } from "./UrlCache";
import { DirectOpenAIComm } from "./DirectOpenAIComm";
import { BrowserComm } from "./BrowserComm";
import { HuggingFaceComm } from "./HuggingFaceComm";

export class Comm {
  static allModels: any = null;
  static isLoading: boolean = false;

  static getCommForModel(model: Model) {
    if (model.comm && model.comm.call) {
      return model.comm;
    }

    const service = ModelSets.getService(model);
    model.comm = Comm.getCommForService(service);

    return model.comm; // Comm.getCommForServiceName(model.service.id, model.id);
  }

  static getCommForService(service: ZService) {
    if (!service || !service.commType) {
      return null;
    }

    const cx = Comm.getCommForServiceName(service.commType, null, service);
    return cx;
  }

  static async getAllModelsJson() {
    let data: any = null;

    if (this.allModels != null) {
      return this.allModels;
    }

    var count = 0;
    while (this.isLoading && count < 20) {
      await new Promise(resolve => setTimeout(resolve, 50));
      ++count;
    }

    if (this.allModels != null) {
      return this.allModels;
    }

    try {
      this.isLoading = true;
      data = await fetch('/assets/data/allmodels.json')
        .then((response: Response) => response.json());

      // load openrouter models fresh
      const openrouter = await UrlCache.getSet('openrouter-models', () => this.getOpenRouterModels(), 60);
      data.openrouter.data = openrouter ? openrouter.data : [];
      this.allModels = data;
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }

    return this.allModels;
  }

  static async getOpenRouterModels() {
    const key = ModelSets.getKeyFor('openrouter') ?? Storage.get('openrouter-key', '');
    if (!key || key.length === 0) {
      return null;
    }

    const url = 'https://openrouter.ai/api/v1/models';

    // Create a timeout promise
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        reject(new Error('Request timed out'));
      }, 10000); // 10 seconds
    });

    // Create the fetch promise
    const fetchPromise = fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': key
      }
    }).then(response => response.json());

    // Race the fetch promise against the timeout promise
    try {
      const data = await Promise.race([fetchPromise, timeoutPromise]);
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  static sortModels(models: Model[]) {

    if (!models) return [];

    const plus = ['gpt-4', 'turbo'];
    const minus = ['older'];

    const modelSortKey = (model: Model) => {
      if (!model.architecture || !model.architecture.modality) {
        return model.id ?? '';
      }

      // get first character of modality, convert to number
      const mode = model.architecture.modality;
      var modeNum = '9';
      switch (mode) {
        case 'multimodal': { modeNum = '0'; break; }
        case 'text': { modeNum = '1'; break; }
        case 'chat': { modeNum = '1'; break; }
        case 'video': { modeNum = '2'; break; }
        case 'voice': { modeNum = '3'; break; }
        case 'audio': { modeNum = '4'; break; }
        case 'embedding': { modeNum = '5'; break; }
        default: { modeNum = '9'; break; }
      }
      // const num = 100 - (chr.charCodeAt(0) - 64);

      const xPlus = 10 - plus.filter(p => model.id.includes(p) || model.name.includes(p)).length;
      const xMinus = minus.filter(p => model.id.includes(p) || model.name.includes(p)).length;

      return `${modeNum}${xPlus}${xMinus}${model.id}`;
    };

    return models.sort((a, b) => {
      return modelSortKey(a).localeCompare(modelSortKey(b));
    });
  }

  static getCommForServiceName(serviceId: string, modelId = null, service: ZService = null) {
    let name = serviceId, urlBase, key, modelx = modelId;
    // new-service:01
    switch (serviceId) {
      case 'openai':
        urlBase = 'https://api.openai.com/v1';
        // const url = 'https://api.openai.com/v1/chat/completions';
        key = service ? service.apiKey : Storage.get('openai-key', '');
        return this.getOpenAIComm(name, urlBase, modelx, key);
      case 'openrouter':
        urlBase = 'https://openrouter.ai/api/v1';
        key = service ? service.apiKey : Storage.get('openrouter-key', '');
        return this.getOpenRouterComm(name, urlBase, modelx, key);
      case 'groq':
        // https://api.groq.com/openai/v1/chat/completions
        urlBase = 'https://api.groq.com/openai/v1';
        key = service ? service.apiKey : Storage.get('groq-key', '');
        return this.getGroqAIComm(name, urlBase, modelx, key);
      case 'azureopenai':
        urlBase = service.apiURL;
        key = service.apiKey;
        var comm = this.getAzureOpenAIComm(name, urlBase, modelx, key);
        comm.apiVersion = service.apiVersion;
        return comm;
      case 'azureml':
      case 'direct':
        urlBase = service.apiURL;
        key = service.apiKey;
        var comm2 = service.commStyle === 'openai' ?
          this.getDirectOpenAIEndpoint(name, urlBase, modelx, key, service.proxyURL) :
          this.getDirectEndpoint(name, urlBase, modelx, key, service.proxyURL);
        comm2.proxyKey = service.proxyKey;
        comm2.proxyURL = comm2.proxyURL ?? service.proxy;
        //serviceparams:04
        comm2.header1 = service.header1;
        comm2.header2 = service.header2;
        comm2.header3 = service.header3;
        return comm2;
      case 'copilot':
        urlBase = service.apiURL;
        key = service.apiKey;
        var comm3 = this.getCoPilotEndpoint(name, urlBase, modelx, key);
        comm3.proxyKey = service.proxyKey;
        comm3.proxyURL = comm3.proxyURL ?? service.proxy;
        return comm3;
      case 'browser':
        urlBase = service.apiURL;
        var commBrowser = this.getBrowserCommEndpoint(name, urlBase);
        return commBrowser;
      case 'huggingface':
        urlBase = 'https://api-inference.huggingface.co';
        key = service ? service.apiKey : Storage.get('huggingface-key', '');
        return this.getHuggingFaceComm(name, urlBase, modelx, key);
      default:
        console.error('Unknown service: ' + serviceId);
        return null;
    }
  }

  static getOpenAIComm(name, urlBase, model, key) {
    return new OpenAIComm(name, urlBase, model, key);
  }

  static getGroqAIComm(name, urlBase, model, key) {
    return new GroqAIComm(name, urlBase, model, key);
  }

  static getOpenRouterComm(name, urlBase, model, key) {
    return new OpenRouterComm(name, urlBase, model, key);
  }

  static getAzureOpenAIComm(name, urlBase, model, key) {
    return new AzureOpenAIComm(name, urlBase, model, key);
  }

  static getDirectEndpoint(name, urlBase, model, key, proxyURL) {
    console.log('using direct endpoint for ' + name + ' ' + urlBase + ' ' + model);
    return new DirectEndpointAIComm(name, urlBase, model, key, true, proxyURL);
  }

  static getDirectOpenAIEndpoint(name, urlBase, model, key, proxyURL) {
    console.log('using directopenai endpoint for ' + name + ' ' + urlBase + ' ' + model);
    return new DirectOpenAIComm(name, urlBase, model, key, proxyURL);
  }

  static getCoPilotEndpoint(name, urlBase, model, key) {
    return new CoPilotComm(name, urlBase, model, key);
  }

  static getBrowserCommEndpoint(name, urlBase) {
    return new BrowserComm(name, urlBase, null, null);
  }

  static getHuggingFaceComm(name, urlBase, model, key) {
    return new HuggingFaceComm(name, urlBase, model, key);
  }
}
