import { ZService } from 'global/_zcom/ZSettingsfn';

export class NullAuth {
  setLogged(isLoggedIn: boolean) {
    //nothing
  }

  getMyName() {
    return 'NullAuth';
  }

  getMyUserName() {
    return 'NullAuth';
  }

  isLoggedIn() {
    return false;
  }

  forceLogin(afterLogin: () => void) {
    //nothing
  }

  logout(afterLogout: () => void) {
    //nothing
  }

  login(afterLogin: () => void) {
    //nothing
  }
}

export class ProxyAuth extends NullAuth {
  static instance = null;
  scope = '';
  auth = '';
  clientId = '';
  tenantId = '';
  proxyUrl = '';
  token = '';

  isLoggedOut = (localStorage.getItem('proxyconfigurl') ?? 'false') === 'true';

  static xinit() {
    if (ProxyAuth.instance === null) {
      ProxyAuth.instance = new ProxyAuth();
    }

    return ProxyAuth.instance;
  }

  init() {
    ZService.debug(null, 'ProxyAuth.init');
    return ProxyAuth.xinit();
  }

  setScopeAuthId(scope: string, auth: string, clientId: string, tenantId: string = null, proxyUrl: string = null) {
    this.scope = scope;
    this.auth = auth;
    this.clientId = clientId;
    this.tenantId = tenantId;
    this.proxyUrl = proxyUrl;
    this.tokenRequest = { scopes: [this.scope] };
  }

  tokenRequest = {
    scopes: [this.scope]
  };

  isLoggedIn() {
    return this.token && this.token.length > 0;
  }

  async getToken() {
    this.init();

    if (this.token && this.token.length > 0) {
      console.log('got token already');
      return new Promise((resolve, reject) => {
        resolve(this.token);
      });
    }

    // call proxyUrl with clientId, auth, tenantId, scope
    // return the token
    return new Promise(async (resolve, reject) => {
      // is user logged in?

      if (this.isLoggedOut) {
        return reject(new Error('Proxy is logged out'));
      }

      if (this.proxyUrl === null) {
        return reject(new Error('Proxy URL is not set'));
      }

      if (this.proxyUrl.startsWith('ws')) {
        this.proxyUrl = 'http://' + this.proxyUrl.substring(5);
      }

      //debugger;
      const url = `${this.proxyUrl}/token?clientId=${encodeURIComponent(this.clientId)}&auth=${encodeURIComponent(this.auth)}&tenantId=${encodeURIComponent(this.tenantId)}&scope=${encodeURIComponent(this.scope)}`;
      fetch(url)
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          // read the token (response.body)
          const data = await response.text();
          this.token = data;
          console.log('got token');
          resolve(data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  //--------------------//

  async authRedirected() {
    this.init();
    //nothing?
  }
}