/*
Language: GML
Description: Game Maker Language for GameMaker (rev. 2023.1)
Website: https://manual.yoyogames.com/
Category: scripting
*/

function gml(hljs) {
  const KEYWORDS = [
    "#endregion",
    "#macro",
    "#region",
    "and",
    "begin",
    "break",
    "case",
    "constructor",
    "continue",
    "default",
    "delete",
    "div",
    "do",
    "else",
    "end",
    "enum",
    "exit",
    "for",
    "function",
    "globalvar",
    "if",
    "mod",
    "new",
    "not",
    "or",
    "repeat",
    "return",
    "static",
    "switch",
    "then",
    "until",
    "var",
    "while",
    "with",
    "xor"
  ];

  const BUILT_INS = [
    "abs",
    "alarm_get",
    "alarm_set",
    "angle_difference",
    "animcurve_channel_evaluate",
    "animcurve_channel_new",
    "animcurve_create",
    "animcurve_destroy",
    "animcurve_exists",
    "animcurve_get",
    "animcurve_get_channel",
    "animcurve_get_channel_index",
    "animcurve_point_new",
    "ansi_char",
    "application_get_position",
    "application_surface_draw_enable",
    "application_surface_enable",
    "application_surface_is_enabled",
    "arccos",
    "arcsin",
    "arctan",
    "arctan2",
    "array_all",
    "array_any",
    "array_concat",
    "array_contains",
    "array_contains_ext",
    "array_copy",
    "array_copy_while",
    "array_create",
    "array_create_ext",
    "array_delete",
    "array_equals",
    "array_filter",
    "array_filter_ext",
    "array_find_index",
    "array_first",
    "array_foreach",
    "array_get",
    "array_get_index",
    "array_insert",
    "array_intersection",
    "array_last",
    "array_length",
    "array_map",
    "array_map_ext",
    "array_pop",
    "array_push",
    "array_reduce",
    "array_resize",
    "array_reverse",
    "array_reverse_ext",
    "array_set",
    "array_shuffle",
    "array_shuffle_ext",
    "array_sort",
    "array_union",
    "array_unique",
    "array_unique_ext",
    "asset_add_tags",
    "asset_clear_tags",
    "asset_get_ids",
    "asset_get_index",
    "asset_get_tags",
    "asset_get_type",
    "asset_has_any_tag",
    "asset_has_tags",
    "asset_remove_tags",
    "audio_bus_clear_emitters",
    "audio_bus_create",
    "audio_bus_get_emitters",
    "audio_channel_num",
    "audio_create_buffer_sound",
    "audio_create_play_queue",
    "audio_create_stream",
    "audio_create_sync_group",
    "audio_debug",
    "audio_destroy_stream",
    "audio_destroy_sync_group",
    "audio_effect_create",
    "audio_emitter_bus",
    "audio_emitter_create",
    "audio_emitter_exists",
    "audio_emitter_falloff",
    "audio_emitter_free",
    "audio_emitter_gain",
    "audio_emitter_get_bus",
    "audio_emitter_get_gain",
    "audio_emitter_get_listener_mask",
    "audio_emitter_get_pitch",
    "audio_emitter_get_vx",
    "audio_emitter_get_vy",
    "audio_emitter_get_vz",
    "audio_emitter_get_x",
    "audio_emitter_get_y",
    "audio_emitter_get_z",
    "audio_emitter_pitch",
    "audio_emitter_position",
    "audio_emitter_set_listener_mask",
    "audio_emitter_velocity",
    "audio_exists",
    "audio_falloff_set_model",
    "audio_free_buffer_sound",
    "audio_free_play_queue",
    "audio_get_listener_count",
    "audio_get_listener_info",
    "audio_get_listener_mask",
    "audio_get_master_gain",
    "audio_get_name",
    "audio_get_recorder_count",
    "audio_get_recorder_info",
    "audio_get_type",
    "audio_group_get_assets",
    "audio_group_get_gain",
    "audio_group_is_loaded",
    "audio_group_load",
    "audio_group_load_progress",
    "audio_group_name",
    "audio_group_set_gain",
    "audio_group_stop_all",
    "audio_group_unload",
    "audio_is_paused",
    "audio_is_playing",
    "audio_listener_get_data",
    "audio_listener_orientation",
    "audio_listener_position",
    "audio_listener_set_orientation",
    "audio_listener_set_position",
    "audio_listener_set_velocity",
    "audio_listener_velocity",
    "audio_master_gain",
    "audio_pause_all",
    "audio_pause_sound",
    "audio_pause_sync_group",
    "audio_play_in_sync_group",
    "audio_play_sound",
    "audio_play_sound_at",
    "audio_play_sound_ext",
    "audio_play_sound_on",
    "audio_queue_sound",
    "audio_resume_all",
    "audio_resume_sound",
    "audio_resume_sync_group",
    "audio_set_listener_mask",
    "audio_set_master_gain",
    "audio_sound_gain",
    "audio_sound_get_audio_group",
    "audio_sound_get_gain",
    "audio_sound_get_listener_mask",
    "audio_sound_get_loop",
    "audio_sound_get_loop_end",
    "audio_sound_get_loop_start",
    "audio_sound_get_pitch",
    "audio_sound_get_track_position",
    "audio_sound_is_playable",
    "audio_sound_length",
    "audio_sound_loop",
    "audio_sound_loop_end",
    "audio_sound_loop_start",
    "audio_sound_pitch",
    "audio_sound_set_listener_mask",
    "audio_sound_set_track_position",
    "audio_start_recording",
    "audio_start_sync_group",
    "audio_stop_all",
    "audio_stop_recording",
    "audio_stop_sound",
    "audio_stop_sync_group",
    "audio_sync_group_debug",
    "audio_sync_group_get_track_pos",
    "audio_sync_group_is_paused",
    "audio_sync_group_is_playing",
    "audio_system_is_available",
    "audio_system_is_initialised",
    "base64_decode",
    "base64_encode",
    "bool",
    "browser_input_capture",
    "buffer_async_group_begin",
    "buffer_async_group_end",
    "buffer_async_group_option",
    "buffer_base64_decode",
    "buffer_base64_decode_ext",
    "buffer_base64_encode",
    "buffer_compress",
    "buffer_copy",
    "buffer_copy_from_vertex_buffer",
    "buffer_copy_stride",
    "buffer_crc32",
    "buffer_create",
    "buffer_create_from_vertex_buffer",
    "buffer_create_from_vertex_buffer_ext",
    "buffer_decompress",
    "buffer_delete",
    "buffer_exists",
    "buffer_fill",
    "buffer_get_address",
    "buffer_get_alignment",
    "buffer_get_size",
    "buffer_get_surface",
    "buffer_get_type",
    "buffer_load",
    "buffer_load_async",
    "buffer_load_ext",
    "buffer_load_partial",
    "buffer_md5",
    "buffer_peek",
    "buffer_poke",
    "buffer_read",
    "buffer_resize",
    "buffer_save",
    "buffer_save_async",
    "buffer_save_ext",
    "buffer_seek",
    "buffer_set_surface",
    "buffer_set_used_size",
    "buffer_sha1",
    "buffer_sizeof",
    "buffer_tell",
    "buffer_write",
    "call_cancel",
    "call_later",
    "camera_apply",
    "camera_copy_transforms",
    "camera_create",
    "camera_create_view",
    "camera_destroy",
    "camera_get_active",
    "camera_get_begin_script",
    "camera_get_default",
    "camera_get_end_script",
    "camera_get_proj_mat",
    "camera_get_update_script",
    "camera_get_view_angle",
    "camera_get_view_border_x",
    "camera_get_view_border_y",
    "camera_get_view_height",
    "camera_get_view_mat",
    "camera_get_view_speed_x",
    "camera_get_view_speed_y",
    "camera_get_view_target",
    "camera_get_view_width",
    "camera_get_view_x",
    "camera_get_view_y",
    "camera_set_begin_script",
    "camera_set_default",
    "camera_set_end_script",
    "camera_set_proj_mat",
    "camera_set_update_script",
    "camera_set_view_angle",
    "camera_set_view_border",
    "camera_set_view_mat",
    "camera_set_view_pos",
    "camera_set_view_size",
    "camera_set_view_speed",
    "camera_set_view_target",
    "ceil",
    "choose",
    "chr",
    "clamp",
    "clickable_add",
    "clickable_add_ext",
    "clickable_change",
    "clickable_change_ext",
    "clickable_delete",
    "clickable_exists",
    "clickable_set_style",
    "clipboard_get_text",
    "clipboard_has_text",
    "clipboard_set_text",
    "cloud_file_save",
    "cloud_string_save",
    "cloud_synchronise",
    "code_is_compiled",
    "collision_circle",
    "collision_circle_list",
    "collision_ellipse",
    "collision_ellipse_list",
    "collision_line",
    "collision_line_list",
    "collision_point",
    "collision_point_list",
    "collision_rectangle",
    "collision_rectangle_list",
    "color_get_blue",
    "color_get_green",
    "color_get_hue",
    "color_get_red",
    "color_get_saturation",
    "color_get_value",
    "colour_get_blue",
    "colour_get_green",
    "colour_get_hue",
    "colour_get_red",
    "colour_get_saturation",
    "colour_get_value",
    "cos",
    "darccos",
    "darcsin",
    "darctan",
    "darctan2",
    "date_compare_date",
    "date_compare_datetime",
    "date_compare_time",
    "date_create_datetime",
    "date_current_datetime",
    "date_date_of",
    "date_date_string",
    "date_datetime_string",
    "date_day_span",
    "date_days_in_month",
    "date_days_in_year",
    "date_get_day",
    "date_get_day_of_year",
    "date_get_hour",
    "date_get_hour_of_year",
    "date_get_minute",
    "date_get_minute_of_year",
    "date_get_month",
    "date_get_second",
    "date_get_second_of_year",
    "date_get_timezone",
    "date_get_week",
    "date_get_weekday",
    "date_get_year",
    "date_hour_span",
    "date_inc_day",
    "date_inc_hour",
    "date_inc_minute",
    "date_inc_month",
    "date_inc_second",
    "date_inc_week",
    "date_inc_year",
    "date_is_today",
    "date_leap_year",
    "date_minute_span",
    "date_month_span",
    "date_second_span",
    "date_set_timezone",
    "date_time_of",
    "date_time_string",
    "date_valid_datetime",
    "date_week_span",
    "date_year_span",
    "db_to_lin",
    "dbg_add_font_glyphs",
    "dbg_button",
    "dbg_checkbox",
    "dbg_color",
    "dbg_colour",
    "dbg_drop_down",
    "dbg_same_line",
    "dbg_section",
    "dbg_section_delete",
    "dbg_section_exists",
    "dbg_slider",
    "dbg_slider_int",
    "dbg_sprite",
    "dbg_text",
    "dbg_text_input",
    "dbg_view",
    "dbg_view_delete",
    "dbg_view_exists",
    "dbg_watch",
    "dcos",
    "debug_event",
    "debug_get_callstack",
    "degtorad",
    "device_get_tilt_x",
    "device_get_tilt_y",
    "device_get_tilt_z",
    "device_is_keypad_open",
    "device_mouse_check_button",
    "device_mouse_check_button_pressed",
    "device_mouse_check_button_released",
    "device_mouse_dbclick_enable",
    "device_mouse_raw_x",
    "device_mouse_raw_y",
    "device_mouse_x",
    "device_mouse_x_to_gui",
    "device_mouse_y",
    "device_mouse_y_to_gui",
    "directory_create",
    "directory_destroy",
    "directory_exists",
    "display_get_dpi_x",
    "display_get_dpi_y",
    "display_get_frequency",
    "display_get_gui_height",
    "display_get_gui_width",
    "display_get_height",
    "display_get_orientation",
    "display_get_sleep_margin",
    "display_get_timing_method",
    "display_get_width",
    "display_mouse_get_x",
    "display_mouse_get_y",
    "display_mouse_set",
    "display_reset",
    "display_set_gui_maximise",
    "display_set_gui_maximize",
    "display_set_gui_size",
    "display_set_sleep_margin",
    "display_set_timing_method",
    "display_set_ui_visibility",
    "distance_to_object",
    "distance_to_point",
    "dot_product",
    "dot_product_3d",
    "dot_product_3d_normalised",
    "dot_product_3d_normalized",
    "dot_product_normalised",
    "dot_product_normalized",
    "draw_arrow",
    "draw_button",
    "draw_circle",
    "draw_circle_color",
    "draw_circle_colour",
    "draw_clear",
    "draw_clear_alpha",
    "draw_ellipse",
    "draw_ellipse_color",
    "draw_ellipse_colour",
    "draw_enable_drawevent",
    "draw_enable_skeleton_blendmodes",
    "draw_enable_swf_aa",
    "draw_flush",
    "draw_get_alpha",
    "draw_get_color",
    "draw_get_colour",
    "draw_get_enable_skeleton_blendmodes",
    "draw_get_font",
    "draw_get_halign",
    "draw_get_lighting",
    "draw_get_swf_aa_level",
    "draw_get_valign",
    "draw_getpixel",
    "draw_getpixel_ext",
    "draw_healthbar",
    "draw_highscore",
    "draw_light_define_ambient",
    "draw_light_define_direction",
    "draw_light_define_point",
    "draw_light_enable",
    "draw_light_get",
    "draw_light_get_ambient",
    "draw_line",
    "draw_line_color",
    "draw_line_colour",
    "draw_line_width",
    "draw_line_width_color",
    "draw_line_width_colour",
    "draw_path",
    "draw_point",
    "draw_point_color",
    "draw_point_colour",
    "draw_primitive_begin",
    "draw_primitive_begin_texture",
    "draw_primitive_end",
    "draw_rectangle",
    "draw_rectangle_color",
    "draw_rectangle_colour",
    "draw_roundrect",
    "draw_roundrect_color",
    "draw_roundrect_color_ext",
    "draw_roundrect_colour",
    "draw_roundrect_colour_ext",
    "draw_roundrect_ext",
    "draw_self",
    "draw_set_alpha",
    "draw_set_circle_precision",
    "draw_set_color",
    "draw_set_colour",
    "draw_set_font",
    "draw_set_halign",
    "draw_set_lighting",
    "draw_set_swf_aa_level",
    "draw_set_valign",
    "draw_skeleton",
    "draw_skeleton_collision",
    "draw_skeleton_instance",
    "draw_skeleton_time",
    "draw_sprite",
    "draw_sprite_ext",
    "draw_sprite_general",
    "draw_sprite_part",
    "draw_sprite_part_ext",
    "draw_sprite_pos",
    "draw_sprite_stretched",
    "draw_sprite_stretched_ext",
    "draw_sprite_tiled",
    "draw_sprite_tiled_ext",
    "draw_surface",
    "draw_surface_ext",
    "draw_surface_general",
    "draw_surface_part",
    "draw_surface_part_ext",
    "draw_surface_stretched",
    "draw_surface_stretched_ext",
    "draw_surface_tiled",
    "draw_surface_tiled_ext",
    "draw_text",
    "draw_text_color",
    "draw_text_colour",
    "draw_text_ext",
    "draw_text_ext_color",
    "draw_text_ext_colour",
    "draw_text_ext_transformed",
    "draw_text_ext_transformed_color",
    "draw_text_ext_transformed_colour",
    "draw_text_transformed",
    "draw_text_transformed_color",
    "draw_text_transformed_colour",
    "draw_texture_flush",
    "draw_tile",
    "draw_tilemap",
    "draw_triangle",
    "draw_triangle_color",
    "draw_triangle_colour",
    "draw_vertex",
    "draw_vertex_color",
    "draw_vertex_colour",
    "draw_vertex_texture",
    "draw_vertex_texture_color",
    "draw_vertex_texture_colour",
    "ds_exists",
    "ds_grid_add",
    "ds_grid_add_disk",
    "ds_grid_add_grid_region",
    "ds_grid_add_region",
    "ds_grid_clear",
    "ds_grid_copy",
    "ds_grid_create",
    "ds_grid_destroy",
    "ds_grid_get",
    "ds_grid_get_disk_max",
    "ds_grid_get_disk_mean",
    "ds_grid_get_disk_min",
    "ds_grid_get_disk_sum",
    "ds_grid_get_max",
    "ds_grid_get_mean",
    "ds_grid_get_min",
    "ds_grid_get_sum",
    "ds_grid_height",
    "ds_grid_multiply",
    "ds_grid_multiply_disk",
    "ds_grid_multiply_grid_region",
    "ds_grid_multiply_region",
    "ds_grid_read",
    "ds_grid_resize",
    "ds_grid_set",
    "ds_grid_set_disk",
    "ds_grid_set_grid_region",
    "ds_grid_set_region",
    "ds_grid_shuffle",
    "ds_grid_sort",
    "ds_grid_to_mp_grid",
    "ds_grid_value_disk_exists",
    "ds_grid_value_disk_x",
    "ds_grid_value_disk_y",
    "ds_grid_value_exists",
    "ds_grid_value_x",
    "ds_grid_value_y",
    "ds_grid_width",
    "ds_grid_write",
    "ds_list_add",
    "ds_list_clear",
    "ds_list_copy",
    "ds_list_create",
    "ds_list_delete",
    "ds_list_destroy",
    "ds_list_empty",
    "ds_list_find_index",
    "ds_list_find_value",
    "ds_list_insert",
    "ds_list_is_list",
    "ds_list_is_map",
    "ds_list_mark_as_list",
    "ds_list_mark_as_map",
    "ds_list_read",
    "ds_list_replace",
    "ds_list_set",
    "ds_list_shuffle",
    "ds_list_size",
    "ds_list_sort",
    "ds_list_write",
    "ds_map_add",
    "ds_map_add_list",
    "ds_map_add_map",
    "ds_map_clear",
    "ds_map_copy",
    "ds_map_create",
    "ds_map_delete",
    "ds_map_destroy",
    "ds_map_empty",
    "ds_map_exists",
    "ds_map_find_first",
    "ds_map_find_last",
    "ds_map_find_next",
    "ds_map_find_previous",
    "ds_map_find_value",
    "ds_map_is_list",
    "ds_map_is_map",
    "ds_map_keys_to_array",
    "ds_map_read",
    "ds_map_replace",
    "ds_map_replace_list",
    "ds_map_replace_map",
    "ds_map_secure_load",
    "ds_map_secure_load_buffer",
    "ds_map_secure_save",
    "ds_map_secure_save_buffer",
    "ds_map_set",
    "ds_map_size",
    "ds_map_values_to_array",
    "ds_map_write",
    "ds_priority_add",
    "ds_priority_change_priority",
    "ds_priority_clear",
    "ds_priority_copy",
    "ds_priority_create",
    "ds_priority_delete_max",
    "ds_priority_delete_min",
    "ds_priority_delete_value",
    "ds_priority_destroy",
    "ds_priority_empty",
    "ds_priority_find_max",
    "ds_priority_find_min",
    "ds_priority_find_priority",
    "ds_priority_read",
    "ds_priority_size",
    "ds_priority_write",
    "ds_queue_clear",
    "ds_queue_copy",
    "ds_queue_create",
    "ds_queue_dequeue",
    "ds_queue_destroy",
    "ds_queue_empty",
    "ds_queue_enqueue",
    "ds_queue_head",
    "ds_queue_read",
    "ds_queue_size",
    "ds_queue_tail",
    "ds_queue_write",
    "ds_set_precision",
    "ds_stack_clear",
    "ds_stack_copy",
    "ds_stack_create",
    "ds_stack_destroy",
    "ds_stack_empty",
    "ds_stack_pop",
    "ds_stack_push",
    "ds_stack_read",
    "ds_stack_size",
    "ds_stack_top",
    "ds_stack_write",
    "dsin",
    "dtan",
    "effect_clear",
    "effect_create_above",
    "effect_create_below",
    "effect_create_depth",
    "effect_create_layer",
    "environment_get_variable",
    "event_inherited",
    "event_perform",
    "event_perform_async",
    "event_perform_object",
    "event_user",
    "exception_unhandled_handler",
    "exp",
    "extension_exists",
    "extension_get_option_count",
    "extension_get_option_names",
    "extension_get_option_value",
    "extension_get_options",
    "extension_get_version",
    "external_call",
    "external_define",
    "external_free",
    "file_attributes",
    "file_bin_close",
    "file_bin_open",
    "file_bin_position",
    "file_bin_read_byte",
    "file_bin_rewrite",
    "file_bin_seek",
    "file_bin_size",
    "file_bin_write_byte",
    "file_copy",
    "file_delete",
    "file_exists",
    "file_find_close",
    "file_find_first",
    "file_find_next",
    "file_rename",
    "file_text_close",
    "file_text_eof",
    "file_text_eoln",
    "file_text_open_append",
    "file_text_open_from_string",
    "file_text_open_read",
    "file_text_open_write",
    "file_text_read_real",
    "file_text_read_string",
    "file_text_readln",
    "file_text_write_real",
    "file_text_write_string",
    "file_text_writeln",
    "filename_change_ext",
    "filename_dir",
    "filename_drive",
    "filename_ext",
    "filename_name",
    "filename_path",
    "floor",
    "font_add",
    "font_add_enable_aa",
    "font_add_get_enable_aa",
    "font_add_sprite",
    "font_add_sprite_ext",
    "font_cache_glyph",
    "font_delete",
    "font_enable_effects",
    "font_enable_sdf",
    "font_exists",
    "font_get_bold",
    "font_get_first",
    "font_get_fontname",
    "font_get_info",
    "font_get_italic",
    "font_get_last",
    "font_get_name",
    "font_get_sdf_enabled",
    "font_get_sdf_spread",
    "font_get_size",
    "font_get_texture",
    "font_get_uvs",
    "font_replace_sprite",
    "font_replace_sprite_ext",
    "font_sdf_spread",
    "font_set_cache_size",
    "frac",
    "fx_create",
    "fx_get_name",
    "fx_get_parameter",
    "fx_get_parameter_names",
    "fx_get_parameters",
    "fx_get_single_layer",
    "fx_set_parameter",
    "fx_set_parameters",
    "fx_set_single_layer",
    "game_change",
    "game_end",
    "game_get_speed",
    "game_load",
    "game_load_buffer",
    "game_restart",
    "game_save",
    "game_save_buffer",
    "game_set_speed",
    "gamepad_axis_count",
    "gamepad_axis_value",
    "gamepad_button_check",
    "gamepad_button_check_pressed",
    "gamepad_button_check_released",
    "gamepad_button_count",
    "gamepad_button_value",
    "gamepad_get_axis_deadzone",
    "gamepad_get_button_threshold",
    "gamepad_get_description",
    "gamepad_get_device_count",
    "gamepad_get_guid",
    "gamepad_get_mapping",
    "gamepad_get_option",
    "gamepad_hat_count",
    "gamepad_hat_value",
    "gamepad_is_connected",
    "gamepad_is_supported",
    "gamepad_remove_mapping",
    "gamepad_set_axis_deadzone",
    "gamepad_set_button_threshold",
    "gamepad_set_color",
    "gamepad_set_colour",
    "gamepad_set_option",
    "gamepad_set_vibration",
    "gamepad_test_mapping",
    "gc_collect",
    "gc_enable",
    "gc_get_stats",
    "gc_get_target_frame_time",
    "gc_is_enabled",
    "gc_target_frame_time",
    "gesture_double_tap_distance",
    "gesture_double_tap_time",
    "gesture_drag_distance",
    "gesture_drag_time",
    "gesture_flick_speed",
    "gesture_get_double_tap_distance",
    "gesture_get_double_tap_time",
    "gesture_get_drag_distance",
    "gesture_get_drag_time",
    "gesture_get_flick_speed",
    "gesture_get_pinch_angle_away",
    "gesture_get_pinch_angle_towards",
    "gesture_get_pinch_distance",
    "gesture_get_rotate_angle",
    "gesture_get_rotate_time",
    "gesture_get_tap_count",
    "gesture_pinch_angle_away",
    "gesture_pinch_angle_towards",
    "gesture_pinch_distance",
    "gesture_rotate_angle",
    "gesture_rotate_time",
    "gesture_tap_count",
    "get_integer",
    "get_integer_async",
    "get_login_async",
    "get_open_filename",
    "get_open_filename_ext",
    "get_save_filename",
    "get_save_filename_ext",
    "get_string",
    "get_string_async",
    "get_timer",
    "gif_add_surface",
    "gif_open",
    "gif_save",
    "gif_save_buffer",
    "gml_pragma",
    "gml_release_mode",
    "gpu_get_alphatestenable",
    "gpu_get_alphatestref",
    "gpu_get_blendenable",
    "gpu_get_blendmode",
    "gpu_get_blendmode_dest",
    "gpu_get_blendmode_destalpha",
    "gpu_get_blendmode_ext",
    "gpu_get_blendmode_ext_sepalpha",
    "gpu_get_blendmode_src",
    "gpu_get_blendmode_srcalpha",
    "gpu_get_colorwriteenable",
    "gpu_get_colourwriteenable",
    "gpu_get_cullmode",
    "gpu_get_depth",
    "gpu_get_fog",
    "gpu_get_state",
    "gpu_get_tex_filter",
    "gpu_get_tex_filter_ext",
    "gpu_get_tex_max_aniso",
    "gpu_get_tex_max_aniso_ext",
    "gpu_get_tex_max_mip",
    "gpu_get_tex_max_mip_ext",
    "gpu_get_tex_min_mip",
    "gpu_get_tex_min_mip_ext",
    "gpu_get_tex_mip_bias",
    "gpu_get_tex_mip_bias_ext",
    "gpu_get_tex_mip_enable",
    "gpu_get_tex_mip_enable_ext",
    "gpu_get_tex_mip_filter",
    "gpu_get_tex_mip_filter_ext",
    "gpu_get_tex_repeat",
    "gpu_get_tex_repeat_ext",
    "gpu_get_texfilter",
    "gpu_get_texfilter_ext",
    "gpu_get_texrepeat",
    "gpu_get_texrepeat_ext",
    "gpu_get_zfunc",
    "gpu_get_ztestenable",
    "gpu_get_zwriteenable",
    "gpu_pop_state",
    "gpu_push_state",
    "gpu_set_alphatestenable",
    "gpu_set_alphatestref",
    "gpu_set_blendenable",
    "gpu_set_blendmode",
    "gpu_set_blendmode_ext",
    "gpu_set_blendmode_ext_sepalpha",
    "gpu_set_colorwriteenable",
    "gpu_set_colourwriteenable",
    "gpu_set_cullmode",
    "gpu_set_depth",
    "gpu_set_fog",
    "gpu_set_state",
    "gpu_set_tex_filter",
    "gpu_set_tex_filter_ext",
    "gpu_set_tex_max_aniso",
    "gpu_set_tex_max_aniso_ext",
    "gpu_set_tex_max_mip",
    "gpu_set_tex_max_mip_ext",
    "gpu_set_tex_min_mip",
    "gpu_set_tex_min_mip_ext",
    "gpu_set_tex_mip_bias",
    "gpu_set_tex_mip_bias_ext",
    "gpu_set_tex_mip_enable",
    "gpu_set_tex_mip_enable_ext",
    "gpu_set_tex_mip_filter",
    "gpu_set_tex_mip_filter_ext",
    "gpu_set_tex_repeat",
    "gpu_set_tex_repeat_ext",
    "gpu_set_texfilter",
    "gpu_set_texfilter_ext",
    "gpu_set_texrepeat",
    "gpu_set_texrepeat_ext",
    "gpu_set_zfunc",
    "gpu_set_ztestenable",
    "gpu_set_zwriteenable",
    "handle_parse",
    "highscore_add",
    "highscore_clear",
    "highscore_name",
    "highscore_value",
    "http_get",
    "http_get_file",
    "http_get_request_crossorigin",
    "http_post_string",
    "http_request",
    "http_set_request_crossorigin",
    "iap_acquire",
    "iap_activate",
    "iap_consume",
    "iap_enumerate_products",
    "iap_product_details",
    "iap_purchase_details",
    "iap_restore_all",
    "iap_status",
    "ini_close",
    "ini_key_delete",
    "ini_key_exists",
    "ini_open",
    "ini_open_from_string",
    "ini_read_real",
    "ini_read_string",
    "ini_section_delete",
    "ini_section_exists",
    "ini_write_real",
    "ini_write_string",
    "instance_activate_all",
    "instance_activate_layer",
    "instance_activate_object",
    "instance_activate_region",
    "instance_change",
    "instance_copy",
    "instance_create_depth",
    "instance_create_layer",
    "instance_deactivate_all",
    "instance_deactivate_layer",
    "instance_deactivate_object",
    "instance_deactivate_region",
    "instance_destroy",
    "instance_exists",
    "instance_find",
    "instance_furthest",
    "instance_id_get",
    "instance_nearest",
    "instance_number",
    "instance_place",
    "instance_place_list",
    "instance_position",
    "instance_position_list",
    "instanceof",
    "int64",
    "io_clear",
    "irandom",
    "irandom_range",
    "is_array",
    "is_bool",
    "is_callable",
    "is_debug_overlay_open",
    "is_handle",
    "is_infinity",
    "is_instanceof",
    "is_int32",
    "is_int64",
    "is_keyboard_used_debug_overlay",
    "is_method",
    "is_mouse_over_debug_overlay",
    "is_nan",
    "is_numeric",
    "is_ptr",
    "is_real",
    "is_string",
    "is_struct",
    "is_undefined",
    "json_decode",
    "json_encode",
    "json_parse",
    "json_stringify",
    "keyboard_check",
    "keyboard_check_direct",
    "keyboard_check_pressed",
    "keyboard_check_released",
    "keyboard_clear",
    "keyboard_get_map",
    "keyboard_get_numlock",
    "keyboard_key_press",
    "keyboard_key_release",
    "keyboard_set_map",
    "keyboard_set_numlock",
    "keyboard_unset_map",
    "keyboard_virtual_height",
    "keyboard_virtual_hide",
    "keyboard_virtual_show",
    "keyboard_virtual_status",
    "layer_add_instance",
    "layer_background_alpha",
    "layer_background_blend",
    "layer_background_change",
    "layer_background_create",
    "layer_background_destroy",
    "layer_background_exists",
    "layer_background_get_alpha",
    "layer_background_get_blend",
    "layer_background_get_htiled",
    "layer_background_get_id",
    "layer_background_get_index",
    "layer_background_get_speed",
    "layer_background_get_sprite",
    "layer_background_get_stretch",
    "layer_background_get_visible",
    "layer_background_get_vtiled",
    "layer_background_get_xscale",
    "layer_background_get_yscale",
    "layer_background_htiled",
    "layer_background_index",
    "layer_background_speed",
    "layer_background_sprite",
    "layer_background_stretch",
    "layer_background_visible",
    "layer_background_vtiled",
    "layer_background_xscale",
    "layer_background_yscale",
    "layer_clear_fx",
    "layer_create",
    "layer_depth",
    "layer_destroy",
    "layer_destroy_instances",
    "layer_element_move",
    "layer_enable_fx",
    "layer_exists",
    "layer_force_draw_depth",
    "layer_fx_is_enabled",
    "layer_get_all",
    "layer_get_all_elements",
    "layer_get_depth",
    "layer_get_element_layer",
    "layer_get_element_type",
    "layer_get_forced_depth",
    "layer_get_fx",
    "layer_get_hspeed",
    "layer_get_id",
    "layer_get_id_at_depth",
    "layer_get_name",
    "layer_get_script_begin",
    "layer_get_script_end",
    "layer_get_shader",
    "layer_get_target_room",
    "layer_get_visible",
    "layer_get_vspeed",
    "layer_get_x",
    "layer_get_y",
    "layer_has_instance",
    "layer_hspeed",
    "layer_instance_get_instance",
    "layer_is_draw_depth_forced",
    "layer_reset_target_room",
    "layer_script_begin",
    "layer_script_end",
    "layer_sequence_angle",
    "layer_sequence_create",
    "layer_sequence_destroy",
    "layer_sequence_exists",
    "layer_sequence_get_angle",
    "layer_sequence_get_headdir",
    "layer_sequence_get_headpos",
    "layer_sequence_get_instance",
    "layer_sequence_get_length",
    "layer_sequence_get_sequence",
    "layer_sequence_get_speedscale",
    "layer_sequence_get_x",
    "layer_sequence_get_xscale",
    "layer_sequence_get_y",
    "layer_sequence_get_yscale",
    "layer_sequence_headdir",
    "layer_sequence_headpos",
    "layer_sequence_is_finished",
    "layer_sequence_is_paused",
    "layer_sequence_pause",
    "layer_sequence_play",
    "layer_sequence_speedscale",
    "layer_sequence_x",
    "layer_sequence_xscale",
    "layer_sequence_y",
    "layer_sequence_yscale",
    "layer_set_fx",
    "layer_set_target_room",
    "layer_set_visible",
    "layer_shader",
    "layer_sprite_alpha",
    "layer_sprite_angle",
    "layer_sprite_blend",
    "layer_sprite_change",
    "layer_sprite_create",
    "layer_sprite_destroy",
    "layer_sprite_exists",
    "layer_sprite_get_alpha",
    "layer_sprite_get_angle",
    "layer_sprite_get_blend",
    "layer_sprite_get_id",
    "layer_sprite_get_index",
    "layer_sprite_get_speed",
    "layer_sprite_get_sprite",
    "layer_sprite_get_x",
    "layer_sprite_get_xscale",
    "layer_sprite_get_y",
    "layer_sprite_get_yscale",
    "layer_sprite_index",
    "layer_sprite_speed",
    "layer_sprite_x",
    "layer_sprite_xscale",
    "layer_sprite_y",
    "layer_sprite_yscale",
    "layer_tile_alpha",
    "layer_tile_blend",
    "layer_tile_change",
    "layer_tile_create",
    "layer_tile_destroy",
    "layer_tile_exists",
    "layer_tile_get_alpha",
    "layer_tile_get_blend",
    "layer_tile_get_region",
    "layer_tile_get_sprite",
    "layer_tile_get_visible",
    "layer_tile_get_x",
    "layer_tile_get_xscale",
    "layer_tile_get_y",
    "layer_tile_get_yscale",
    "layer_tile_region",
    "layer_tile_visible",
    "layer_tile_x",
    "layer_tile_xscale",
    "layer_tile_y",
    "layer_tile_yscale",
    "layer_tilemap_create",
    "layer_tilemap_destroy",
    "layer_tilemap_exists",
    "layer_tilemap_get_id",
    "layer_vspeed",
    "layer_x",
    "layer_y",
    "lengthdir_x",
    "lengthdir_y",
    "lerp",
    "lin_to_db",
    "ln",
    "load_csv",
    "log10",
    "log2",
    "logn",
    "make_color_hsv",
    "make_color_rgb",
    "make_colour_hsv",
    "make_colour_rgb",
    "math_get_epsilon",
    "math_set_epsilon",
    "matrix_build",
    "matrix_build_identity",
    "matrix_build_lookat",
    "matrix_build_projection_ortho",
    "matrix_build_projection_perspective",
    "matrix_build_projection_perspective_fov",
    "matrix_get",
    "matrix_multiply",
    "matrix_set",
    "matrix_stack_clear",
    "matrix_stack_is_empty",
    "matrix_stack_pop",
    "matrix_stack_push",
    "matrix_stack_set",
    "matrix_stack_top",
    "matrix_transform_vertex",
    "max",
    "md5_file",
    "md5_string_unicode",
    "md5_string_utf8",
    "mean",
    "median",
    "merge_color",
    "merge_colour",
    "method",
    "method_call",
    "method_get_index",
    "method_get_self",
    "min",
    "motion_add",
    "motion_set",
    "mouse_check_button",
    "mouse_check_button_pressed",
    "mouse_check_button_released",
    "mouse_clear",
    "mouse_wheel_down",
    "mouse_wheel_up",
    "move_and_collide",
    "move_bounce_all",
    "move_bounce_solid",
    "move_contact_all",
    "move_contact_solid",
    "move_outside_all",
    "move_outside_solid",
    "move_random",
    "move_snap",
    "move_towards_point",
    "move_wrap",
    "mp_grid_add_cell",
    "mp_grid_add_instances",
    "mp_grid_add_rectangle",
    "mp_grid_clear_all",
    "mp_grid_clear_cell",
    "mp_grid_clear_rectangle",
    "mp_grid_create",
    "mp_grid_destroy",
    "mp_grid_draw",
    "mp_grid_get_cell",
    "mp_grid_path",
    "mp_grid_to_ds_grid",
    "mp_linear_path",
    "mp_linear_path_object",
    "mp_linear_step",
    "mp_linear_step_object",
    "mp_potential_path",
    "mp_potential_path_object",
    "mp_potential_settings",
    "mp_potential_step",
    "mp_potential_step_object",
    "nameof",
    "network_connect",
    "network_connect_async",
    "network_connect_raw",
    "network_connect_raw_async",
    "network_create_server",
    "network_create_server_raw",
    "network_create_socket",
    "network_create_socket_ext",
    "network_destroy",
    "network_resolve",
    "network_send_broadcast",
    "network_send_packet",
    "network_send_raw",
    "network_send_udp",
    "network_send_udp_raw",
    "network_set_config",
    "network_set_timeout",
    "object_exists",
    "object_get_mask",
    "object_get_name",
    "object_get_parent",
    "object_get_persistent",
    "object_get_physics",
    "object_get_solid",
    "object_get_sprite",
    "object_get_visible",
    "object_is_ancestor",
    "object_set_mask",
    "object_set_persistent",
    "object_set_solid",
    "object_set_sprite",
    "object_set_visible",
    "ord",
    "os_check_permission",
    "os_get_config",
    "os_get_info",
    "os_get_language",
    "os_get_region",
    "os_is_network_connected",
    "os_is_paused",
    "os_lock_orientation",
    "os_powersave_enable",
    "os_request_permission",
    "os_set_orientation_lock",
    "parameter_count",
    "parameter_string",
    "part_emitter_burst",
    "part_emitter_clear",
    "part_emitter_create",
    "part_emitter_delay",
    "part_emitter_destroy",
    "part_emitter_destroy_all",
    "part_emitter_enable",
    "part_emitter_exists",
    "part_emitter_interval",
    "part_emitter_region",
    "part_emitter_relative",
    "part_emitter_stream",
    "part_particles_burst",
    "part_particles_clear",
    "part_particles_count",
    "part_particles_create",
    "part_particles_create_color",
    "part_particles_create_colour",
    "part_system_angle",
    "part_system_automatic_draw",
    "part_system_automatic_update",
    "part_system_clear",
    "part_system_color",
    "part_system_colour",
    "part_system_create",
    "part_system_create_layer",
    "part_system_depth",
    "part_system_destroy",
    "part_system_draw_order",
    "part_system_drawit",
    "part_system_exists",
    "part_system_get_info",
    "part_system_get_layer",
    "part_system_global_space",
    "part_system_layer",
    "part_system_position",
    "part_system_update",
    "part_type_alpha1",
    "part_type_alpha2",
    "part_type_alpha3",
    "part_type_blend",
    "part_type_clear",
    "part_type_color1",
    "part_type_color2",
    "part_type_color3",
    "part_type_color_hsv",
    "part_type_color_mix",
    "part_type_color_rgb",
    "part_type_colour1",
    "part_type_colour2",
    "part_type_colour3",
    "part_type_colour_hsv",
    "part_type_colour_mix",
    "part_type_colour_rgb",
    "part_type_create",
    "part_type_death",
    "part_type_destroy",
    "part_type_direction",
    "part_type_exists",
    "part_type_gravity",
    "part_type_life",
    "part_type_orientation",
    "part_type_scale",
    "part_type_shape",
    "part_type_size",
    "part_type_size_x",
    "part_type_size_y",
    "part_type_speed",
    "part_type_sprite",
    "part_type_step",
    "part_type_subimage",
    "particle_exists",
    "particle_get_info",
    "path_add",
    "path_add_point",
    "path_append",
    "path_assign",
    "path_change_point",
    "path_clear_points",
    "path_delete",
    "path_delete_point",
    "path_duplicate",
    "path_end",
    "path_exists",
    "path_flip",
    "path_get_closed",
    "path_get_kind",
    "path_get_length",
    "path_get_name",
    "path_get_number",
    "path_get_point_speed",
    "path_get_point_x",
    "path_get_point_y",
    "path_get_precision",
    "path_get_speed",
    "path_get_x",
    "path_get_y",
    "path_insert_point",
    "path_mirror",
    "path_rescale",
    "path_reverse",
    "path_rotate",
    "path_set_closed",
    "path_set_kind",
    "path_set_precision",
    "path_shift",
    "path_start",
    "physics_apply_angular_impulse",
    "physics_apply_force",
    "physics_apply_impulse",
    "physics_apply_local_force",
    "physics_apply_local_impulse",
    "physics_apply_torque",
    "physics_draw_debug",
    "physics_fixture_add_point",
    "physics_fixture_bind",
    "physics_fixture_bind_ext",
    "physics_fixture_create",
    "physics_fixture_delete",
    "physics_fixture_set_angular_damping",
    "physics_fixture_set_awake",
    "physics_fixture_set_box_shape",
    "physics_fixture_set_chain_shape",
    "physics_fixture_set_circle_shape",
    "physics_fixture_set_collision_group",
    "physics_fixture_set_density",
    "physics_fixture_set_edge_shape",
    "physics_fixture_set_friction",
    "physics_fixture_set_kinematic",
    "physics_fixture_set_linear_damping",
    "physics_fixture_set_polygon_shape",
    "physics_fixture_set_restitution",
    "physics_fixture_set_sensor",
    "physics_get_density",
    "physics_get_friction",
    "physics_get_restitution",
    "physics_joint_delete",
    "physics_joint_distance_create",
    "physics_joint_enable_motor",
    "physics_joint_friction_create",
    "physics_joint_gear_create",
    "physics_joint_get_value",
    "physics_joint_prismatic_create",
    "physics_joint_pulley_create",
    "physics_joint_revolute_create",
    "physics_joint_rope_create",
    "physics_joint_set_value",
    "physics_joint_weld_create",
    "physics_joint_wheel_create",
    "physics_mass_properties",
    "physics_particle_count",
    "physics_particle_create",
    "physics_particle_delete",
    "physics_particle_delete_region_box",
    "physics_particle_delete_region_circle",
    "physics_particle_delete_region_poly",
    "physics_particle_draw",
    "physics_particle_draw_ext",
    "physics_particle_get_damping",
    "physics_particle_get_data",
    "physics_particle_get_data_particle",
    "physics_particle_get_density",
    "physics_particle_get_gravity_scale",
    "physics_particle_get_group_flags",
    "physics_particle_get_max_count",
    "physics_particle_get_radius",
    "physics_particle_group_add_point",
    "physics_particle_group_begin",
    "physics_particle_group_box",
    "physics_particle_group_circle",
    "physics_particle_group_count",
    "physics_particle_group_delete",
    "physics_particle_group_end",
    "physics_particle_group_get_ang_vel",
    "physics_particle_group_get_angle",
    "physics_particle_group_get_centre_x",
    "physics_particle_group_get_centre_y",
    "physics_particle_group_get_data",
    "physics_particle_group_get_inertia",
    "physics_particle_group_get_mass",
    "physics_particle_group_get_vel_x",
    "physics_particle_group_get_vel_y",
    "physics_particle_group_get_x",
    "physics_particle_group_get_y",
    "physics_particle_group_join",
    "physics_particle_group_polygon",
    "physics_particle_set_category_flags",
    "physics_particle_set_damping",
    "physics_particle_set_density",
    "physics_particle_set_flags",
    "physics_particle_set_gravity_scale",
    "physics_particle_set_group_flags",
    "physics_particle_set_max_count",
    "physics_particle_set_radius",
    "physics_pause_enable",
    "physics_remove_fixture",
    "physics_set_density",
    "physics_set_friction",
    "physics_set_restitution",
    "physics_test_overlap",
    "physics_world_create",
    "physics_world_draw_debug",
    "physics_world_gravity",
    "physics_world_update_iterations",
    "physics_world_update_speed",
    "place_empty",
    "place_free",
    "place_meeting",
    "place_snapped",
    "point_direction",
    "point_distance",
    "point_distance_3d",
    "point_in_circle",
    "point_in_rectangle",
    "point_in_triangle",
    "position_change",
    "position_destroy",
    "position_empty",
    "position_meeting",
    "power",
    "ptr",
    "radtodeg",
    "random",
    "random_get_seed",
    "random_range",
    "random_set_seed",
    "randomise",
    "randomize",
    "real",
    "rectangle_in_circle",
    "rectangle_in_rectangle",
    "rectangle_in_triangle",
    "ref_create",
    "rollback_chat",
    "rollback_create_game",
    "rollback_define_extra_network_latency",
    "rollback_define_input",
    "rollback_define_input_frame_delay",
    "rollback_define_mock_input",
    "rollback_define_player",
    "rollback_display_events",
    "rollback_get_info",
    "rollback_get_input",
    "rollback_get_player_prefs",
    "rollback_join_game",
    "rollback_leave_game",
    "rollback_set_player_prefs",
    "rollback_start_game",
    "rollback_sync_on_frame",
    "rollback_use_late_join",
    "rollback_use_manual_start",
    "rollback_use_player_prefs",
    "rollback_use_random_input",
    "room_add",
    "room_assign",
    "room_duplicate",
    "room_exists",
    "room_get_camera",
    "room_get_info",
    "room_get_name",
    "room_get_viewport",
    "room_goto",
    "room_goto_next",
    "room_goto_previous",
    "room_instance_add",
    "room_instance_clear",
    "room_next",
    "room_previous",
    "room_restart",
    "room_set_camera",
    "room_set_height",
    "room_set_persistent",
    "room_set_view_enabled",
    "room_set_viewport",
    "room_set_width",
    "round",
    "scheduler_resolution_get",
    "scheduler_resolution_set",
    "screen_save",
    "screen_save_part",
    "script_execute",
    "script_execute_ext",
    "script_exists",
    "script_get_name",
    "sequence_create",
    "sequence_destroy",
    "sequence_exists",
    "sequence_get",
    "sequence_get_objects",
    "sequence_instance_override_object",
    "sequence_keyframe_new",
    "sequence_keyframedata_new",
    "sequence_track_new",
    "sha1_file",
    "sha1_string_unicode",
    "sha1_string_utf8",
    "shader_current",
    "shader_enable_corner_id",
    "shader_get_name",
    "shader_get_sampler_index",
    "shader_get_uniform",
    "shader_is_compiled",
    "shader_reset",
    "shader_set",
    "shader_set_uniform_f",
    "shader_set_uniform_f_array",
    "shader_set_uniform_f_buffer",
    "shader_set_uniform_i",
    "shader_set_uniform_i_array",
    "shader_set_uniform_matrix",
    "shader_set_uniform_matrix_array",
    "shaders_are_supported",
    "shop_leave_rating",
    "show_debug_message",
    "show_debug_message_ext",
    "show_debug_overlay",
    "show_error",
    "show_message",
    "show_message_async",
    "show_question",
    "show_question_async",
    "sign",
    "sin",
    "skeleton_animation_clear",
    "skeleton_animation_get",
    "skeleton_animation_get_duration",
    "skeleton_animation_get_event_frames",
    "skeleton_animation_get_ext",
    "skeleton_animation_get_frame",
    "skeleton_animation_get_frames",
    "skeleton_animation_get_position",
    "skeleton_animation_is_finished",
    "skeleton_animation_is_looping",
    "skeleton_animation_list",
    "skeleton_animation_mix",
    "skeleton_animation_set",
    "skeleton_animation_set_ext",
    "skeleton_animation_set_frame",
    "skeleton_animation_set_position",
    "skeleton_attachment_create",
    "skeleton_attachment_create_color",
    "skeleton_attachment_create_colour",
    "skeleton_attachment_destroy",
    "skeleton_attachment_exists",
    "skeleton_attachment_get",
    "skeleton_attachment_replace",
    "skeleton_attachment_replace_color",
    "skeleton_attachment_replace_colour",
    "skeleton_attachment_set",
    "skeleton_bone_data_get",
    "skeleton_bone_data_set",
    "skeleton_bone_list",
    "skeleton_bone_state_get",
    "skeleton_bone_state_set",
    "skeleton_collision_draw_set",
    "skeleton_find_slot",
    "skeleton_get_bounds",
    "skeleton_get_minmax",
    "skeleton_get_num_bounds",
    "skeleton_skin_create",
    "skeleton_skin_get",
    "skeleton_skin_list",
    "skeleton_skin_set",
    "skeleton_slot_alpha_get",
    "skeleton_slot_color_get",
    "skeleton_slot_color_set",
    "skeleton_slot_colour_get",
    "skeleton_slot_colour_set",
    "skeleton_slot_data",
    "skeleton_slot_data_instance",
    "skeleton_slot_list",
    "sprite_add",
    "sprite_add_ext",
    "sprite_add_from_surface",
    "sprite_assign",
    "sprite_collision_mask",
    "sprite_create_from_surface",
    "sprite_delete",
    "sprite_duplicate",
    "sprite_exists",
    "sprite_flush",
    "sprite_flush_multi",
    "sprite_get_bbox_bottom",
    "sprite_get_bbox_left",
    "sprite_get_bbox_mode",
    "sprite_get_bbox_right",
    "sprite_get_bbox_top",
    "sprite_get_height",
    "sprite_get_info",
    "sprite_get_name",
    "sprite_get_nineslice",
    "sprite_get_number",
    "sprite_get_speed",
    "sprite_get_speed_type",
    "sprite_get_texture",
    "sprite_get_tpe",
    "sprite_get_uvs",
    "sprite_get_width",
    "sprite_get_xoffset",
    "sprite_get_yoffset",
    "sprite_merge",
    "sprite_nineslice_create",
    "sprite_prefetch",
    "sprite_prefetch_multi",
    "sprite_replace",
    "sprite_save",
    "sprite_save_strip",
    "sprite_set_alpha_from_sprite",
    "sprite_set_bbox",
    "sprite_set_bbox_mode",
    "sprite_set_cache_size",
    "sprite_set_cache_size_ext",
    "sprite_set_nineslice",
    "sprite_set_offset",
    "sprite_set_speed",
    "sqr",
    "sqrt",
    "static_get",
    "static_set",
    "string",
    "string_byte_at",
    "string_byte_length",
    "string_char_at",
    "string_concat",
    "string_concat_ext",
    "string_copy",
    "string_count",
    "string_delete",
    "string_digits",
    "string_ends_with",
    "string_ext",
    "string_foreach",
    "string_format",
    "string_hash_to_newline",
    "string_height",
    "string_height_ext",
    "string_insert",
    "string_join",
    "string_join_ext",
    "string_last_pos",
    "string_last_pos_ext",
    "string_length",
    "string_letters",
    "string_lettersdigits",
    "string_lower",
    "string_ord_at",
    "string_pos",
    "string_pos_ext",
    "string_repeat",
    "string_replace",
    "string_replace_all",
    "string_set_byte_at",
    "string_split",
    "string_split_ext",
    "string_starts_with",
    "string_trim",
    "string_trim_end",
    "string_trim_start",
    "string_upper",
    "string_width",
    "string_width_ext",
    "struct_exists",
    "struct_foreach",
    "struct_get",
    "struct_get_from_hash",
    "struct_get_names",
    "struct_names_count",
    "struct_remove",
    "struct_set",
    "struct_set_from_hash",
    "surface_copy",
    "surface_copy_part",
    "surface_create",
    "surface_create_ext",
    "surface_depth_disable",
    "surface_exists",
    "surface_format_is_supported",
    "surface_free",
    "surface_get_depth_disable",
    "surface_get_format",
    "surface_get_height",
    "surface_get_target",
    "surface_get_target_ext",
    "surface_get_texture",
    "surface_get_width",
    "surface_getpixel",
    "surface_getpixel_ext",
    "surface_reset_target",
    "surface_resize",
    "surface_save",
    "surface_save_part",
    "surface_set_target",
    "surface_set_target_ext",
    "tag_get_asset_ids",
    "tag_get_assets",
    "tan",
    "texture_debug_messages",
    "texture_flush",
    "texture_get_height",
    "texture_get_texel_height",
    "texture_get_texel_width",
    "texture_get_uvs",
    "texture_get_width",
    "texture_global_scale",
    "texture_is_ready",
    "texture_prefetch",
    "texture_set_stage",
    "texturegroup_get_fonts",
    "texturegroup_get_names",
    "texturegroup_get_sprites",
    "texturegroup_get_status",
    "texturegroup_get_textures",
    "texturegroup_get_tilesets",
    "texturegroup_load",
    "texturegroup_set_mode",
    "texturegroup_unload",
    "tile_get_empty",
    "tile_get_flip",
    "tile_get_index",
    "tile_get_mirror",
    "tile_get_rotate",
    "tile_set_empty",
    "tile_set_flip",
    "tile_set_index",
    "tile_set_mirror",
    "tile_set_rotate",
    "tilemap_clear",
    "tilemap_get",
    "tilemap_get_at_pixel",
    "tilemap_get_cell_x_at_pixel",
    "tilemap_get_cell_y_at_pixel",
    "tilemap_get_frame",
    "tilemap_get_global_mask",
    "tilemap_get_height",
    "tilemap_get_mask",
    "tilemap_get_tile_height",
    "tilemap_get_tile_width",
    "tilemap_get_tileset",
    "tilemap_get_width",
    "tilemap_get_x",
    "tilemap_get_y",
    "tilemap_set",
    "tilemap_set_at_pixel",
    "tilemap_set_global_mask",
    "tilemap_set_height",
    "tilemap_set_mask",
    "tilemap_set_width",
    "tilemap_tileset",
    "tilemap_x",
    "tilemap_y",
    "tileset_get_info",
    "tileset_get_name",
    "tileset_get_texture",
    "tileset_get_uvs",
    "time_bpm_to_seconds",
    "time_seconds_to_bpm",
    "time_source_create",
    "time_source_destroy",
    "time_source_exists",
    "time_source_get_children",
    "time_source_get_parent",
    "time_source_get_period",
    "time_source_get_reps_completed",
    "time_source_get_reps_remaining",
    "time_source_get_state",
    "time_source_get_time_remaining",
    "time_source_get_units",
    "time_source_pause",
    "time_source_reconfigure",
    "time_source_reset",
    "time_source_resume",
    "time_source_start",
    "time_source_stop",
    "timeline_add",
    "timeline_clear",
    "timeline_delete",
    "timeline_exists",
    "timeline_get_name",
    "timeline_max_moment",
    "timeline_moment_add_script",
    "timeline_moment_clear",
    "timeline_size",
    "typeof",
    "url_get_domain",
    "url_open",
    "url_open_ext",
    "url_open_full",
    "uwp_device_touchscreen_available",
    "uwp_livetile_badge_clear",
    "uwp_livetile_badge_notification",
    "uwp_livetile_notification_begin",
    "uwp_livetile_notification_end",
    "uwp_livetile_notification_expiry",
    "uwp_livetile_notification_image_add",
    "uwp_livetile_notification_secondary_begin",
    "uwp_livetile_notification_tag",
    "uwp_livetile_notification_template_add",
    "uwp_livetile_notification_text_add",
    "uwp_livetile_queue_enable",
    "uwp_livetile_tile_clear",
    "uwp_secondarytile_badge_clear",
    "uwp_secondarytile_badge_notification",
    "uwp_secondarytile_delete",
    "uwp_secondarytile_pin",
    "uwp_secondarytile_tile_clear",
    "variable_clone",
    "variable_get_hash",
    "variable_global_exists",
    "variable_global_get",
    "variable_global_set",
    "variable_instance_exists",
    "variable_instance_get",
    "variable_instance_get_names",
    "variable_instance_names_count",
    "variable_instance_set",
    "variable_struct_exists",
    "variable_struct_get",
    "variable_struct_get_names",
    "variable_struct_names_count",
    "variable_struct_remove",
    "variable_struct_set",
    "vertex_argb",
    "vertex_begin",
    "vertex_color",
    "vertex_colour",
    "vertex_create_buffer",
    "vertex_create_buffer_ext",
    "vertex_create_buffer_from_buffer",
    "vertex_create_buffer_from_buffer_ext",
    "vertex_delete_buffer",
    "vertex_end",
    "vertex_float1",
    "vertex_float2",
    "vertex_float3",
    "vertex_float4",
    "vertex_format_add_color",
    "vertex_format_add_colour",
    "vertex_format_add_custom",
    "vertex_format_add_normal",
    "vertex_format_add_position",
    "vertex_format_add_position_3d",
    "vertex_format_add_texcoord",
    "vertex_format_begin",
    "vertex_format_delete",
    "vertex_format_end",
    "vertex_format_get_info",
    "vertex_freeze",
    "vertex_get_buffer_size",
    "vertex_get_number",
    "vertex_normal",
    "vertex_position",
    "vertex_position_3d",
    "vertex_submit",
    "vertex_submit_ext",
    "vertex_texcoord",
    "vertex_ubyte4",
    "vertex_update_buffer_from_buffer",
    "vertex_update_buffer_from_vertex",
    "video_close",
    "video_draw",
    "video_enable_loop",
    "video_get_duration",
    "video_get_format",
    "video_get_position",
    "video_get_status",
    "video_get_volume",
    "video_is_looping",
    "video_open",
    "video_pause",
    "video_resume",
    "video_seek_to",
    "video_set_volume",
    "view_get_camera",
    "view_get_hport",
    "view_get_surface_id",
    "view_get_visible",
    "view_get_wport",
    "view_get_xport",
    "view_get_yport",
    "view_set_camera",
    "view_set_hport",
    "view_set_surface_id",
    "view_set_visible",
    "view_set_wport",
    "view_set_xport",
    "view_set_yport",
    "virtual_key_add",
    "virtual_key_delete",
    "virtual_key_hide",
    "virtual_key_show",
    "wallpaper_set_config",
    "wallpaper_set_subscriptions",
    "weak_ref_alive",
    "weak_ref_any_alive",
    "weak_ref_create",
    "window_center",
    "window_device",
    "window_enable_borderless_fullscreen",
    "window_get_borderless_fullscreen",
    "window_get_caption",
    "window_get_color",
    "window_get_colour",
    "window_get_cursor",
    "window_get_fullscreen",
    "window_get_height",
    "window_get_showborder",
    "window_get_visible_rects",
    "window_get_width",
    "window_get_x",
    "window_get_y",
    "window_handle",
    "window_has_focus",
    "window_mouse_get_delta_x",
    "window_mouse_get_delta_y",
    "window_mouse_get_locked",
    "window_mouse_get_x",
    "window_mouse_get_y",
    "window_mouse_set",
    "window_mouse_set_locked",
    "window_set_caption",
    "window_set_color",
    "window_set_colour",
    "window_set_cursor",
    "window_set_fullscreen",
    "window_set_max_height",
    "window_set_max_width",
    "window_set_min_height",
    "window_set_min_width",
    "window_set_position",
    "window_set_rectangle",
    "window_set_showborder",
    "window_set_size",
    "window_view_mouse_get_x",
    "window_view_mouse_get_y",
    "window_views_mouse_get_x",
    "window_views_mouse_get_y",
    "winphone_tile_background_color",
    "winphone_tile_background_colour",
    "zip_add_file",
    "zip_create",
    "zip_save",
    "zip_unzip",
    "zip_unzip_async"
  ];
  const SYMBOLS = [
    "AudioEffect",
    "AudioEffectType",
    "AudioLFOType",
    "GM_build_date",
    "GM_build_type",
    "GM_is_sandboxed",
    "GM_project_filename",
    "GM_runtime_version",
    "GM_version",
    "NaN",
    "_GMFILE_",
    "_GMFUNCTION_",
    "_GMLINE_",
    "alignmentH",
    "alignmentV",
    "all",
    "animcurvetype_bezier",
    "animcurvetype_catmullrom",
    "animcurvetype_linear",
    "asset_animationcurve",
    "asset_font",
    "asset_object",
    "asset_path",
    "asset_room",
    "asset_script",
    "asset_sequence",
    "asset_shader",
    "asset_sound",
    "asset_sprite",
    "asset_tiles",
    "asset_timeline",
    "asset_unknown",
    "audio_3D",
    "audio_bus_main",
    "audio_falloff_exponent_distance",
    "audio_falloff_exponent_distance_clamped",
    "audio_falloff_exponent_distance_scaled",
    "audio_falloff_inverse_distance",
    "audio_falloff_inverse_distance_clamped",
    "audio_falloff_inverse_distance_scaled",
    "audio_falloff_linear_distance",
    "audio_falloff_linear_distance_clamped",
    "audio_falloff_none",
    "audio_mono",
    "audio_stereo",
    "bboxkind_diamond",
    "bboxkind_ellipse",
    "bboxkind_precise",
    "bboxkind_rectangular",
    "bboxmode_automatic",
    "bboxmode_fullimage",
    "bboxmode_manual",
    "bm_add",
    "bm_dest_alpha",
    "bm_dest_color",
    "bm_dest_colour",
    "bm_inv_dest_alpha",
    "bm_inv_dest_color",
    "bm_inv_dest_colour",
    "bm_inv_src_alpha",
    "bm_inv_src_color",
    "bm_inv_src_colour",
    "bm_max",
    "bm_normal",
    "bm_one",
    "bm_src_alpha",
    "bm_src_alpha_sat",
    "bm_src_color",
    "bm_src_colour",
    "bm_subtract",
    "bm_zero",
    "browser_chrome",
    "browser_edge",
    "browser_firefox",
    "browser_ie",
    "browser_ie_mobile",
    "browser_not_a_browser",
    "browser_opera",
    "browser_safari",
    "browser_safari_mobile",
    "browser_tizen",
    "browser_unknown",
    "browser_windows_store",
    "buffer_bool",
    "buffer_f16",
    "buffer_f32",
    "buffer_f64",
    "buffer_fast",
    "buffer_fixed",
    "buffer_grow",
    "buffer_s16",
    "buffer_s32",
    "buffer_s8",
    "buffer_seek_end",
    "buffer_seek_relative",
    "buffer_seek_start",
    "buffer_string",
    "buffer_text",
    "buffer_u16",
    "buffer_u32",
    "buffer_u64",
    "buffer_u8",
    "buffer_vbuffer",
    "buffer_wrap",
    "c_aqua",
    "c_black",
    "c_blue",
    "c_dkgray",
    "c_dkgrey",
    "c_fuchsia",
    "c_gray",
    "c_green",
    "c_grey",
    "c_lime",
    "c_ltgray",
    "c_ltgrey",
    "c_maroon",
    "c_navy",
    "c_olive",
    "c_orange",
    "c_purple",
    "c_red",
    "c_silver",
    "c_teal",
    "c_white",
    "c_yellow",
    "cache_directory",
    "characterSpacing",
    "cmpfunc_always",
    "cmpfunc_equal",
    "cmpfunc_greater",
    "cmpfunc_greaterequal",
    "cmpfunc_less",
    "cmpfunc_lessequal",
    "cmpfunc_never",
    "cmpfunc_notequal",
    "coreColor",
    "coreColour",
    "cr_appstart",
    "cr_arrow",
    "cr_beam",
    "cr_cross",
    "cr_default",
    "cr_drag",
    "cr_handpoint",
    "cr_hourglass",
    "cr_none",
    "cr_size_all",
    "cr_size_nesw",
    "cr_size_ns",
    "cr_size_nwse",
    "cr_size_we",
    "cr_uparrow",
    "cull_clockwise",
    "cull_counterclockwise",
    "cull_noculling",
    "device_emulator",
    "device_ios_ipad",
    "device_ios_ipad_retina",
    "device_ios_iphone",
    "device_ios_iphone5",
    "device_ios_iphone6",
    "device_ios_iphone6plus",
    "device_ios_iphone_retina",
    "device_ios_unknown",
    "device_tablet",
    "display_landscape",
    "display_landscape_flipped",
    "display_portrait",
    "display_portrait_flipped",
    "dll_cdecl",
    "dll_stdcall",
    "dropShadowEnabled",
    "dropShadowEnabled",
    "ds_type_grid",
    "ds_type_list",
    "ds_type_map",
    "ds_type_priority",
    "ds_type_queue",
    "ds_type_stack",
    "ef_cloud",
    "ef_ellipse",
    "ef_explosion",
    "ef_firework",
    "ef_flare",
    "ef_rain",
    "ef_ring",
    "ef_smoke",
    "ef_smokeup",
    "ef_snow",
    "ef_spark",
    "ef_star",
    "effectsEnabled",
    "effectsEnabled",
    "ev_alarm",
    "ev_animation_end",
    "ev_animation_event",
    "ev_animation_update",
    "ev_async_audio_playback",
    "ev_async_audio_playback_ended",
    "ev_async_audio_recording",
    "ev_async_dialog",
    "ev_async_push_notification",
    "ev_async_save_load",
    "ev_async_save_load",
    "ev_async_social",
    "ev_async_system_event",
    "ev_async_web",
    "ev_async_web_cloud",
    "ev_async_web_iap",
    "ev_async_web_image_load",
    "ev_async_web_networking",
    "ev_async_web_steam",
    "ev_audio_playback",
    "ev_audio_playback_ended",
    "ev_audio_recording",
    "ev_boundary",
    "ev_boundary_view0",
    "ev_boundary_view1",
    "ev_boundary_view2",
    "ev_boundary_view3",
    "ev_boundary_view4",
    "ev_boundary_view5",
    "ev_boundary_view6",
    "ev_boundary_view7",
    "ev_broadcast_message",
    "ev_cleanup",
    "ev_collision",
    "ev_create",
    "ev_destroy",
    "ev_dialog_async",
    "ev_draw",
    "ev_draw_begin",
    "ev_draw_end",
    "ev_draw_normal",
    "ev_draw_post",
    "ev_draw_pre",
    "ev_end_of_path",
    "ev_game_end",
    "ev_game_start",
    "ev_gesture",
    "ev_gesture_double_tap",
    "ev_gesture_drag_end",
    "ev_gesture_drag_start",
    "ev_gesture_dragging",
    "ev_gesture_flick",
    "ev_gesture_pinch_end",
    "ev_gesture_pinch_in",
    "ev_gesture_pinch_out",
    "ev_gesture_pinch_start",
    "ev_gesture_rotate_end",
    "ev_gesture_rotate_start",
    "ev_gesture_rotating",
    "ev_gesture_tap",
    "ev_global_gesture_double_tap",
    "ev_global_gesture_drag_end",
    "ev_global_gesture_drag_start",
    "ev_global_gesture_dragging",
    "ev_global_gesture_flick",
    "ev_global_gesture_pinch_end",
    "ev_global_gesture_pinch_in",
    "ev_global_gesture_pinch_out",
    "ev_global_gesture_pinch_start",
    "ev_global_gesture_rotate_end",
    "ev_global_gesture_rotate_start",
    "ev_global_gesture_rotating",
    "ev_global_gesture_tap",
    "ev_global_left_button",
    "ev_global_left_press",
    "ev_global_left_release",
    "ev_global_middle_button",
    "ev_global_middle_press",
    "ev_global_middle_release",
    "ev_global_right_button",
    "ev_global_right_press",
    "ev_global_right_release",
    "ev_gui",
    "ev_gui_begin",
    "ev_gui_end",
    "ev_joystick1_button1",
    "ev_joystick1_button2",
    "ev_joystick1_button3",
    "ev_joystick1_button4",
    "ev_joystick1_button5",
    "ev_joystick1_button6",
    "ev_joystick1_button7",
    "ev_joystick1_button8",
    "ev_joystick1_down",
    "ev_joystick1_left",
    "ev_joystick1_right",
    "ev_joystick1_up",
    "ev_joystick2_button1",
    "ev_joystick2_button2",
    "ev_joystick2_button3",
    "ev_joystick2_button4",
    "ev_joystick2_button5",
    "ev_joystick2_button6",
    "ev_joystick2_button7",
    "ev_joystick2_button8",
    "ev_joystick2_down",
    "ev_joystick2_left",
    "ev_joystick2_right",
    "ev_joystick2_up",
    "ev_keyboard",
    "ev_keypress",
    "ev_keyrelease",
    "ev_left_button",
    "ev_left_press",
    "ev_left_release",
    "ev_middle_button",
    "ev_middle_press",
    "ev_middle_release",
    "ev_mouse",
    "ev_mouse_enter",
    "ev_mouse_leave",
    "ev_mouse_wheel_down",
    "ev_mouse_wheel_up",
    "ev_no_button",
    "ev_no_more_health",
    "ev_no_more_lives",
    "ev_other",
    "ev_outside",
    "ev_outside_view0",
    "ev_outside_view1",
    "ev_outside_view2",
    "ev_outside_view3",
    "ev_outside_view4",
    "ev_outside_view5",
    "ev_outside_view6",
    "ev_outside_view7",
    "ev_pre_create",
    "ev_push_notification",
    "ev_right_button",
    "ev_right_press",
    "ev_right_release",
    "ev_room_end",
    "ev_room_start",
    "ev_social",
    "ev_step",
    "ev_step_begin",
    "ev_step_end",
    "ev_step_normal",
    "ev_system_event",
    "ev_trigger",
    "ev_user0",
    "ev_user1",
    "ev_user10",
    "ev_user11",
    "ev_user12",
    "ev_user13",
    "ev_user14",
    "ev_user15",
    "ev_user2",
    "ev_user3",
    "ev_user4",
    "ev_user5",
    "ev_user6",
    "ev_user7",
    "ev_user8",
    "ev_user9",
    "ev_web_async",
    "ev_web_cloud",
    "ev_web_iap",
    "ev_web_image_load",
    "ev_web_networking",
    "ev_web_sound_load",
    "ev_web_steam",
    "fa_archive",
    "fa_bottom",
    "fa_center",
    "fa_directory",
    "fa_hidden",
    "fa_left",
    "fa_middle",
    "fa_none",
    "fa_readonly",
    "fa_right",
    "fa_sysfile",
    "fa_top",
    "fa_volumeid",
    "false",
    "frameSizeX",
    "frameSizeY",
    "gamespeed_fps",
    "gamespeed_microseconds",
    "global",
    "glowColor",
    "glowColour",
    "glowEnabled",
    "glowEnabled",
    "glowEnd",
    "glowStart",
    "gp_axis_acceleration_x",
    "gp_axis_acceleration_y",
    "gp_axis_acceleration_z",
    "gp_axis_angular_velocity_x",
    "gp_axis_angular_velocity_y",
    "gp_axis_angular_velocity_z",
    "gp_axis_orientation_w",
    "gp_axis_orientation_x",
    "gp_axis_orientation_y",
    "gp_axis_orientation_z",
    "gp_axislh",
    "gp_axislv",
    "gp_axisrh",
    "gp_axisrv",
    "gp_face1",
    "gp_face2",
    "gp_face3",
    "gp_face4",
    "gp_padd",
    "gp_padl",
    "gp_padr",
    "gp_padu",
    "gp_select",
    "gp_shoulderl",
    "gp_shoulderlb",
    "gp_shoulderr",
    "gp_shoulderrb",
    "gp_start",
    "gp_stickl",
    "gp_stickr",
    "iap_available",
    "iap_canceled",
    "iap_ev_consume",
    "iap_ev_product",
    "iap_ev_purchase",
    "iap_ev_restore",
    "iap_ev_storeload",
    "iap_failed",
    "iap_purchased",
    "iap_refunded",
    "iap_status_available",
    "iap_status_loading",
    "iap_status_processing",
    "iap_status_restoring",
    "iap_status_unavailable",
    "iap_status_uninitialised",
    "iap_storeload_failed",
    "iap_storeload_ok",
    "iap_unavailable",
    "infinity",
    "kbv_autocapitalize_characters",
    "kbv_autocapitalize_none",
    "kbv_autocapitalize_sentences",
    "kbv_autocapitalize_words",
    "kbv_returnkey_continue",
    "kbv_returnkey_default",
    "kbv_returnkey_done",
    "kbv_returnkey_emergency",
    "kbv_returnkey_go",
    "kbv_returnkey_google",
    "kbv_returnkey_join",
    "kbv_returnkey_next",
    "kbv_returnkey_route",
    "kbv_returnkey_search",
    "kbv_returnkey_send",
    "kbv_returnkey_yahoo",
    "kbv_type_ascii",
    "kbv_type_default",
    "kbv_type_email",
    "kbv_type_numbers",
    "kbv_type_phone",
    "kbv_type_phone_name",
    "kbv_type_url",
    "layerelementtype_background",
    "layerelementtype_instance",
    "layerelementtype_oldtilemap",
    "layerelementtype_particlesystem",
    "layerelementtype_sequence",
    "layerelementtype_sprite",
    "layerelementtype_tile",
    "layerelementtype_tilemap",
    "layerelementtype_undefined",
    "leaderboard_type_number",
    "leaderboard_type_time_mins_secs",
    "lighttype_dir",
    "lighttype_point",
    "lineSpacing",
    "m_axisx",
    "m_axisx_gui",
    "m_axisy",
    "m_axisy_gui",
    "m_scroll_down",
    "m_scroll_up",
    "matrix_projection",
    "matrix_view",
    "matrix_world",
    "mb_any",
    "mb_left",
    "mb_middle",
    "mb_none",
    "mb_right",
    "mb_side1",
    "mb_side2",
    "mip_markedonly",
    "mip_off",
    "mip_on",
    "network_config_avoid_time_wait",
    "network_config_connect_timeout",
    "network_config_disable_multicast",
    "network_config_disable_reliable_udp",
    "network_config_enable_multicast",
    "network_config_enable_reliable_udp",
    "network_config_use_non_blocking_socket",
    "network_config_websocket_protocol",
    "network_connect_active",
    "network_connect_blocking",
    "network_connect_nonblocking",
    "network_connect_none",
    "network_connect_passive",
    "network_send_binary",
    "network_send_text",
    "network_socket_bluetooth",
    "network_socket_tcp",
    "network_socket_udp",
    "network_socket_ws",
    "network_socket_wss",
    "network_type_connect",
    "network_type_data",
    "network_type_disconnect",
    "network_type_down",
    "network_type_non_blocking_connect",
    "network_type_up",
    "network_type_up_failed",
    "nineslice_blank",
    "nineslice_bottom",
    "nineslice_center",
    "nineslice_centre",
    "nineslice_hide",
    "nineslice_left",
    "nineslice_mirror",
    "nineslice_repeat",
    "nineslice_right",
    "nineslice_stretch",
    "nineslice_top",
    "noone",
    "of_challenge_lose",
    "of_challenge_tie",
    "of_challenge_win",
    "os_android",
    "os_gdk",
    "os_gxgames",
    "os_ios",
    "os_linux",
    "os_macosx",
    "os_operagx",
    "os_permission_denied",
    "os_permission_denied_dont_request",
    "os_permission_granted",
    "os_ps3",
    "os_ps4",
    "os_ps5",
    "os_psvita",
    "os_switch",
    "os_tvos",
    "os_unknown",
    "os_uwp",
    "os_win8native",
    "os_windows",
    "os_winphone",
    "os_xboxone",
    "os_xboxseriesxs",
    "other",
    "outlineColor",
    "outlineColour",
    "outlineDist",
    "outlineEnabled",
    "outlineEnabled",
    "paragraphSpacing",
    "path_action_continue",
    "path_action_restart",
    "path_action_reverse",
    "path_action_stop",
    "phy_debug_render_aabb",
    "phy_debug_render_collision_pairs",
    "phy_debug_render_coms",
    "phy_debug_render_core_shapes",
    "phy_debug_render_joints",
    "phy_debug_render_obb",
    "phy_debug_render_shapes",
    "phy_joint_anchor_1_x",
    "phy_joint_anchor_1_y",
    "phy_joint_anchor_2_x",
    "phy_joint_anchor_2_y",
    "phy_joint_angle",
    "phy_joint_angle_limits",
    "phy_joint_damping_ratio",
    "phy_joint_frequency",
    "phy_joint_length_1",
    "phy_joint_length_2",
    "phy_joint_lower_angle_limit",
    "phy_joint_max_force",
    "phy_joint_max_length",
    "phy_joint_max_motor_force",
    "phy_joint_max_motor_torque",
    "phy_joint_max_torque",
    "phy_joint_motor_force",
    "phy_joint_motor_speed",
    "phy_joint_motor_torque",
    "phy_joint_reaction_force_x",
    "phy_joint_reaction_force_y",
    "phy_joint_reaction_torque",
    "phy_joint_speed",
    "phy_joint_translation",
    "phy_joint_upper_angle_limit",
    "phy_particle_data_flag_category",
    "phy_particle_data_flag_color",
    "phy_particle_data_flag_colour",
    "phy_particle_data_flag_position",
    "phy_particle_data_flag_typeflags",
    "phy_particle_data_flag_velocity",
    "phy_particle_flag_colormixing",
    "phy_particle_flag_colourmixing",
    "phy_particle_flag_elastic",
    "phy_particle_flag_powder",
    "phy_particle_flag_spring",
    "phy_particle_flag_tensile",
    "phy_particle_flag_viscous",
    "phy_particle_flag_wall",
    "phy_particle_flag_water",
    "phy_particle_flag_zombie",
    "phy_particle_group_flag_rigid",
    "phy_particle_group_flag_solid",
    "pi",
    "pointer_invalid",
    "pointer_null",
    "pr_linelist",
    "pr_linestrip",
    "pr_pointlist",
    "pr_trianglefan",
    "pr_trianglelist",
    "pr_trianglestrip",
    "ps_distr_gaussian",
    "ps_distr_invgaussian",
    "ps_distr_linear",
    "ps_mode_burst",
    "ps_mode_stream",
    "ps_shape_diamond",
    "ps_shape_ellipse",
    "ps_shape_line",
    "ps_shape_rectangle",
    "pt_shape_circle",
    "pt_shape_cloud",
    "pt_shape_disk",
    "pt_shape_explosion",
    "pt_shape_flare",
    "pt_shape_line",
    "pt_shape_pixel",
    "pt_shape_ring",
    "pt_shape_smoke",
    "pt_shape_snow",
    "pt_shape_spark",
    "pt_shape_sphere",
    "pt_shape_square",
    "pt_shape_star",
    "rollback_chat_message",
    "rollback_connect_error",
    "rollback_connect_info",
    "rollback_connected_to_peer",
    "rollback_connection_rejected",
    "rollback_disconnected_from_peer",
    "rollback_end_game",
    "rollback_game_full",
    "rollback_game_info",
    "rollback_game_interrupted",
    "rollback_game_resumed",
    "rollback_high_latency",
    "rollback_player_prefs",
    "rollback_protocol_rejected",
    "rollback_synchronized_with_peer",
    "rollback_synchronizing_with_peer",
    "self",
    "seqaudiokey_loop",
    "seqaudiokey_oneshot",
    "seqdir_left",
    "seqdir_right",
    "seqinterpolation_assign",
    "seqinterpolation_lerp",
    "seqplay_loop",
    "seqplay_oneshot",
    "seqplay_pingpong",
    "seqtextkey_bottom",
    "seqtextkey_center",
    "seqtextkey_justify",
    "seqtextkey_left",
    "seqtextkey_middle",
    "seqtextkey_right",
    "seqtextkey_top",
    "seqtracktype_audio",
    "seqtracktype_bool",
    "seqtracktype_clipmask",
    "seqtracktype_clipmask_mask",
    "seqtracktype_clipmask_subject",
    "seqtracktype_color",
    "seqtracktype_colour",
    "seqtracktype_empty",
    "seqtracktype_graphic",
    "seqtracktype_group",
    "seqtracktype_instance",
    "seqtracktype_message",
    "seqtracktype_moment",
    "seqtracktype_particlesystem",
    "seqtracktype_real",
    "seqtracktype_sequence",
    "seqtracktype_spriteframes",
    "seqtracktype_string",
    "seqtracktype_text",
    "shadowColor",
    "shadowColour",
    "shadowOffsetX",
    "shadowOffsetY",
    "shadowSoftness",
    "sprite_add_ext_error_cancelled",
    "sprite_add_ext_error_decompressfailed",
    "sprite_add_ext_error_loadfailed",
    "sprite_add_ext_error_setupfailed",
    "sprite_add_ext_error_spritenotfound",
    "sprite_add_ext_error_unknown",
    "spritespeed_framespergameframe",
    "spritespeed_framespersecond",
    "surface_r16float",
    "surface_r32float",
    "surface_r8unorm",
    "surface_rg8unorm",
    "surface_rgba16float",
    "surface_rgba32float",
    "surface_rgba4unorm",
    "surface_rgba8unorm",
    "texturegroup_status_fetched",
    "texturegroup_status_loaded",
    "texturegroup_status_loading",
    "texturegroup_status_unloaded",
    "tf_anisotropic",
    "tf_linear",
    "tf_point",
    "thickness",
    "tile_flip",
    "tile_index_mask",
    "tile_mirror",
    "tile_rotate",
    "time_source_expire_after",
    "time_source_expire_nearest",
    "time_source_game",
    "time_source_global",
    "time_source_state_active",
    "time_source_state_initial",
    "time_source_state_paused",
    "time_source_state_stopped",
    "time_source_units_frames",
    "time_source_units_seconds",
    "timezone_local",
    "timezone_utc",
    "tm_countvsyncs",
    "tm_sleep",
    "tm_systemtiming",
    "true",
    "ty_real",
    "ty_string",
    "undefined",
    "vertex_type_color",
    "vertex_type_colour",
    "vertex_type_float1",
    "vertex_type_float2",
    "vertex_type_float3",
    "vertex_type_float4",
    "vertex_type_ubyte4",
    "vertex_usage_binormal",
    "vertex_usage_blendindices",
    "vertex_usage_blendweight",
    "vertex_usage_color",
    "vertex_usage_colour",
    "vertex_usage_depth",
    "vertex_usage_fog",
    "vertex_usage_normal",
    "vertex_usage_position",
    "vertex_usage_psize",
    "vertex_usage_sample",
    "vertex_usage_tangent",
    "vertex_usage_texcoord",
    "video_format_rgba",
    "video_format_yuv",
    "video_status_closed",
    "video_status_paused",
    "video_status_playing",
    "video_status_preparing",
    "vk_add",
    "vk_alt",
    "vk_anykey",
    "vk_backspace",
    "vk_control",
    "vk_decimal",
    "vk_delete",
    "vk_divide",
    "vk_down",
    "vk_end",
    "vk_enter",
    "vk_escape",
    "vk_f1",
    "vk_f10",
    "vk_f11",
    "vk_f12",
    "vk_f2",
    "vk_f3",
    "vk_f4",
    "vk_f5",
    "vk_f6",
    "vk_f7",
    "vk_f8",
    "vk_f9",
    "vk_home",
    "vk_insert",
    "vk_lalt",
    "vk_lcontrol",
    "vk_left",
    "vk_lshift",
    "vk_multiply",
    "vk_nokey",
    "vk_numpad0",
    "vk_numpad1",
    "vk_numpad2",
    "vk_numpad3",
    "vk_numpad4",
    "vk_numpad5",
    "vk_numpad6",
    "vk_numpad7",
    "vk_numpad8",
    "vk_numpad9",
    "vk_pagedown",
    "vk_pageup",
    "vk_pause",
    "vk_printscreen",
    "vk_ralt",
    "vk_rcontrol",
    "vk_return",
    "vk_right",
    "vk_rshift",
    "vk_shift",
    "vk_space",
    "vk_subtract",
    "vk_tab",
    "vk_up",
    "wallpaper_config",
    "wallpaper_subscription_data",
    "wrap"
  ];
  const LANGUAGE_VARIABLES = [
    "alarm",
    "application_surface",
    "argument",
    "argument0",
    "argument1",
    "argument2",
    "argument3",
    "argument4",
    "argument5",
    "argument6",
    "argument7",
    "argument8",
    "argument9",
    "argument10",
    "argument11",
    "argument12",
    "argument13",
    "argument14",
    "argument15",
    "argument_count",
    "async_load",
    "background_color",
    "background_colour",
    "background_showcolor",
    "background_showcolour",
    "bbox_bottom",
    "bbox_left",
    "bbox_right",
    "bbox_top",
    "browser_height",
    "browser_width",
    "colour?ColourTrack",
    "current_day",
    "current_hour",
    "current_minute",
    "current_month",
    "current_second",
    "current_time",
    "current_weekday",
    "current_year",
    "cursor_sprite",
    "debug_mode",
    "delta_time",
    "depth",
    "direction",
    "display_aa",
    "drawn_by_sequence",
    "event_action",
    "event_data",
    "event_number",
    "event_object",
    "event_type",
    "font_texture_page_size",
    "fps",
    "fps_real",
    "friction",
    "game_display_name",
    "game_id",
    "game_project_name",
    "game_save_id",
    "gravity",
    "gravity_direction",
    "health",
    "hspeed",
    "iap_data",
    "id",
    "image_alpha",
    "image_angle",
    "image_blend",
    "image_index",
    "image_number",
    "image_speed",
    "image_xscale",
    "image_yscale",
    "in_collision_tree",
    "in_sequence",
    "instance_count",
    "instance_id",
    "keyboard_key",
    "keyboard_lastchar",
    "keyboard_lastkey",
    "keyboard_string",
    "layer",
    "lives",
    "longMessage",
    "managed",
    "mask_index",
    "message",
    "mouse_button",
    "mouse_lastbutton",
    "mouse_x",
    "mouse_y",
    "object_index",
    "os_browser",
    "os_device",
    "os_type",
    "os_version",
    "path_endaction",
    "path_index",
    "path_orientation",
    "path_position",
    "path_positionprevious",
    "path_scale",
    "path_speed",
    "persistent",
    "phy_active",
    "phy_angular_damping",
    "phy_angular_velocity",
    "phy_bullet",
    "phy_col_normal_x",
    "phy_col_normal_y",
    "phy_collision_points",
    "phy_collision_x",
    "phy_collision_y",
    "phy_com_x",
    "phy_com_y",
    "phy_dynamic",
    "phy_fixed_rotation",
    "phy_inertia",
    "phy_kinematic",
    "phy_linear_damping",
    "phy_linear_velocity_x",
    "phy_linear_velocity_y",
    "phy_mass",
    "phy_position_x",
    "phy_position_xprevious",
    "phy_position_y",
    "phy_position_yprevious",
    "phy_rotation",
    "phy_sleeping",
    "phy_speed",
    "phy_speed_x",
    "phy_speed_y",
    "player_avatar_sprite",
    "player_avatar_url",
    "player_id",
    "player_local",
    "player_type",
    "player_user_id",
    "program_directory",
    "rollback_api_server",
    "rollback_confirmed_frame",
    "rollback_current_frame",
    "rollback_event_id",
    "rollback_event_param",
    "rollback_game_running",
    "room",
    "room_first",
    "room_height",
    "room_last",
    "room_persistent",
    "room_speed",
    "room_width",
    "score",
    "script",
    "sequence_instance",
    "solid",
    "speed",
    "sprite_height",
    "sprite_index",
    "sprite_width",
    "sprite_xoffset",
    "sprite_yoffset",
    "stacktrace",
    "temp_directory",
    "timeline_index",
    "timeline_loop",
    "timeline_position",
    "timeline_running",
    "timeline_speed",
    "view_camera",
    "view_current",
    "view_enabled",
    "view_hport",
    "view_surface_id",
    "view_visible",
    "view_wport",
    "view_xport",
    "view_yport",
    "visible",
    "vspeed",
    "webgl_enabled",
    "working_directory",
    "x",
    "xprevious",
    "xstart",
    "y",
    "yprevious",
    "ystart"
  ];
  return {
    name: 'GML',
    case_insensitive: false, // language is case-insensitive
    keywords: {
      keyword: KEYWORDS,
      built_in: BUILT_INS,
      symbol: SYMBOLS,
      "variable.language": LANGUAGE_VARIABLES
    },
    contains: [
      hljs.C_LINE_COMMENT_MODE,
      hljs.C_BLOCK_COMMENT_MODE,
      hljs.APOS_STRING_MODE,
      hljs.QUOTE_STRING_MODE,
      hljs.C_NUMBER_MODE
    ]
  };
}

module.exports = gml;
