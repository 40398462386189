import { ChatFunctionCard, ChatSetupCard, Command, DataMgr, Language, SavedPromptX } from "../data-mgr/data-mgr";
import { eventBus } from 'global/event-bus';
import { XService } from "global/openai/xservice";
import { XAuth } from "global/auth/xauth";
import { Conversation, ConversationHeader } from "global/_zcom/Model";
export class XMgr {
  static instance: XMgr = null;

  xSet: XSet;
  currentItem: XItem;
  cmds: Command[] = null;
  languages: Language[] = null;
  otherLanguages: Language[];
  cards: Card[] = null;

  helloWorld: HelloWorld[] = null;
  savedPrompts: SavedPromptX[] = null;
  savedChatlist: ConversationHeader[] = null;

  azConfig: TConfig = null;
  throttleReceipt: ReservationResult = null;
  throttleStatus: Status = Status.None;

  constructor(load: boolean = true) {
    if (XMgr.instance === null) {
      XMgr.instance = this;
      this.xSet = { topXItems: [] };

      if (load) {
        this.loadSettings();
      }
    }
  }

  public async loadSettings() {
    if (!this.cmds) {
      this.loadCommands();
    }

    if (!this.languages) {
      this.loadLanguages();
    }

    if (!this.cards) {
      this.loadCards();
    }

    await XService.getAzureConfig();

    if (!this.savedPrompts) {
      this.loadSavedPrompts();
    }

    if (!this.helloWorld) {
      const hello = localStorage.getItem('hello');
      const isHello = hello && hello === 'true';
      if (isHello) {
        this.loadHello();
      }
    }

    await XService.loadModes(XMgr.instance);
  }

  public async loadSavedPrompts(): Promise<SavedPromptX[]> {
    const prompts = localStorage.getItem('saved-prompts');
    if (prompts) {
      try {
        this.savedPrompts = JSON.parse(prompts);
        DataMgr.doEvent('saved-prompts-loaded');
      }
      catch {
        this.savedPrompts = [];
        localStorage.setItem('saved-prompts', JSON.stringify(this.savedPrompts));
      }
    }
    else {
      this.savedPrompts = [];
      localStorage.setItem('saved-prompts', JSON.stringify(this.savedPrompts));
    }

    return this.savedPrompts;
  }

  savePrompts() {
    localStorage.setItem('saved-prompts', JSON.stringify(this.savedPrompts));
  }

  public async loadChatlist(): Promise<ConversationHeader[]> {
    const chats = localStorage.getItem('saved-chatlist');
    if (chats) {
      try {
        this.savedChatlist = JSON.parse(chats);
        DataMgr.doEvent('saved-chatlist-loaded');
      }
      catch {
        this.savedChatlist = [];
        localStorage.setItem('saved-chatlist', JSON.stringify(this.savedChatlist));
      }
    }
    else {
      this.savedChatlist = [];
      localStorage.setItem('saved-chatlist', JSON.stringify(this.savedChatlist));
    }

    return this.savedChatlist;
  }

  saveChatlist() {
    localStorage.setItem('saved-chatlist', JSON.stringify(this.savedChatlist));
  }

  refreshSaveChat(chat: Conversation) {
    const firstUserMsg = chat.messages.find(m => m.type === 'user');
    if (!firstUserMsg) {
      return;
    }

    var msg = firstUserMsg.message;
    if (msg.length > 80) {
      msg = msg.substring(0, 80) + '...';
    }
    var item = {
      title: msg, uid: chat.uid, created: chat.created, updated: chat.updated
    };

    var isChanged = false;
    const chatIndex = this.savedChatlist.findIndex(c => c.uid === chat.uid);
    if (chatIndex >= 0) {
      if (this.savedChatlist[chatIndex].title !== item.title) {
        isChanged = true;
      }

      this.savedChatlist[chatIndex] = item;
    }
    else {
      isChanged = true;
      this.savedChatlist.push(item);
    }

    localStorage.setItem('saved-chat-' + chat.uid, JSON.stringify(chat));
    this.saveChatlist();

    return isChanged;
  }

  loadChat(uid: string): Conversation {
    const chat = localStorage.getItem('saved-chat-' + uid);
    if (chat) {
      try {
        return Conversation.fromJSON(JSON.parse(chat));
      }
      catch {
        return null;
      }
    }

    return null;
  }

  deleteChat(uid: string) {
    const chatIndex = this.savedChatlist.findIndex(c => c.uid === uid);
    if (chatIndex >= 0) {
      this.savedChatlist.splice(chatIndex, 1);
      localStorage.removeItem('saved-chat-' + uid);
      this.saveChatlist();
    }
  }

  async clearChatHistory(choice: string) {
    const eraseChatList = [];
    const newList = [];
    this.savedChatlist.forEach(c => {
      if (choice === 'clearAll' || (choice === 'clearNonStarred' && !c.isStarred) || (choice === 'clearSelections' && c.isSelected)) {
        eraseChatList.push(c);
      } else {
        newList.push(c);
      }
    });

    eraseChatList.map(c => {
      localStorage.removeItem('saved-chat-' + c.uid);
    });

    localStorage.setItem('saved-chatlist', JSON.stringify(newList));
    this.savedChatlist = newList;

    // remove all localStorage that starts with 'saved-chat-' as backup
    if (choice === 'clearAll') {
      Object.keys(localStorage).forEach(key => {
        if (key.startsWith('saved-chat-')) {
          localStorage.removeItem(key);
        }
      });
    }
  }

  static SaveChatCards(chatCards: ChatSetupCard[]) {
    localStorage.setItem('chat-cards', JSON.stringify(chatCards));
  }

  static SaveFunctionCards(chatCards: ChatFunctionCard[]) {
    localStorage.setItem('function-cards', JSON.stringify(chatCards));
    eventBus.emit('function-cards', { cards: chatCards, isError: false });
  }

  static LoadChatCards() {
    const chatCards = localStorage.getItem('chat-cards');
    if (chatCards) {
      try {
        return JSON.parse(chatCards);
      }
      catch {
        return [];
      }
    }
    else {
      return [];
    }
  }

  static async LoadChatCategories() {
    const chatCategories = localStorage.getItem('chat-categories');
    if (chatCategories) {
      try {
        return JSON.parse(chatCategories);
      }
      catch {
        return await XMgr.loadFile('/assets/data/chatcategories.json');
      }
    }
    else {
      return await XMgr.loadFile('/assets/data/chatcategories.json');
    }
  }

  static LoadFunctionCards() {
    const fnCards = localStorage.getItem('function-cards');
    if (fnCards) {
      try {
        var xCards = JSON.parse(fnCards);
        if (xCards === null) {
          xCards = [];
        }
        eventBus.emit('function-cards', { cards: xCards, isError: false });
        return xCards;
      }
      catch {
        return [];
      }
    }
    else {
      return [];
    }
  }

  private async loadCommands() {
    const url = "/assets/data/cmds.json";
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      try {
        this.cmds = await response.json();
        DataMgr.doEvent('cmds-loaded');
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  private async loadLanguages() {
    const url = "/assets/data/lang.json";
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      try {
        const responseJson = (await response.json());
        this.languages = responseJson.languages;
        const primaryLanguages = this.languages.map(l => l.language);
        this.otherLanguages = responseJson.otherLanguages.filter(l => !primaryLanguages.includes(l.language));
        DataMgr.doEvent('lang-loaded');
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  static async LoadSettings() {
    if (XMgr.instance) {
      XMgr.instance.loadSettings();
    }
  }

  static GetHelloFor(lang: string) {
    if (XMgr.instance && XMgr.instance.helloWorld) {
      let hellos = XMgr.instance.helloWorld.filter(h => h.Key.toLowerCase() === lang.toLowerCase());
      if (hellos.length === 0) {
        const hw = XMgr.instance.helloWorld.sort((a, b) => b.Key.length - a.Key.length);
        hellos = hw.filter(h => h.Key.toLowerCase().startsWith(lang.toLowerCase()));
        // do akas here
      }

      if (hellos.length > 0) {
        return hellos[0].Value;
      }
    }

    return '';
  }

  static HandleDark() {
    const isDark = localStorage.getItem('dark') === 'true';

    var ionApp = document.getElementsByTagName('ion-app');

    //HACK: REENABLE
    // if (ionApp && ionApp.length > 0) {
    //   toggleClass(ionApp[0] as Element, 'nightmode', isDark);
    //   toggleClass(ionApp[0] as Element, 'daymode', !isDark);
    // }
  }


  // private async loadModesLocal() {
  //   const url = "/assets/config/init.json";
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   };
  //   const response = await fetch(url, options);
  //   if (response.ok) {
  //     try {
  //       const responseJson = (await response.json());

  //       this.modes = responseJson.Modes.map((mode) => ({
  //         name: mode,
  //         models: responseJson.ModeModels[mode].map((model) => ({ name: model })),
  //       }));

  //       DataMgr.doEvent('modes-loaded');
  //     } catch (ex) {
  //       console.error(ex);
  //     }
  //   }
  // }

  static async loadFile(url: string, withToken: boolean = false) {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    if (withToken) {
      const token = await XAuth.getToken();
      if (token) {
        options.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    const response = await fetch(url, options);
    if (response.ok) {
      try {
        const responseJson = (await response.json());
        return responseJson;
      } catch (ex) {
        console.error(ex);
      }
    }

    return null;
  }

  private async loadCards() {
    const url = "/assets/data/cards.json";
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      try {
        const responseJson = (await response.json());
        this.cards = responseJson;
        DataMgr.doEvent('cards-loaded');
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  private async loadHello() {
    const url = "/assets/data/helloworld.json";
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      try {
        const responseJson = (await response.json());
        this.helloWorld = responseJson.HelloWorld;
        DataMgr.doEvent('hello-loaded');
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  static Instance(): XMgr {
    if (XMgr.instance === null) {
      new XMgr();
    }

    return XMgr.instance;
  }

  getCommand(id: string): Command {
    const availCmds = XMgr.instance.cmds.filter(c => c.id === id);
    if (availCmds.length === 1) {
      return availCmds[0];
    }

    return null;
  }

  getCommandFromPath(path: string): Command {
    const lowerPath = path.toLowerCase();
    const availCmds = XMgr.instance.cmds.filter(c => lowerPath.startsWith(c.url.toLowerCase()))
      .sort(c => c.url.length);
    if (availCmds.length > 0) {
      return availCmds[availCmds.length - 1];
    }

    return null;
  }

  static GetCard(sampleId: string) {
    const id = parseInt(sampleId);
    if (!Number.isNaN(id)) {
      const cards = XMgr.instance.cards.filter(c => c.id === id);
      if (cards.length > 0) {
        return cards[0];
      }
    }

    return null;
  }

  static async fetchRSS(url) {
    const response = await fetch(url);
    const text = await response.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, "text/xml");
    return xmlDoc;
  }

  static parseRSS(xmlDoc) {
    const items = xmlDoc.getElementsByTagName("item");
    const parsedItems = [];

    for (let i = 0; i < items.length; i++) {
      const title = items[i].getElementsByTagName("title")[0].textContent;
      const link = items[i].getElementsByTagName("link")[0].textContent;
      const description = items[i].getElementsByTagName("description")[0].textContent;
      const pubDate = items[i].getElementsByTagName("pubDate")[0].textContent;

      parsedItems.push({ title, link, description, pubDate });
    }

    return parsedItems;
  }

  // async getEmbeddings(text) {
  //   const model = 'text-embedding-ada-002';
  //   //        const call = async (key) => await XMgr.instance.callChat(model, this.conversation, sysmsg.value, key);
  //   var service = await XMgr.instance.switchModes();

  //   const call = async (key) => await this._getOpenAIApiEmbeddings(key, text, model);
  //   const cancelThrottle = () => { };

  //   if (service === 'azureopenai') {
  //     const pri = localStorage.getItem('priority') ?? 'medium';
  //     return {
  //       result: this.throttle(model, call, false, cancelThrottle, '', pri).catch(e => {
  //         return { isError: true, message: e.toString() };
  //       }), isError: false
  //     };

  //     // if (XMgr.instance.isThrottled()) {
  //     //   const ckThrottle = () => {
  //     //     if (XMgr.instance.throttleReceipt && XMgr.instance.throttleReceipt.info) {
  //     //       // if (this.isLoading) {
  //     //       //   setTimeout(ckThrottle, 1000);
  //     //       // }
  //     //       // else {
  //     //       //   // this.isQueued = false;
  //     //       // }
  //     //     }
  //     //   };
  //     //   setTimeout(ckThrottle, 25);
  //     // }
  //   }
  //   else {
  //     const key = localStorage.getKey('openai-key') ?? '';
  //     if (key) {
  //       return {
  //         result: await call(key).catch(e => {
  //           return { isError: true, message: e.toString() };
  //         }), isError: false
  //       };
  //     }
  //   }

  // }



  // async xNew(subject: string) {
  //   const api = this.urlBase + 'convert';
  //   await getUrlX(api, true).then(
  //     data => {
  //       XMgr.instance.xSet.topXItems.push(data);
  //       XMgr.instance.currentItem = data;
  //       DataMgr.doEvent('x-update');
  //     }
  //   )
  // }

  // async xNext(name: string, index: number, subject: string, item: XItem) {
  //   const api = this.urlBase + '?subject=' + subject + '&key=' + name;
  //   await getUrlX(api, true).then(
  //     data => {
  //       item.keyConcepts[index] = data;
  //       item.keyConcepts[index].parent = item;
  //       XMgr.instance.currentItem = data;
  //       DataMgr.doEvent('x-update');
  //     }
  //   )
  // }

  // async testNew() {
  //   const api = this.urlBase + 'test';
  //   await getUrlX(api, true).then(
  //     data => {
  //       XMgr.instance.xSet.topXItems.push(data);
  //       XMgr.instance.currentItem = data;
  //       DataMgr.doEvent('x-update');
  //     }
  //   )
  // }


}

export interface XSet {
  topXItems: XItem[];
}

export interface XItem {
  subject: string;
  name: string;
  parent: XItem;
  keyConcepts: XItem[];
  hasKeyConcepts: boolean;
  shortDescription: string;
  longDescription: string;
  errorCode: string;
  errorMessage: string;
}

export interface Card {
  id: number;
  cmd: string;
  title: string;
  source: string;
  target: string;
  desc: string;
  sourcecode: string;
  targetcode: string;
  sourcemessage: string;
  targetmessage: string;
}

export interface HelloWorld {
  Key: string;
  Value: string;
}

export interface Mode {
  name: string;
  models: Model[];
}

export interface Model {
  name: string;
}

export interface TConfig {
  version: string;
  app: string;
  throttleUrl: string;
  resourceName: string;
}

// convert to interface
export interface ReservationResult {
  key: string;
  id: number;
  status: Status;
  slots: number;
  allocatedSlots: number;
  minimumWaitSeconds: number;
  info: QueueInfo;
  values: { [key: string]: string };
}

export enum Status {
  None = 0,
  Awaiting = 1,
  Ready = 2,
  Granted = 3, //only used to hand to caller when consuming - server is InUse
  Allocated = 4,
  InUse = 5,
  Completed = 6,
  Released = 7,
  Abandoned = 8,
  Deleted = 9
}

export interface QueueInfo {
  used: number;
  of: number;
  waiting: number;
  reservedOut: number;
  reservedIn: number;
  in: string;
  done: number;
  ready: number;
}

export interface ThrottlerRequest {
  key: string;
  owner: string;
  app: string;
  instance: string;
  requestedSlots: number;
  minReadySlots?: number;
  priority?: number;
}
