import { eventBus } from "global/event-bus";
import { AIComm } from "./AIComm";
import { Comm } from "./Comm";
import { ChatSettingsInferenceTemplate, Model, SendMessageData, TextReply } from "./Model";
import { ZService } from "./ZSettingsfn";

export class HuggingFaceComm extends AIComm {
  constructor(name: string, urlBase: string, model: string, key: string) {
    super(name, urlBase, model, key);
    this.canHandleSpecialAttachments = true;
  }

  async getPublicModels(params: any = {}): Promise<any[]> {
    const url = new URL('https://huggingface.co/api/models');
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  
    if (!response.ok) {
      throw new Error(`Failed to fetch models: ${response.statusText}`);
    }
  
    const data = await response.json();
    return this.convertData(data);
  }
  
  convertData(inputData: InputModel[]): Model[] {
    return inputData.map((model: InputModel) => {
      const name = model.id.split("/")[1].replace(/-/g, " ");
      const createdDate = new Date(model.createdAt).toLocaleDateString();
  
      const description = `${name.charAt(0).toUpperCase() + name.slice(1)} is a language model by Hugging Face. It uses ${
        model.library_name
      }, and fits into ${model.pipeline_tag.replace(/-/g, " ")} tasks. It also supports ${
        model.tags.join(", ")
      }. This model was created on ${createdDate} and operates in the ${model.tags.includes("region:us") ? "US" : "unknown"} region.`;

      const modelOut = new Model();      
  
      modelOut.id = model.id;
      modelOut.name = name.charAt(0).toUpperCase() + name.slice(1);
      modelOut.description = description;
      modelOut.pricing = {
        prompt: "0.0000002",
        completion: "0.0000002",
        image: "0",
        request: "0",
      };
      modelOut.contextLength = model.id.includes("falcon") ? 8192 : 4096; // Example heuristic
      modelOut.architecture = {
        modality: "text",
        tokenizer: "Transformers",
        instruct_type: model.tags.includes("gpt2") ? "gpt2" : "custom_code",
      };
      modelOut.topProvider = {
        max_completion_tokens: null,
        is_moderated: false,
      };
      modelOut.perRequestLimits = {
        prompt_tokens: "49692071",
        completion_tokens: "49692071",
      };
      
      return modelOut;
    });
  };  

  async getModelsFresh(): Promise<any[]> {
    //if (!this.apiKey) {
        try {
            const params = {
                search: 'gpt',
                author: 'huggingface',
                limit: '10',
                sort: 'downloads',
                direction: '-1'
              };
            const pModels = await this.getPublicModels(params);
            return pModels;
        }
        catch (ex) {
            console.log(ex);
            return [];
        }
 //   }

    // const url = this.apiURL + '/api/models';
    // const auth = await this.getAuthHeader();
    // const response = await fetch(url, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     [auth.key]: auth.value
    //   }
    // });

    // const data = await response.json();
    // return data;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    const json = await Comm.getAllModelsJson();
    this.keyUrl = json.openrouter.keyUrl;
//    const models = Model.loadModels(json.openrouter, this);
    const models = [];

    const huggingFaceModels = {data: await this.getModelsFresh()}; //await UrlCache.getSet('huggingface-models', async () => await this.getModelsFresh(), 60);
    if (huggingFaceModels && huggingFaceModels.data && huggingFaceModels.data.length > 0) {
      huggingFaceModels.data.map(item => {
        item.architecture = { modality: 'text', tokenizer: 'gpt4', instruct_type: 'instruct' };
        item.comm = this;
        item.serviceUid = service.uid;
        models.push(item);
      });
    }

    console.log('huggingface models:' + models.length);
    this.isConnected = true;

    // default
    if (models.filter(m => m.isSelected).length === 0) {
      const gpt4o = models.find(m => m.id.indexOf('gpt4o') > -1);
      if (gpt4o) {
        gpt4o.isSelected = true;
      }
    }

    return models;
  }

  async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {
    const key = 'Authorization';
    const value = `Bearer ${this.apiKey}`;
    return { key, value };
  }

  getURLwithSuffix(url, model) {
    // if url has / on end, remove it
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    if (model.id.indexOf('tts') > -1) {
      return url + '/audio/speech';
    }

    if (model.id.indexOf('whisper') > -1) {
      return url + '/audio/transcriptions';
    }

    return url + '/models/' + model.id;
  }

  fixModelId(id) {
    if (this.name === 'huggingface') {
      return id.replace('huggingface/', '');
    }

    return id;
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    const data = {
      model: this.fixModelId(model.id),
      stream: false,
      messages: [
        { role: 'system', content: instruction }
      ],
    };

    const maxTokens = infTemp.inference?.max_tokens ?? 0;
    if (maxTokens > 0) {
      data['max_tokens'] = maxTokens;
    }

    const url = this.getURLwithSuffix(this.apiURL, model);
    const auth = await this.getAuthHeader(model);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value,
      },
      body: JSON.stringify(data)
    });

    const text = await response.text();
    return text;
  }

  async callTTS(text: string, key: string, voiceModel: Model, voice: string = 'alloy'): Promise<string | void> {
    const url = this.getURLwithSuffix(this.apiURL, voiceModel);
    const auth = await this.getAuthHeader(voiceModel);

    let body = JSON.stringify({
      "model": voiceModel.id,
      "input": text,
      "voice": voice
    });

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: body
    })
      .then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        return url;
      })
      .catch(error => console.log('Error:', error));
  }

  async callListen(blob: Blob, listenModel: Model, convId: string, mode: string) {
    const url = this.getURLwithSuffix(this.apiURL, listenModel);
    const auth = await this.getAuthHeader(listenModel);

    const file = new File([blob], 'recording.ogg', { type: 'audio/ogg; codecs=opus' });
    const data = new FormData();
    data.append('model', 'whisper-1');
    data.append('file', file);

    return await fetch(url, {
      method: 'POST',
      headers: {
        [auth.key]: auth.value
      },
      body: data as any
    })
      .then(response => response.json())
      .then(responseData => {
        eventBus.emit('gotSpeechText', { text: responseData.text, isError: false, convId: convId, mode: mode });
      })
      .catch(error => {
        console.log(error);
        eventBus.emit('gotSpeechText', { text: '', isError: true, error: error, convId: convId, mode: mode });
      });
  }

  parseTextReply(text: string, model: Model, ms = 0): TextReply {
    const data = JSON.parse(text);
    if (data.choices[0]?.delta?.content) {
      return { text: data.choices[0].delta.content, isDone: false, ms: ms };
    }
    if (data.choices[0]?.message?.content) {
      return { text: data.choices[0].message.content, isDone: false, ms: ms };
    }

    return null;
  }

  readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result as string;
        const base64String = btoa(binaryString);
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  }

  async call(callData: SendMessageData): Promise<string> {
    const instruction = callData.preInstructions;
    const msgs = callData.msgs;
    const infTemp = callData.infTemp;
    const inferenceParams = infTemp?.inference;
    const temperature = inferenceParams?.temp ?? 1.0;
    let maxTokens = inferenceParams?.max_tokens ?? 50;  // Set a sensible default value
    const update = callData.update;
    const model = callData.model;
    const chatId = callData.chatId;
  
    if (maxTokens === 0) {
        maxTokens = 1000;
    }

    // Combine all messages into a single input text
    let combinedInput = msgs.map(m => m.message).join('\n');
  
    // Prefix instruction if provided
    if (instruction) {
      combinedInput = `${instruction}\n${combinedInput}`;
    }
  
    const data = {
      model: this.fixModelId(model.id),
      inputs: combinedInput,
      parameters: {
        temperature: temperature,
        max_new_tokens: maxTokens
      },
      options: {
        use_cache: true,
        wait_for_model: true,
        stream: true
      }
    };
  
    const url = this.getURLwithSuffix(this.apiURL, model);
    const auth = await this.getAuthHeader(model);
    const startTime = new Date().getTime();

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: JSON.stringify(data)
    });

    if (!response.ok) {
        throw new Error(response.status + ':' + response.text());
    }
      
    await this.parseAndSend(response, update, model, startTime);
  
    return "";
  }  

  async parseAndSend(response, update, model, startTime = 0) {
    let doContinue = true;
  
    if (response.body) {
      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();
      let buffer = '';
  
      while (doContinue) {
        const { done, value } = await reader.read();
  
        if (done) {
          console.log('DONE: ' + value);
          break;
        }
  
        const textChunk = textDecoder.decode(value, { stream: true });
        buffer += textChunk;
  
        // Process each chunk manually
        try {
          // Split buffer by newlines to handle multiple JSON objects in one chunk
          const lines = buffer.split('\n');
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            if (line) {
              const eventData = JSON.parse(line);
              const endTime = new Date().getTime();
              const ms = endTime - startTime;
  
              if (eventData && eventData.length>0 && eventData[0].generated_text) {
                const reply = { text: eventData[0].generated_text, isDone: false, ms: ms };
                doContinue = update(reply, model);
              }
  
              if (line === '[DONE]') {
                doContinue = update({ isDone: true, ms: ms }, model);
                break;
              }
            }
          }
  
          // Keep the last line in the buffer because it might be incomplete
          buffer = lines[lines.length - 1];
        } catch (e) {
          console.error('Parsing error:', e);
          const textReply = new TextReply();
          textReply.text = buffer;
          textReply.isDone = false;
          textReply.isError = true;
          update(textReply, model);
        }
      }
  
      if (!doContinue) {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;
        reader.cancel();
        update({ isDone: true, ms: ms }, model);
      }
    }
  }
  
}

export class InferenceParams {
    max_tokens?: number;
    temperature?: number;
    top_p?: number;
    top_k?: number;
  
    constructor(max_tokens?: number, temperature?: number, top_p?: number, top_k?: number) {
      this.max_tokens = max_tokens;
      this.temperature = temperature;
      this.top_p = top_p;
      this.top_k = top_k;
    }
  }
  
  
  type InputModel = {
    _id: string;
    id: string;
    likes: number;
    private: boolean;
    downloads: number;
    tags: string[];
    pipeline_tag: string;
    library_name: string;
    createdAt: string;
    modelId: string;
  };
  
  type OutputModel = {
    id: string;
    name: string;
    description: string;
    pricing: {
      prompt: string;
      completion: string;
      image: string;
      request: string;
    };
    context_length: number;
    architecture: {
      modality: string;
      tokenizer: string;
      instruct_type: string;
    };
    top_provider: {
      max_completion_tokens: number | null;
      is_moderated: boolean;
    };
    per_request_limits: {
      prompt_tokens: string;
      completion_tokens: string;
    };
  };