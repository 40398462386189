import { AIComm } from "./AIComm";
import { ChatSettingsInferenceTemplate, Message, Model, ModelSets, TextReply } from "./Model";
import { ZService } from "./ZSettingsfn";
import { Comm } from "./Comm";
import { XAuthLocal } from "global/auth/xauth";

export class CoPilotComm extends AIComm {
  isJson: boolean;
  token = '';

  constructor(name, urlBase, model, key, isJson = true) {
    super(name, urlBase, model, key);
    this.isJson = isJson;
  }

  async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {

    var apiKey = this.apiKey;
    if (model) {
      var service = ModelSets.getService(model);
      if (!service.authInfo) {
        service = ModelSets.getServiceForCommName('copilot');
      }
      const auth = ModelSets.getAuthFor(service);
      const key = 'token';
      const value = await auth.getToken();
      //auth.token = value;
      this.token = value;
      return { key, value };
    }

    const key = 'api-key';
    const value = `${apiKey}`;
    return { key, value };
  }

  getURLwithSuffix(url, model) {
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    url = 'ws://localhost:8080?access_token=' + this.token;

    return url;
  }

  getValueByPath(data, path) {
    let result = data;
    for (const key of path) {
      if (result != null && key in result) {
        result = result[key];
      } else {
        return undefined; // Path does not exist
      }
    }
    return result;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled || !ModelSets.instance) {
      return service ? service.models : [];
    }

    var auth = new XAuthLocal('copilot');
    ModelSets.instance.tempStorage.auths['copilot'] = auth;

    this.wasCalled = true;
    const json = await Comm.getAllModelsJson();
    if (!json || !json.copilot) {
      return [];
    }
    this.keyUrl = json.copilot.keyUrl;
    service.useLogin = json.copilot.useLogin ?? false;
    service.authInfo = json.copilot.authInfo;
    const models = Model.loadModelsFlat(json.copilot, this, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    // this.addInServiceModels(models, service);
    this.isConnected = true;
    return models;
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    const data = {
      model: model.id,
      messages: [
        { role: 'system', content: instruction }
      ],
      stream: false
    };

    const maxTokens = infTemp.inference?.max_tokens ?? 0;
    if (maxTokens > 0) {
      data['max_tokens'] = maxTokens;
    }

    const url = this.getURLwithSuffix(this.apiURL, model.id);
    const auth = await this.getAuthHeader(model);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: JSON.stringify(data)
    });

    const text = await response.text();
    return text;
  }

  //Upcall:01
  async callX(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
    const auth = await this.getAuthHeader(model);
    const newMessages = msgs.map(m => {
      return {
        role: m.type,
        type: 'text',
        text: m.message
      };
    });

    if (instruction) {
      newMessages.unshift({ role: "system", type: 'text', text: instruction });
    }

    const data = {
      event: "send",
      conversationId: "TTD72P1VJmFiGsCLf1d9fU",
      content: newMessages
    };

    const url = this.getURLwithSuffix(this.apiURL, model.id);
    let ws;
    try {
      ws = new WebSocket(url);
    } catch (error) {
      console.error("WebSocket creation error: ", error);
      return "";
    }

    const openPromise = new Promise<void>((resolve, reject) => {
      ws.onopen = () => {
        console.log("Connected to WebSocket");
        resolve();
      };

      ws.onerror = (error) => {
        console.error("WebSocket error: ", error);
        reject(error);
      };
    });

    ws.onmessage = async (event) => {
      if (typeof event.data === "string") {
        const json = JSON.parse(event.data);
        console.log("Received response: ", json);
        if (!(await this.parseAndSend2(json, update, model))) {
          ws.close();
        }
      } else {
        const reader = new FileReader();
        reader.onload = async () => {
          const text = reader.result;
          if (typeof text === "string") {
            const json = JSON.parse(text);
            console.log("Received response: ", json);
            if (!(await this.parseAndSend2(json, update, model))) {
              ws.close();
            }
          } else {
            console.error("Unexpected data type: ", typeof text);
          }
        };
        reader.readAsText(event.data);
      }
    };

    const closePromise = new Promise<void>((resolve, reject) => {
      ws.onclose = (event) => {
        if (event.wasClean) {
          console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
          resolve();
        } else {
          console.error("Connection closed unexpectedly", event);
          reject(event);
        }
      };
    });

    ws.addEventListener("error", (event) => {
      console.error("WebSocket error event: ", event);
    });

    try {
      await openPromise;
      ws.send(JSON.stringify(data)); // Send data only after the connection is open
      console.log("Sent data");
      await closePromise;
    } catch (error) {
      console.error("Failed to open WebSocket connection: ", error);
    }
    return "";
  }

  async parseAndSend2(response, update, model, startTime = 0): Promise<boolean> {
    var doContinue = true;

    const parser = (response) => {
      if (response.event) {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;

        if (response.event === 'appendText') {
          const textChunk = response.text;

          const reply = this.parseTextReply(textChunk, model);
          if (reply) {
            // console.log(reply);
            doContinue = update(reply, model);
          }
        }
        else if (response.event === 'done') {
          doContinue = update({ isDone: true, ms: ms }, model);
          doContinue = false;
        } else if (response.event === 'startMessage') {
          // do nothing
        }
      }
    };

    if (response) {
      parser(response);

      if (!doContinue) {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;
        update({ isDone: true, ms: ms }, model)
      }
    }

    return doContinue;
  }

  parseTextReply(text: string, model: Model): TextReply {
    try {
      if (this.proxyURL && model.replyPath) {
        return { text: text, isDone: false };
      }

      return { text: text, isDone: false };
    }
    catch (e) {
      console.error('Error parsing response: ' + e);

      if (text.startsWith('{')) {
        // assume multiple... split by \n
        const lines = text.split('\n');
        let content = '';

        for (const line of lines) {
          const data = JSON.parse(line);
          if (this.proxyURL && model.replyPath) {
            content += this.getValueByPath(data, JSON.parse(model.replyPath));
          }
          else {
            content += data.choices[0].delta.content;
          }
        }

        const data1 = JSON.parse(lines[0]);
        data1.choices[0].delta.content = content;
        return data1;
      }

      return { text: '', isDone: false };
    }
  }

}
