import { ChatDuo, DataMgr } from "global/data-mgr/data-mgr";
import { eventBus } from "global/event-bus";
import { fetchWithToken } from "global/utils";
import { Mode, ReservationResult, Status, ThrottlerRequest, XMgr } from "global/x-mgr/x-mgr";

import FormData from 'form-data';
//import { AppChat } from "components/z1/app-chat/app-chat";
import { XAuth } from "global/auth/xauth";

export class XService {
  static id: string = `obj_${Math.random().toString(36).substr(2, 9)}`;

  static openAIModels = ['gpt-4o', 'gpt-4-turbo-preview', 'gpt-4', 'gpt-3.5-turbo-1106', 'gpt-4-1106-preview', 'gpt-4-32k', 'gpt-3.5-turbo'];
  static azureAIModels = ['gpt-4', 'gpt-35-turbo', 'gpt-4-32k'];
  static openAIvoices = ['Alloy', 'Echo', 'Fable', 'Onyx', 'Nova', 'Shimmer'];

  static getMyModels() {
    return this.getService() === 'azureopenai' ? this.azureAIModels : this.openAIModels;
  }

  static get35ModelName() {
    return this.getService() === 'azureopenai' ? 'GPT-3.5' : 'gpt-3.5-turbo-1106';
  }

  static get4ModelName() {
    return this.getService() === 'azureopenai' ? 'GPT-4o' : 'gpt-4-turbo-preview';
  }

  static getModelInfo(model: string) {
    const is4 = model.indexOf('-4') > -1;
    const isLarge = model.indexOf('32k') > -1 || model.indexOf('4-turbo') > -1 || model.indexOf('-1106') > -1;
    return { is4, isLarge };
  }

  static getVoice() {
    return localStorage.getItem('openai-voice') ?? 'Nova';
  }

  static setVoice(voice: string) {
    localStorage.setItem('openai-voice', voice);
  }

  static urlBaseBase: string = "https://api.openai.com/v1/";
  static urlBase: string = "https://api.openai.com/v1/completions";
  static urlChatBase: string = "https://api.openai.com/v1/chat/completions";
  static urlEmbeddingsBase: string = 'https://api.openai.com/v1/embeddings';

  // urlAzureBaseBase: string = "https://api.openai.com/v1/";
  static urlAzureBase: string = `https://{resourceName}.openai.azure.com/openai/deployments/{deployment}/completions?api-version=2023-07-01-preview`;
  static urlAzureChatBase: string = `https://{resourceName}.openai.azure.com/openai/deployments/{deployment}/chat/completions?api-version=2023-07-01-preview`;
  static urlAzureModels = `https://{resourceName}.openai.azure.com/openai/models?api-version=2023-07-01-preview`;

  static modes: Mode[] = null;
  static openAIModes: Mode[] = [];
  static azureOpenAIModes: Mode[] = [];

  static throttleReceipt;
  static throttleStatus;
  static azConfig;
  static stopStreaming;

  static getService() {
    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const azureOpenAiKey = localStorage.getItem('azureapikey') ?? '';
    const canSwitchServices = openAiKey.length > 0 && azureOpenAiKey.length > 0;
    return canSwitchServices ?
      (localStorage.getItem('service') !== 'azure' ? 'openai' : 'azureopenai') :
      (azureOpenAiKey.length > 0 ? 'azureopenai' : 'openai');
  }

  static isOpenAI() {
    return XService.getService() === 'openai';
  }

  static canHearServiceExists() {
    const openAiKey = localStorage.getItem('openai-key') ?? '';
    return openAiKey.length > 0;
  }

  static getCurrent() {
    const service = XService.getService();
    const result = new XServiceInfo();
    result.service = service;
    result.key = service === 'openai' ? localStorage.getItem('openai-key') : localStorage.getItem('azureapikey');

    const azureresourcename = localStorage.getItem('azureresourcename') ?? '';

    let urlRoot = '';

    const azureUrl = XService.urlAzureChatBase.replace('{resourceName}', azureresourcename); //.replace('{deployment}', model);
    const openUrl = XService.urlChatBase;
    result.url = service === 'openai' ? openUrl : azureUrl;

    const settings = JSON.parse(localStorage.getItem('chat-settings') ?? '{}');

    const elasticUrl = service === 'openai' ? localStorage.getItem('openaielasticurl') : localStorage.getItem('azureelasticurl');
    const elasticKey = service === 'openai' ? localStorage.getItem('openaielastickey') : localStorage.getItem('azureelastickey');

    const data = {
      model: '',
      //messages: messages,
      temperature: settings.temperature ?? 0.7,
      top_p: settings.topp ?? 0.95,
      frequency_penalty: settings.frequencyPenalty ?? 0.0,
      presence_penalty: settings.presencePenalty ?? 0.0,
      max_tokens: settings.response ?? 800,
      stop: !settings.stopSequences || settings.stopSequences == null || settings.stopSequences.length === 0 ? null : settings.stopSequences
      //stream: stream
    };

    result.data = data;
    result.elastic = { url: elasticUrl, key: elasticKey };
    result.getUrl = (modelType: XServiceType) => XService.getUrl(modelType, result);

    return result;
  }

  public static getModel(modelType: XServiceType, data: XServiceInfo): string {
    switch (modelType) {
      case XServiceType.EmbeddingsAda002:
        var mode = this.modes[0].models.filter(m => m.name.indexOf('ada') > -1 && m.name.indexOf('embedding') > -1 && m.name.indexOf('002') > -1);
        if (mode.length > 0) {
          return mode[0].name;
        }
    }

    return null;
  }

  public static getUrl(modelType: XServiceType, data: XServiceInfo): string {
    // const model = this.getModel(modelType, data);

    switch (modelType) {
      case XServiceType.EmbeddingsAda002:
        return XService.urlEmbeddingsBase;
    }

    return '';
  }

  // --- //

  static async loadModesOpenAI(openAiKey: string) {
    const url = "https://api.openai.com/v1/models";
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${openAiKey}`
      }
    };
    const response = await fetch(url, options);
    if (response.ok) {
      try {
        const responseJson = (await response.json());

        const models = responseJson.data.filter(m => m.object === 'model').map((item) => ({
          name: item.id
        }));

        this.openAIModes = [];

        this.addSet(this.openAIModes, 'Complete', models.filter(m => m.name.indexOf('insert') === -1 && m.name.indexOf('edit') === -1));
        this.addSet(this.openAIModes, 'Chat', models.filter(m => m.name.indexOf('gpt') > -1));
        this.addSet(this.openAIModes, 'Insert', models.filter(m => m.name.indexOf('insert') > -1));
        this.addSet(this.openAIModes, 'Edit', models.filter(m => m.name.indexOf('edit') > -1));
      } catch (ex) {
        console.error(ex);
      }
    }
  }

  static addSet(set, type: string, models) {
    if (models.length > 0) {
      set.push(
        {
          name: type,
          models: models
        }
      );
    }
  }

  static async loadModes(tempXmgr) {
    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const azureOpenAiKey = localStorage.getItem('azureapikey') ?? '';
    const res = localStorage.getItem('azureresourcename');

    if (openAiKey && openAiKey.length > 0 && this.openAIModes.length === 0) {
      await this.loadModesOpenAI(openAiKey);
    }

    if (azureOpenAiKey && azureOpenAiKey.length > 0 && res && res.length > 0 && this.azureOpenAIModes.length === 0) {
      await this.loadModesAzureOpenAI(azureOpenAiKey, res, tempXmgr);
    }

    this.switchModes();
    return XService.modes;
  }

  static async loadModesAzureOpenAI(azureOpenAIKey: string, resourceName: string, tempXmgr) {
    if (azureOpenAIKey === '(throttled)' && tempXmgr && !tempXmgr.azConfig) {
      await XService.getAzureConfig();
      tempXmgr.azConfig = XService.azConfig;
    }
    // await XService.throttle('models', async (key) => await this._loadModesAzureOpenAI(key, resourceName), false, () => { }, azureOpenAIKey, 'medium', tempXmgr);
  }

  static async _loadModesAzureOpenAI(azureOpenAIKey: string, resourceName: string) {

    if (this.azureOpenAIModes && this.azureOpenAIModes.length > 0) {
      return;
    }

    const url = XService.urlAzureModels.replace('{resourceName}', resourceName); // `https://${resourceName}.openai.azure.com/openai/deployments?api-version=2022-06-01-preview`;
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-key': `${azureOpenAIKey}`
      }
    };
    console.log('AzureOpenAI models:LOADING');
    const response = await fetch(url, options);
    console.log('AzureOpenAI models:LOADED');
    if (response.ok) {
      console.log('AzureOpenAI models:LOADED-OK');
      try {
        const responseJson = (await response.json());

        const models = responseJson.data.filter(m => m.object === 'model').map((item) => ({
          name: item.id,
          model: item.model
        }));

        //debugger;

        if (models.length === 0) {
          // fake fill while azure bug is being fixed
          models.push({ name: 'gpt-4o', object: 'model' });
          models.push({ name: 'gpt-35-turbo', object: 'model' });
          models.push({ name: 'gpt-4-32k', object: 'model' });
        }

        this.azureOpenAIModes = [];

        this.addSet(this.azureOpenAIModes, 'Complete', models.filter(m => m.name.indexOf('insert') === -1 && m.name.indexOf('edit') === -1));
        this.addSet(this.azureOpenAIModes, 'Chat', models.filter(m => m.name.indexOf('gpt') > -1));
        this.addSet(this.azureOpenAIModes, 'Insert', models.filter(m => m.name.indexOf('insert') > -1));
        this.addSet(this.azureOpenAIModes, 'Edit', models.filter(m => m.name.indexOf('edit') > -1));

      } catch (ex) {
        console.error(ex);
      }
    }
  }

  public static isAzureOpenAI(): boolean {
    return XService.getService() === 'azureopenai';
  }

  private static loadErrorCount = 0;

  public static async getGPTModelxxx(isGPT4: boolean, isGPT32k: boolean): Promise<string> {

    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const azureOpenAiKey = localStorage.getItem('azureapikey') ?? '';
    const canSwitchServices = openAiKey.length > 0 && azureOpenAiKey.length > 0;

    const serviceSelected = canSwitchServices ?
      (localStorage.getItem('service') !== 'azure' ? 'openai' : 'azureopenai') :
      (azureOpenAiKey.length > 0 ? 'azureopenai' : 'openai');

    let chatModels;
    if (serviceSelected === 'azureopenai') {
      chatModels = XService.azureOpenAIModes;
    } else {
      chatModels = XService.openAIModes;
    }

    // multiple static class fix:
    if (!chatModels || chatModels.length === 0 && this.loadErrorCount < 4) {
      ++this.loadErrorCount;
      await XService.loadModes(XMgr.instance);
      if (serviceSelected === 'azureopenai') {
        chatModels = XService.azureOpenAIModes;
      } else {
        chatModels = XService.openAIModes;
      }
    }

    const cModes = chatModels.filter(c => c.name === 'Chat');
    if (!cModes || cModes.length === 0) {
      return null;
    }

    chatModels = cModes[0].models;

    if (!chatModels) {
      return null;
    }

    if (!isGPT4) {
      let models3 = chatModels.filter(c => (c.name === 'gpt-3' || c.name === 'gpt3' || (c.name.indexOf('gpt') > -1 && c.name.indexOf('3') > -1)) && (c.name.indexOf('gpt4') === -1 && c.name.indexOf('gpt-4o') === -1))
        .sort();

      let models3june = models3.filter(c => c.name.indexOf('0613') > -1 && c.name.indexOf('16k') === -1);

      if (models3june.length > 0) {
        return models3june[0].name;
      }

      let models = chatModels.filter(c => c.name === 'gpt-3');
      if (models.length > 0) {
        return models[0].name;
      }
      models = chatModels.filter(c => c.name === 'gpt3');
      if (models.length > 0) {
        return models[0].name;
      }
      models = chatModels.filter(c => c.name.indexOf('gpt') > -1 && c.name.indexOf('3') > -1);
      if (models.length > 0) {
        return models[0].name;
      }

      return null;
    }

    if (isGPT32k) {
      let models4 = chatModels.filter(c => ((c.name.indexOf('gpt4') > -1 || c.name.indexOf('gpt-4') > -1) && c.name.indexOf('32k') > -1) && c.name.indexOf('0613') > -1);

      let models = chatModels.filter(c => c.name === 'gpt-4-32k');
      if (models.length > 0) {
        return models[0].name;
      }
      models = chatModels.filter(c => c.name.indexOf('gpt') > -1 && c.name.indexOf('32') > -1);
      if (models.length > 0) {
        return models[0].name;
      }

      return null;
    }

    let models4 = chatModels.filter(c => ((c.name.indexOf('gpt4') > -1 || c.name.indexOf('gpt-4') > -1) && c.name.indexOf('32k') === -1) && c.name.indexOf('0613') > -1);
    if (models4.length > 0) {
      return models4[0].name;
    }

    let models = chatModels.filter(c => c.name === 'gpt-4o');
    if (models.length > 0) {
      return models[0].name;
    }
    models = chatModels.filter(c => c.name.indexOf('gpt') > -1 && c.name.indexOf('4') > -1);
    if (models.length > 0) {
      return models[0].name;
    }

    return null;
  }

  public static async switchModes(): Promise<string> {
    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const azureOpenAiKey = localStorage.getItem('azureapikey') ?? '';
    const canSwitchServices = openAiKey.length > 0 && azureOpenAiKey.length > 0;

    const serviceSelected = canSwitchServices ?
      (localStorage.getItem('service') !== 'azure' ? 'openai' : 'azureopenai') :
      (azureOpenAiKey.length > 0 ? 'azureopenai' : 'openai');

    if (serviceSelected === 'azureopenai') {
      XService.modes = XService.azureOpenAIModes;
    } else {
      XService.modes = XService.openAIModes;
    }

    console.log('emitting modes-loaded');
    eventBus.emit('modes-loaded');
    // await AppChat.instance.checkModesPlus();
    return serviceSelected;
  }

  static async getAzureConfig(): Promise<boolean> {
    return false;
    // ZService.debug(null, 'getAzureConfig');

    // const azureConfigUrl = localStorage.getItem('azureconfigurl') ?? '';
    // if (azureConfigUrl.length > 0) {
    //   try {
    //     let url = azureConfigUrl;
    //     let root = '';
    //     if (url.toLocaleLowerCase().indexOf('http') === -1) {
    //       root = 'https://' + url + '.azurewebsites.net';
    //       url = root + '/api/public?app=crosscodex';
    //       // root = 'https://localhost:7129';
    //       // url = 'https://localhost:7129/api/public?app=crosscodex';
    //     }
    //     const configData = await XMgr.loadFile(url, false);

    //     if (configData.throttleUrl.indexOf('http') === -1) {
    //       configData.throttleUrl = root + configData.throttleUrl;
    //     }

    //     XService.azConfig = configData;
    //     localStorage.setItem('azureapikey', '(throttled)');
    //     localStorage.setItem('azureresourcename', configData.resourceName);
    //     return true;
    //   }
    //   catch (ex) {
    //     console.error(ex);
    //   }
    // }

    // return false;
  }

  static async getTitleLine(prompt: string, key: string) {
    const model = this.get35ModelName();
    const inst = 'Summarize the following text/question/data into a short title (max 50 characters)';
    // const title = ask()
  }

  static async ask(from: string, key: string, stop: string[], barelyTrackedData, mode, source, sourceMsg, sysMsg) {
    let isChat = false;
    let model = mode ? mode.model : 'text-davinci-003';
    let base = XService.urlBase;
    let data = {};

    if (mode && mode.name === 'Chat') {
      isChat = true;
      base = XService.urlChatBase;
      const messages = [];
      if (sysMsg && sysMsg.length > 0) {
        messages.push({ role: 'system', content: sysMsg })
      }

      messages.push({ role: 'user', content: from });

      data = {
        model: model,
        messages: messages,
        temperature: 0.8,
      };
    }
    else if (mode && mode.name === 'Edit') {
      base = XService.urlBaseBase + 'edits';
      data = {
        model: model,
        input: source,
        instruction: sourceMsg
      }
    }
    else if (mode && mode.name === 'Insert') {
      base = XService.urlBaseBase + 'edits';
      data = {
        model: model,
        input: source,
        instruction: sourceMsg
      }
    }
    else {
      const settings = JSON.parse(localStorage.getItem('chat-settings') ?? '{}');

      data = {
        model: model,
        prompt: from,
        temperature: settings.temperature ?? 0.7,
        top_p: settings.topp ?? 0.95,
        frequency_penalty: settings.frequencyPenalty ?? 0.0,
        presence_penalty: settings.presencePenalty ?? 0.0,
        max_tokens: settings.response ?? 800,
        stop: !settings.stopSequences || settings.stopSequences == null || settings.stopSequences.length === 0 ? null : settings.stopSequences,
      };
    }

    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const azureOpenAiKey = localStorage.getItem('azureapikey') ?? '';
    const azureresourcename = localStorage.getItem('azureresourcename') ?? '';

    const canSwitchServices = openAiKey.length > 0 && azureOpenAiKey.length > 0;

    const serviceSelected = canSwitchServices ?
      (localStorage.getItem('service') !== 'azure' ? 'openai' : 'azureopenai') :
      (azureOpenAiKey.length > 0 ? 'azureopenai' : 'openai');

    let options;
    let url;
    if (serviceSelected === 'azureopenai') {
      url = XService.urlAzureBase.replace('{resourceName}', azureresourcename).replace('{deployment}', model);
      const API_KEY = key ?? azureOpenAiKey;
      options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': `${API_KEY}`
        },
        body: JSON.stringify(
          data
        )
      };
    } else {
      url = base;
      const API_KEY = openAiKey;
      options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${API_KEY}`
        },
        body: JSON.stringify(
          data
        )
      };
    }

    const start = performance.now();
    let end = 0;
    const response = await fetch(url, options);
    if (response.ok) {
      const json = await response.json();
      end = performance.now();
      DataMgr.doEvent('x-update', { response: json, isError: false });
    }
    else {
      const json = await response.json();
      end = performance.now();
      DataMgr.doEvent('x-update', { response: json, isError: true });
    }

    barelyTrackedData.timingMS = end - start;
    barelyTrackedData.responseStatus = response.status;

    window.appInsights.trackEvent(barelyTrackedData);
  }

  static isThrottled(): boolean {
    if (!XMgr.instance) {
      return false;
    }
    const azConfig = XMgr.instance.azConfig;
    return azConfig && azConfig.throttleUrl && azConfig.throttleUrl.length > 0;
  }

  public static async checkQueue(key: string): Promise<ReservationResult> {
    const azConfig = XMgr.instance.azConfig;
    if (azConfig && azConfig.throttleUrl && azConfig.throttleUrl.length > 0) {
      {
        try {
          console.log('th:check');
          const receipt: ReservationResult = {
            key: key,
            id: 0,
            status: Status.None,
            slots: 0,
            allocatedSlots: 0,
            minimumWaitSeconds: 0,
            info: undefined,
            values: {}
          };

          const abortController = new AbortController();
          const signal = abortController.signal;

          const response = await fetchWithToken(azConfig.throttleUrl + '/check', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(receipt),
            signal,
          });
          return await response.json();
        } catch (error) {
          console.log('th:check...error');
          if (error.name === 'AbortError') {
            console.log('Check and use aborted');
          } else {
            throw error;
          }
        }

      }
    }

    return null;
  }


  static async throttle(key: string,
    callback: (key: string) => Promise<any>,
    useSameReceipt: boolean = false,
    cancelCallback: () => void,
    defaultKey: string = '',
    pri: string = 'medium',
    tempXmgr: any = null): Promise<{ result: Promise<string>, cancel: () => void }> {

    tempXmgr = tempXmgr ?? XMgr.instance;

    if (!tempXmgr) {
      new XMgr(false);
      await XMgr.instance.loadSettings();
      tempXmgr = XMgr.instance;
    }

    if (!tempXmgr) {
      return;
    }

    const azConfig = tempXmgr.azConfig;
    if (azConfig && azConfig.throttleUrl && azConfig.throttleUrl.length > 0) {
      // Create an AbortController instance for cancellation
      const abortController = new AbortController();
      const signal = abortController.signal;

      // Function to request slots from the server
      const requestSlots = async () => {
        try {
          const iPri = pri === 'high' ? 4 : pri === 'low' ? 6 : 5;

          const request: ThrottlerRequest = {
            key: key,
            owner: await XAuth.getMyUserName(),
            app: 'crosscodex',
            instance: '1',
            requestedSlots: 1,
            priority: iPri
          };
          console.log('th:requesting');
          const response = await fetchWithToken(azConfig.throttleUrl + '/request', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
            signal,
          });
          return await response.json();
        } catch (error) {
          console.log('th:requesting...error');
          if (error.name === 'AbortError') {
            console.log('Request slots aborted');
          } else {
            throw error;
          }
        }
      };

      // Function to release slots when canceling
      const releaseSlots = async (receipt: ReservationResult) => {
        try {
          console.log('th:release');
          await fetchWithToken(azConfig.throttleUrl + '/release', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(receipt),
            signal,
          });
        } catch (error) {
          console.log('th:release...error');
          if (error.name === 'AbortError') {
            console.log('Release slots aborted');
          } else {
            throw error;
          }
        }
      };

      // Function to check and use the granted slots
      const checkAndUse = async (receipt) => {
        try {
          console.log('th:check');
          const response = await fetchWithToken(azConfig.throttleUrl + '/checkanduse', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(receipt),
            signal,
          });
          const data = await response.json();
          return data;
        } catch (error) {
          console.log('th:check...error');
          if (error.name === 'AbortError') {
            console.log('Check and use aborted');
          } else {
            throw error;
          }
        }
      };

      // Wrap the waiting and checking process in a Promise
      const waitForGranted = new Promise<ReservationResult>(async (resolve, reject) => {
        const checkStatus = async (receipt) => {
          try {
            console.log('th:check');
            XService.throttleReceipt = receipt ? await checkAndUse(receipt) : await requestSlots();
            XService.throttleStatus = XService.throttleReceipt.status;
            if (XService.throttleReceipt.status === Status.Granted) {
              console.log('th:GRANTED');
              resolve(XService.throttleReceipt);
            } else if (this.throttleReceipt.status === Status.Awaiting) {
              console.log('th:awaiting ' + XService.throttleReceipt.minimumWaitSeconds + ' seconds');
              setTimeout(() => checkStatus(XService.throttleReceipt), XService.throttleReceipt.minimumWaitSeconds * 1000);
            } else {
              console.log('th:rejected=' + XService.throttleReceipt.status);
              reject(new Error('Failed to get granted status'));
            }
          } catch (error) {
            if (error.name === 'AbortError') {
              console.log('Checking status aborted');
              reject(error);
            } else {
              throw error;
            }
          }
        };

        checkStatus(useSameReceipt ? XService.throttleReceipt : null);
      });

      // Set up the cancellation function to release slots and abort requests
      const cancel = async () => {
        console.log('th:CANCELLING');
        await releaseSlots(XService.throttleReceipt);
        XService.throttleStatus = Status.Released;
        abortController.abort();
        cancelCallback();
      };

      // Wait for the granted status, then call the callback with the modified key
      try {
        const result = await waitForGranted;
        const modifiedKey = result.values['OpenAIKey'];
        console.log('th:callback!');
        return { result: await callback(modifiedKey), cancel };
      } catch (error) {
        console.log('th:error!');
        console.error('Throttling error:', error);
        throw error;
      } finally {
        console.log('th:final!');
        cancel();
      }
    }
    else {
      return { result: callback(defaultKey), cancel: () => { } };
    }
  }

  static async callChat(model: string, conversation: ChatDuo[], instruction: string, key: string, stream: boolean = true, localMsg: any = null): Promise<string> {
    let base = XService.urlChatBase;

    if (model.indexOf('gpt') === -1) {
      model = 'gpt-4o';
    }

    const messages = [];
    if (instruction && instruction.length > 0) {
      messages.push({ role: 'system', content: instruction })
    }

    conversation.map(c => {
      if (c.user && c.user.length > 0) {
        messages.push({ role: 'user', content: c._overrideUser ?? c.user });
      }
      if (c.bot && c.bot.length > 0 && !c.isError) {
        messages.push({ role: 'assistant', content: c.bot });
      }
      if (c.fnToSend && c.fnToSend.response && !c.fnToSend._isResponseSubmitted) {
        messages.push({ role: 'function', name: c.fnToSend.name, content: c.fnToSend.response });
        c.fnToSend._isResponseSubmitted = true;
      }
    });

    const settings = JSON.parse(localStorage.getItem('chat-settings') ?? '{}');

    const data = {
      model: model,
      messages: messages,
      temperature: settings.temperature ?? 0.7,
      top_p: settings.topp ?? 0.95,
      frequency_penalty: settings.frequencyPenalty ?? 0.0,
      presence_penalty: settings.presencePenalty ?? 0.0,
      // max_tokens: settings.response ?? 800,
      stop: !settings.stopSequences || settings.stopSequences == null || settings.stopSequences.length === 0 ? null : settings.stopSequences,
      stream: stream
    };

    const functions = XMgr.LoadFunctionCards();
    if (functions && functions.length > 0) {
      const activeFunctions = functions.filter(f => f.isActive);
      if (activeFunctions && activeFunctions.length > 0) {
        const fns = [];
        activeFunctions.filter(f => f.name && f.description).map(f => {
          const parms = { type: 'object', required: [] };
          const req = [];
          const props = {};
          f.parameters.filter(p => p && p.key.length > 0).map(p => {
            const key = p.key.replace(' ', '_');
            props[key] = { type: p.type, description: p.description };
            if (p.enumValues && p.enumValues.length > 0) {
              props[key].enum = p.enumValues.split(',');
            }
            parms[p.key] = p.value;
            if (p.isRequired) {
              req.push(key);
            }
          });

          parms['properties'] = props;
          parms['required'] = req;

          const fn = {
            name: f.name.replace(' ', '_'),
            description: f.description,
            parameters: parms
          }

          fns.push(fn);
        });

        if (fns && fns.length > 0) {
          data['functions'] = fns;
          const fnAction = localStorage.getItem('fnAction') ?? '';
          const fnCall = fnAction === 'none' ? 'none' : 'auto';
          data['function_call'] = fnCall;
        }

      }
    }

    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const azureOpenAiKey = key && key.length > 0 ? key : localStorage.getItem('azureapikey') ?? '';
    const azureresourcename = localStorage.getItem('azureresourcename') ?? '';

    const canSwitchServices = openAiKey.length > 0 && azureOpenAiKey.length > 0 && azureresourcename.length > 0;

    const serviceSelected = canSwitchServices ?
      (localStorage.getItem('service') !== 'azure' ? 'openai' : 'azureopenai') :
      (azureOpenAiKey.length > 0 ? 'azureopenai' : 'openai');

    let options;
    let url;
    let API_KEY = '';
    if (serviceSelected === 'azureopenai') {
      url = XService.urlAzureChatBase.replace('{resourceName}', azureresourcename).replace('{deployment}', model);
      API_KEY = azureOpenAiKey;
      options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/event-stream',
          'api-key': `${API_KEY}`
        },
        body: JSON.stringify(
          data
        )
      };
    } else {
      url = base;
      API_KEY = openAiKey;
      options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'text/event-stream',
          'Authorization': `Bearer ${API_KEY}`
        },
        body: JSON.stringify(
          data
        )
      };
    }

    const start = performance.now();
    let end = 0;

    // if (true) { // error testing
    //   const json = { error: { type: 'error', message: 'Testing error', code: 500 } };
    //   end = performance.now();
    //   DataMgr.doEvent('xchatupdate', { response: json, isError: true });
    //   eventBus.emit('gotMessage', { response: json, isError: true });
    //   return '';
    // }

    const doMsg = localMsg ? localMsg : (message) => {
      //if (message.type === 'data') {
      const json = message;
      const end = performance.now();
      if (localMsg == null) {
        DataMgr.doEvent('xchatupdate', { response: json, isError: false, isStreaming: json.isStreaming });
        eventBus.emit('gotMessage', { response: json, isError: false, isStreaming: json.isStreaming });
      }
      // } else if (message.type === 'error') {
      //   const json = message.data;
      //   const end = performance.now();
      //   DataMgr.doEvent('xchatupdate', { response: json, isError: true });
      //   eventBus.emit('gotMessage', { response: json, isError: true });
      // }
      if (json && json.choices && json.choices.length > 0 && json.choices[0].delta && json.choices[0].delta.content) {
        return (json.choices[0].delta.content);
      }
      else {
        return ('');
      }
    };

    //debugger;
    if (stream) {
      XService.stopStreaming = false;
      return new Promise((resolve) => {
        this.streamChatResponses(url, options, msg => resolve(doMsg(msg)), localMsg != null);
      });
    }
    else {
      const response = await fetch(url, options);
      if (response.ok) {
        const json = await response.json();
        end = performance.now();

        if (json.choices[0].message.content.indexOf('server_error: ') === 0 ||
          json.choices[0].message.content.indexOf('invalid_request_error: ') === 0) {
          json.error = { type: 'error', message: json.choices[0].message.content, code: 500 };
          if (localMsg == null) {
            DataMgr.doEvent('xchatupdate', { response: json, isError: true });
            eventBus.emit('gotMessage', { response: json, isError: true });
          }
          return '';
        }

        if (localMsg == null) {
          DataMgr.doEvent('xchatupdate', { response: json, isError: false });
          eventBus.emit('gotMessage', { response: json, isError: false });
        }
        return json.choices[0].message.content;
      }
      else {
        const json = await response.json();
        end = performance.now();
        if (localMsg == null) {
          DataMgr.doEvent('xchatupdate', { response: json, isError: true });
          eventBus.emit('gotMessage', { response: json, isError: true });
        }
        return '';
      }
    }

    //barelyTrackedData.timingMS = end - start;
    //barelyTrackedData.responseStatus = response.status;
    //window.ai.trackEvent(barelyTrackedData);
  }

  static stopStream() {
    this.stopStreaming = true;
  }

  private stopStreaming = false;

  static async streamChatResponses(url, options, onMessage, isLocalOnly: boolean = false) {
    const response = await fetch(url, options);
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let delta = '';

    if (response.status != 200) {
      let errorMessage = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        errorMessage += decoder.decode(value, { stream: true });
      }

      let errorResponse;
      try {
        errorResponse = JSON.parse(errorMessage);
      } catch (e) {
        errorResponse = { message: errorMessage };
      }

      if (!isLocalOnly) {
        DataMgr.doEvent('xchatupdate', { response: errorResponse, isError: true });
        eventBus.emit('gotMessage', { response: errorResponse, isError: true });
      }
    }

    while (true) {
      if (this.stopStreaming) {
        await reader.cancel();
        break;
      }

      const { value, done } = await reader.read();

      if (done) {
        onMessage({ isError: false, isDone: true, isStreaming: true });
        await reader.cancel();
        break;
      }

      delta += decoder.decode(value, { stream: true });
      const messages = delta.split('\n');

      for (let i = 0; i < messages.length - 1; i++) {
        const message = messages[i];
        if (message.startsWith('data: ')) {
          const messageJson = message.substring(6); // Remove "data: " prefix
          if (messageJson == '[DONE]') {
            onMessage({ isError: false, isDone: true, isStreaming: true });
            await reader.cancel();
            break;
          }
          else {
            const json = JSON.parse(messageJson);
            json.isStreaming = true;
            onMessage(json);
          }
        }
        // else {
        //   console.log(delta);
        //   const json = JSON.parse(delta);
        //   json.isStreaming = false;
        //   onMessage(json);
        // }
      }

      delta = messages[messages.length - 1];
    }
  }

  static validateOpenAIKey(key: string, akey: string) {
    if (key && key.indexOf('4:') === 0) {
      key = key.substring(2);
    }

    const regEx = /^sk-[A-Za-z0-9]{40,64}$/;
    const isOpenApiKeyValid = regEx.test(key);
    const isAzureOpenApiKeyValid = akey && akey.length > 8;
    return isOpenApiKeyValid || isAzureOpenApiKeyValid;
  }

  //static async getFunction()

  static async sendToWhisper2(blob: Blob) {
    const openAiKey = localStorage.getItem('openai-key') ?? '';
    const file = new File([blob], 'recording.ogg', { type: 'audio/ogg; codecs=opus' });
    const data = new FormData();
    data.append('model', 'whisper-1');
    data.append('file', file);

    try {
      const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + openAiKey
        },
        body: data as any
      });

      const responseData = await response.json();
      console.log(responseData);
      eventBus.emit('gotSpeechText', { text: responseData.text, isError: false });

    } catch (error) {
      console.error(error);
      eventBus.emit('gotSpeechText', { text: '', isError: true, error: error });
    }
  }

  static async complete(instruction: string, prompt: string, isGPT4: boolean) {
    var gptModel = isGPT4 ? '4' : '3.5';
    var model = isGPT4 ? await XService.get4ModelName() : await XService.get35ModelName();
    // getGPTModel((gptModel === '4' || gptModel === '4-32k'), gptModel === '4-32k');

    var conversation: ChatDuo[] = [];
    conversation.push({ user: prompt, _isComplete: false, bot: null });

    var result = await XService.callChat(model, conversation, instruction, null, false);
    return result;
  }

  static async recordAudio(): Promise<Blob> {
    // Request access to the microphone
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    // Create a MediaRecorder instance to record the audio stream
    const mediaRecorder = new MediaRecorder(stream);
    const audioChunks: Blob[] = [];

    // Event handler for when a new audio chunk is available
    mediaRecorder.addEventListener('dataavailable', (event) => {
      audioChunks.push(event.data);
    });

    let audioBlob: Blob | undefined;
    // Event handler for when the recording is stopped
    mediaRecorder.addEventListener('stop', () => {
      // Combine the audio chunks into a single Blob
      audioBlob = new Blob(audioChunks, { type: 'audio/webm' });

      // Call the existing code that uses the audio blob
      XService.stt(audioBlob);
    });

    // Start recording
    mediaRecorder.start();

    // Stop recording after a certain duration (e.g., 5 seconds)
    let silenceTimeout: NodeJS.Timeout;

    // Event handler for when a new audio chunk is available
    mediaRecorder.addEventListener('dataavailable', (event) => {
      audioChunks.push(event.data);

      // Reset the silence timeout
      clearTimeout(silenceTimeout);
      silenceTimeout = setTimeout(() => {
        mediaRecorder.stop();
        stream.getTracks().forEach(track => track.stop()); // Stop the microphone stream
      }, 1000);
    });

    return new Promise((resolve) => {
      // Resolve with the audio blob when the recording is stopped
      mediaRecorder.addEventListener('stop', () => {
        resolve(audioBlob);
      });
    });
  }

  static async stt(audioBlob: Blob): Promise<string> {
    const openAiKey = localStorage.getItem('openai-key') ?? '';

    const data = new FormData();
    data.append('model', 'whisper-1');
    data.append('file', audioBlob);

    try {
      const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + openAiKey
        },
        body: data as any
      });

      const responseData = await response.json();
      return responseData.text;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  static async tts(text, voice, play = true) {
    if (!voice) {
      voice = 'nova';
    }

    const openAiKey = localStorage.getItem('openai-key') ?? '';

    const response = await fetch('https://api.openai.com/v1/audio/speech', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + openAiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "model": "tts-1",
        "input": text,
        "voice": voice
      })
    });

    const responseData = await response.arrayBuffer();

    // Create a Blob from the response data
    const blob = new Blob([responseData], { type: 'audio/mpeg' });

    // Create an object URL for the Blob
    const url = URL.createObjectURL(blob);

    // You can use this URL to play the audio or download it
    console.log(url);

    if (play) {
      let audio = new Audio(url);
      audio.play();
    }

    return blob;
  }

  static async generateImage(prompt: string, size: string = "1024x1024", quality: string = "standard", n: number = 1) {
    const openAiKey = localStorage.getItem('openai-key') ?? '';

    const response = await fetch('https://api.openai.com/v1/images/generations', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + openAiKey,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "model": "dall-e-3",
        "prompt": prompt,
        "size": size,
        "quality": quality,
        "n": n
      })
    });

    const responseData = await response.json();

    if (responseData.data && responseData.data.length > 0) {
      const imageUrl = responseData.data[0].url;

      console.log(imageUrl);

      // You can use this URL to display the image or download it
      return imageUrl;
    } else {
      console.error("No image was generated.");
      return null;
    }
  }

}

export class XServiceInfo {
  service: string;
  key: string;
  url: string;
  data: any;
  elastic: any;
  getUrl: (modelType: XServiceType) => string;
}

export enum XServiceType {
  none,
  GPT4Chat,
  GPT35Chat,
  EmbeddingsAda002
}
