import { XService } from "global/openai/xservice";
import { AADAuth } from "./aadAuth";
import { AuthZero } from "./authZero";
import { ProxyAuth } from "./proxyAuth";

export class XAuth {
  static service: any = null;

  static init() {
    if (this.service != null) {
      return;
    }

    const service = XService.getService();
    if (service === 'azureopenai') {
      this.service = AADAuth.xinit();
    } else if (service === 'openai') {
      this.service = AuthZero.xinit();
    } else if (service === 'copilot') {
      this.service = ProxyAuth.xinit();
    }
  }

  static async getToken() {
    this.init();
    return await this.service.getToken();
  }

  static async getMyName() {
    this.init();
    return await this.service.getMyName();
  }

  static async getMyUserName() {
    this.init();
    return await this.service.getMyUserName();
  }

  static async isLoggedIn() {
    this.init();
    return await this.service.isLoggedIn();
  }

  static async setLogged(isLoggedIn: boolean) {
    this.init();
    return await this.service.setLogged(isLoggedIn);
  }

  static async forceLogin(afterLogin: () => void) {
    this.init();
    return await this.service.forceLogin(afterLogin);
  }

  static async authRedirected() {
    this.init();
    return await this.service.authRedirected();
  }

  static async logout(afterLogout: () => void) {
    this.init();
    return await this.service.logout(afterLogout);
  }

  static async login(afterLogin: () => void) {
    this.init();
    return await this.service.login(afterLogin);
  }
}

export class XAuthLocal {
  static services = { 'azureopenai': null, 'openai': null, 'copilot': null };
  service: any = null;
  serviceName: string = '';

  constructor(serviceName: string) {
    this.serviceName = serviceName.toLowerCase();
    if (XAuthLocal.services[this.serviceName] != null) {
      this.service = XAuthLocal.services[this.serviceName];
    }
  }

  static async get(serviceName: string, scope: string, auth: string, clientId: string, tenantId: string = null, proxyUrl: string = null) {
    const svc = new XAuthLocal(serviceName);
    svc.init();
    svc.service.setScopeAuthId(scope, auth, clientId, tenantId, proxyUrl);
    return svc;
  }

  init() {
    if (this.service != null) {
      return;
    }

    const service = this.serviceName;
    if (service === 'azureopenai') {
      //debugger;
      this.service = AADAuth.xinit();
      XAuthLocal.services[this.serviceName] = this.service;
      this.service.setLogged(false);
    } else if (service === 'openai') {
      this.service = AuthZero.xinit();
      XAuthLocal.services[this.serviceName] = this.service;
      this.service.setLogged(false);
    } else if (service === 'copilot') {
      this.service = ProxyAuth.xinit();
      XAuthLocal.services[this.serviceName] = this.service;
      this.service.setLogged(false);
    }
  }

  async getToken() {
    this.init();
    return await this.service.getToken();
  }

  async getMyName() {
    this.init();
    return await this.service.getMyName();
  }

  async getMyUserName() {
    this.init();
    return await this.service.getMyUserName();
  }

  async isLoggedIn() {
    this.init();
    return await this.service.isLoggedIn();
  }

  async setLogged(isLoggedIn: boolean) {
    this.init();
    return await this.service.setLogged(isLoggedIn);
  }

  async forceLogin(afterLogin: () => void) {
    this.init();
    return await this.service.forceLogin(afterLogin);
  }

  async authRedirected() {
    this.init();
    return await this.service.authRedirected();
  }

  async logout(afterLogout: () => void) {
    this.init();
    return await this.service.logout(afterLogout);
  }

  async login(afterLogin: () => void) {
    this.init();
    return await this.service.login(afterLogin);
  }
}
