import { Comm } from "./Comm";
import { AIComm } from "./AIComm";
import { UISettings, ZModel, ZService, ZServiceTemplate, ZSettings } from "./ZSettingsfn";
import { generateUID } from "../utils";
import { Storage } from '../Storage';
import { ZStorage } from "./ZStorage";
import { eventBus } from "../event-bus";
import { CCX_HistoryItemsRecord, ChatPrompt, DataMgr } from "../data-mgr/data-mgr";
import { XMgr } from "../x-mgr/x-mgr";
import { CCXChatData } from "global/ccxdata";
import { BrowserCommExtra } from "./BrowserCommExtra";
import MarkdownIt from 'markdown-it';
// import markdownItKatex from '@xtthaop/markdown-it-katex';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css'; // Import a highlight.js theme
import 'katex/dist/katex.min.css'; // Import KaTeX CSS
import html2text from 'html2text';
import { LazyKatex } from "components/2/utils/lazy-katex";

export class Model {
  //service: Service;
  comm: AIComm;
  uid: string;
  id: string;
  showId: string;
  name: string;
  description: string;
  pricing: { prompt: string; completion: string, image?: string, request?: string };
  contextLength: number;
  architecture: { modality: string; tokenizer: string; instruct_type: string };
  topProvider: { max_completion_tokens: number; is_moderated: boolean };
  perRequestLimits: { prompt_tokens: string; completion_tokens: string };
  // uniqueId: string;
  cmd: number = 0;

  type: string = '';
  instruction: string;
  title: string;
  information: string = '';
  purpose: string = '';
  errorMethod: string = 'stop'; //inherit, stop, retry, exponential
  errorThreshold: number = 6;
  errorLog: string = 'notify'; //notify, log, none
  role: string = 'participant'; //leader, participant, observer, none
  replyPath: string = ''; //path to reply into
  isReplying: boolean = false; //is the model currently replying
  controllerMethod: string = 'purpose-next-checkall';

  // service: ZService;
  owner: string;
  serviceUid?: string;

  azureInfo?: AzureConfigModel;
  isSelected?: boolean = false;
  zmodel?: ZModel;
  isCustom?: boolean = false;

  constructor() {
    this.uid = 'M' + generateUID(8);
  }

  static constructorAll(id: string = '', name: string = '', description: string = '',
    pricing: { prompt: string; completion: string, image?: string, request?: string },
    contextLength: number, architecture: { modality: string; tokenizer: string; instruct_type: string }, topProvider: { max_completion_tokens: number; is_moderated: boolean }, perRequestLimits: { prompt_tokens: string; completion_tokens: string }): Model {
    const model = new Model();
    model.id = id;
    model.name = name;
    model.description = description;
    model.pricing = pricing;
    model.contextLength = contextLength;
    model.architecture = architecture;
    model.topProvider = topProvider;
    model.perRequestLimits = perRequestLimits;
    model.uid = 'M' + generateUID(8);

    return model;
  }

  static constructZModel(zmodel: ZModel): Model {
    const model = new Model();
    model.id = zmodel.id;
    model.name = zmodel.name;
    model.description = zmodel.description;
    model.pricing = { prompt: zmodel.cost?.toString(), completion: zmodel.cost?.toString() };
    model.contextLength = zmodel.maxTokens;
    model.architecture = { modality: zmodel.type, tokenizer: null, instruct_type: null };
    model.topProvider = { max_completion_tokens: 0, is_moderated: zmodel.isModerated };
    model.perRequestLimits = { prompt_tokens: null, completion_tokens: null };
    model.azureInfo = zmodel.azureInfo;
    model.uid = 'M' + generateUID(8);
    model.zmodel = zmodel;

    return model;
  }

  static constructAzureConfigModel(cmodel: AzureConfigModel): Model {
    const model = new Model();
    model.id = cmodel.modelName;
    model.name = cmodel.name;
    model.contextLength = cmodel.maxTokens;
    model.azureInfo = cmodel;
    model.type = 'text';
    model.uid = 'M' + generateUID(8);

    // console.log(model.id + ':' + model.uid + ':3:' + (new Error()).stack);

    return model;
  }

  static construct3(id: string, name: string, contextLength: number): Model {
    const model = new Model();
    model.id = id;
    model.name = name;
    model.contextLength = contextLength;
    model.uid = 'M' + generateUID(8);

    return model;
  }

  static getServiceModelName(model: Model) {
    const service = ModelSets.getService(model);
    return (service ? (service.name + ':') : '') + model.id;
  }

  static fullId(model: Model) {
    return Model.getServiceModelName(model);
  }

  static isSecure(model: Model, services: ZService[] = null) {
    const service = ModelSets.getService(model, services);
    return service.isSecure;
  }

  static contextLengthShort(model: Model) {
    var tokens = model.contextLength;

    if (!tokens) {
      return '?';
    }

    var stokens = tokens.toString();
    if (tokens > 1000) {
      stokens = Math.round(tokens / 1000) + 'k';
    }
    return stokens;
  }

  //imgs:05
  static async sendMessage(callData: SendMessageData) {
    // const myService = XServices.getInstance();
    const comm = Comm.getCommForModel(callData.model);
    callData.model.isReplying = true;
    try {
      callData.preInstructions = callData.preInstructions + (callData.model.instruction ? callData.model.instruction : '');
      callData.infTemp.inference.temp = callData.infTemp.inference.temp ?? 0.3;
      //Upcall:02
      await comm.call(callData);
    }
    catch (error) {
      console.error(error);
      callData.model.isReplying = false;

      const txtResp = TextReply.create(error.message, true);
      callData.update(txtResp, callData.model, true);
      eventBus.emit('ccx-error', { error: error.message, isDone: true, uid: callData.chatId });

      throw error;
    }

    callData.model.isReplying = false;
  }

  static async send(model: Model, instructions: string, infTemp: ChatSettingsInferenceTemplate | null): Promise<string> {
    const comm = Comm.getCommForModel(model);
    model.isReplying = true;
    try {
      const reply = await comm.callDirect(instructions, infTemp, model);
      model.isReplying = false;
      return reply;
    }
    catch (error) {
      console.error(error);
      model.isReplying = false;
      throw error;
    }
  }

  static async say(voiceModel: Model, text: string, voice: string = null) {
    if (!voiceModel) {
      return '';
    }
    const comm = Comm.getCommForModel(voiceModel);
    voiceModel.isReplying = true;
    const reply = await comm.callTTS(text, voice, voiceModel, voice);
    if (reply) {
      await DataMgr.speech.sayFromUrl(reply);
    }
    voiceModel.isReplying = false;
    return reply;
  }

  static async listen(listenModel: Model, blob: Blob, convId: string, mode: string) {
    const comm = Comm.getCommForModel(listenModel);
    listenModel.isReplying = true;
    const reply = await comm.callListen(blob, listenModel, convId, mode);
    listenModel.isReplying = false;
    return reply;
  }

  static async sayToUrl(voiceModel: Model, text: string, voice: string = null): Promise<string> {
    if (!voiceModel) {
      return '';
    }
    const comm = Comm.getCommForModel(voiceModel);
    voiceModel.isReplying = true;
    const reply = await comm.callTTS(text, voice, voiceModel, voice);
    voiceModel.isReplying = false;
    return reply ? reply : '';
  }

  static parseTextReply(model: Model, text: string) {
    const comm = Comm.getCommForModel(model);
    return comm.parseTextReply(text, model);
  }

  static color(model: Model) {
    var name = model.comm?.name?.toLowerCase();
    switch (name) {
      case 'azureopenai':
        name = 'azure';
        break;
      case 'openai':
      case 'openrouter':
      case 'huggingface':
      case 'groq':
        break;
      default:
        name = 'other';
        break;
    }

    return `model-${name}`;
  }

  static words(model: Model) {
    const service = ModelSets.getService(model);
    var name = model.comm ? model.comm.name.toLowerCase() : service?.commType?.toLowerCase();
    var owner = model.owner;
    var id = model.id ?? '';
    var tokens = Model.contextLengthShort(model);
    var pricing = model.pricing ? model.pricing.prompt + '/' + model.pricing.completion : '?';
    var pricingM = model.pricing ? parseFloat((Number(model.pricing.prompt) * 1000000).toFixed(3)) + '/' + parseFloat((Number(model.pricing.completion) * 1000000).toFixed(3)) : '?';

    switch (name) {
      case 'azureopenai':
        name = 'azure';
        owner = 'openai';
        break;
      case 'openai':
      case 'openrouter':
      case 'huggingface':
      case 'groq':
        break;
      default:
        name = 'other';
        break;
    }

    if (id.indexOf('/') > -1) {
      owner = id.split('/')[0];
      id = id.split('/')[1];
    }

    if (id.indexOf(':free') > -1 || pricing === '0/0' || model.pricing?.prompt?.startsWith('-')) {
      pricing = 'free';
      pricingM = 'free';
    }

    if (pricing === 'undefined/undefined') {
      pricing = '?';
      pricingM = '?';
    }

    return [name, owner, id, tokens, pricing, pricingM];
  }

  static copyModel(model: Model) {
    const newModel = Model.constructorAll(model.id, model.name, model.description, model.pricing, model.contextLength, model.architecture, model.topProvider, model.perRequestLimits);
    newModel.comm = model.comm;
    newModel.azureInfo = model.azureInfo;
    newModel.replyPath = model.replyPath;
    newModel.uid = 'm' + generateUID(8);

    if (model.id === 'gpt-4o') {
      //debugger;
    }

    newModel.serviceUid = model.serviceUid;
    return newModel;
  }

  static loadModelsFromService(service: ZService, comm: AIComm) {
    if (!service || !service.models) {
      return [];
    }

    return service.models;

    // return service.models.map((model) => {
    //   const newModel = Model.constructZModel(model);
    //   newModel.service = service;
    //   return newModel;
    // });
  }

  static loadModelsFlat(rawData, comm, service): Model[] {
    if (!rawData || !rawData.data) {
      return [];
    }

    return rawData.data.filter(f => f.active).map((item: any) => {
      const model = Model.constructorAll(
        item.id,
        item.owned_by + ':' + item.id,
        '',
        { prompt: '0', completion: '0' },
        item.context_window,
        { modality: 'text', tokenizer: null, instruct_type: null },
        { max_completion_tokens: null, is_moderated: null },
        { prompt_tokens: null, completion_tokens: null });
      model.comm = comm;
      model.owner = item.owned_by;
      model.serviceUid = service.uid;
      return model;
    });
  }

  static loadModels(rawData, comm): Model[] {
    if (!rawData || !rawData.data) {
      return [];
    }

    return rawData.data.map((item: any) => {
      const model = Model.constructorAll(item.id, item.name ?? (item.owned_by + ':' + item.id), item.description, item.pricing ?? '?', item.context_length ?? item.context_window, item.architecture, item.top_provider, item.per_request_limits);
      model.comm = comm;
      return model;
    });
  }

  static loadModelsFromServices(service, comm) {

    if (!service.models) {
      return [];
    }

    const models = [];
    //    for (var i = 0; i < services.length; i++) {
    //      const service = services[i];
    for (var j = 0; j < service.models.length; j++) {
      const xmodel = service.models[j];
      const model = Model.constructorAll(xmodel.id, xmodel.name, xmodel.description,
        {
          prompt: xmodel.cost?.toString(), completion: xmodel.cost?.toString(),
          image: xmodel.image ? xmodel.image.toString() : null, request: xmodel.request ? xmodel.request.toString() : null
        },
        xmodel.maxTokens,
        { modality: xmodel.type, tokenizer: null, instruct_type: null },
        { max_completion_tokens: 0, is_moderated: xmodel.isModerated },
        { prompt_tokens: null, completion_tokens: null });

      model.comm = comm;
      model.serviceUid = service.uid;
      model.replyPath = xmodel.replyPath;
      models.push(model);
    }
    //    }
    return models;
  }

  static loadModelsFromAzure(rawData) {
    const data: AzureResponse = JSON.parse(rawData);

    return data.value.map((azureModel: AzureModel) => {
      const { id, name, properties } = azureModel;

      // Map the Azure model to your Model class
      // Replace the following with the actual mapping logic based on your Model class structure
      const model = Model.constructorAll(
        name,
        name,
        properties.model.version, // Assuming a static description or derive from Azure model
        { prompt: '0', completion: '0', image: '0', request: '0' }, // Assuming static pricing or derive from Azure model
        properties.currentCapacity,
        {
          modality: properties.capabilities.chatCompletion === 'true' ? 'text' : '',
          tokenizer: 'Tokenizer', // Assuming static tokenizer or derive from Azure model
          instruct_type: 'InstructType', // Assuming static instruct_type or derive from Azure model
        },
        {
          max_completion_tokens: 0, // properties.sku.capacity,
          is_moderated: properties.capabilities.chatCompletion === 'true',
        },
        {
          prompt_tokens: '0', // Assuming static prompt_tokens or derive from Azure model
          completion_tokens: '0', // Assuming static completion_tokens or derive from Azure model
        }
      );

      // Set additional properties if needed
      return model;
    });
  }
}

export class ChatSettings {
  title: string;
  saved: Date | null;
  uniqueId: string;
  chatInstructions: string = '';
  information: string = '';
  inference?: InferenceParams;
  sync?: boolean = false;
  isync?: boolean = false;
  template?: string = 'default';
  customTemplate?: ChatSettingsCustomTemplate = null;
  voice?: string = 'Nova';
  chainMode?: string = 'none';
  chainClear?: boolean = false;
  chainCount?: number = 0; //0=manual, >0=automatic
}

export interface ChatSettingsInferenceTemplate {
  inference?: InferenceParams;
  template?: string;
  customTemplate?: ChatSettingsCustomTemplate | null;
}

export class ChatSettingsCustomTemplate {
  preInst: string = '';
  postInst: string = '';
  preUser: string = '';
  postUser: string = '';
  preAsst: string = '';
  postAsst: string = '';
  leadingAsst: string = '';
}

export class InferenceParams {
  temp: number = 0.8;
  n_predict: number = -1;
  top_k: number = 40;
  repeat_penalty = 1.1;
  min_p = 0.05;
  top_p = 0.95;
  frequency_penalty = 0.0;
  max_tokens = 0;
  n = 1;
  response_format = { 'type': 'text' };
}

export class Service {
  id: string;
  name: string;
  models: Model[];
}

export class ZTempStorage {
  // this.convSet.tempStorage.auths[this.serviceName] = authService;
  auths: any = {};
  name: string = null;
  prompt: any = {};
  instructions: any = {};
}

export class ConversationSet {
  models: Model[];
  conversation: Conversation;
  settings: ChatSettings;
  // storage?: ZStorage;
  zsettings?: ZSettings;
  tempStorage?: ZTempStorage;

  currentModel?: Model;

  constructor() {
    this.models = [];
    this.conversation = new Conversation();
    this.settings = new ChatSettings();
    this.settings.uniqueId = 'C' + generateUID(8);
    this.zsettings = ZSettings.getInstance();
    this.zsettings.load();
    this.tempStorage = new ZTempStorage();
  }

  storage?(convSet: ConversationSet) { //service: ZService, convSet: ConversationSet) {
    const service = ModelSets.getService(this.currentModel);

    return ZStorage.getStorage(service, convSet);
  }

  checkAndClearInstructions?(uid: string = this.conversation.uid) {
    if (this.zsettings.ui.protectInstructions && this.conversation.instructions.length > 0) {
      if (this.zsettings.ui.ask('Protected: Clear instructions?')) {
        this.conversation.clearInstructions(uid);
      }
    }
    else {
      this.conversation.clearInstructions(uid);
    }
  }

  checkAndLoadInstructions?(prompt: any, append = false) {
    if (!append && this.zsettings.ui.protectInstructions &&
      this.conversation.instructions.length > 0) {
      if (this.zsettings.ui.ask('Protected: Overwrite instructions?')) {
        this.conversation.loadInstructions(prompt);
      }
    }
    else {
      this.conversation.loadInstructions(prompt, append);
    }
  }

  remove?(message: any, andBelow: boolean = false) {
    const index = this.conversation.messages.indexOf(message);
    if (index > -1) {
      this.conversation.messages.splice(index, andBelow ? this.conversation.messages.length - index : 1);
      eventBus.emit('ccx-refresh-chat', { uid: this.conversation.uid });
    }
  }

  saveInstructions?() {
    const savedPrompt = {
      name: this.conversation.instructionsPrompt.name,
      prompt: this.conversation.instructionsPrompt.prompt,
      voice: this.conversation.instructionsPrompt.voice,
      link: this.conversation.instructionsPrompt.link,
      linkTitle: this.conversation.instructionsPrompt.linkTitle
    };
    //savedPrompt.

    const storage = this.storage(this);
    storage.savePrompt(savedPrompt);
    //storage.savePrompt(this.conversation.instructions, );
    //split HERE

    //this.conversation.saveInstructions(this.zsettings.ui);
  }

  deletePrompt?(prompt: any, convSet: ConversationSet) {
    if (this.zsettings.ui.ask('Delete instruction?')) {
      this.storage(convSet).deletePrompt(prompt);
    }
  }
}

export class Conversations {
  static save(models: Model[], conv: Conversation, settings: ChatSettings) {
    const allConversations = JSON.parse(Storage.get('conversations', '[]'));

    settings.saved = new Date();
    const data: ConversationSet = { models: models, conversation: conv, settings: settings, storage: null, zsettings: null };

    const existing = allConversations.find((c) => c.settings.uniqueId === settings.uniqueId);
    if (existing) {
      existing.models = models;
      existing.conversation = conv;
      existing.settings = settings;
    }
    else {
      allConversations.push(data);
    }

    Storage.set('conversations', JSON.stringify(allConversations));
  }

  static load(uniqueId: string) {
    const allConversations = JSON.parse(Storage.get('conversations', '[]'));
    const data = allConversations.find((c) => c.settings.uniqueId === uniqueId);
    return data;
  }

  static loadAll() {
    const allConversations = JSON.parse(Storage.get('conversations', '[]'));
    return allConversations;
  }
}

export class ModelSets {
  modelData: Model[] = null;
  services: Service[] = [];
  settings: ZSettings = null;
  tempStorage: ZTempStorage = null;
  static instance: ModelSets = null;
  static aiTemplates: any = null;

  constructor() {
    if (!ModelSets.instance) {
      ModelSets.instance = this;
    }
  }

  static async postLoad() {
    const aiT = await fetch('/assets/data/aitemplates.json')
      .then((response: Response) => response.json());
    this.aiTemplates = aiT;

    const inst = ModelSets.instance;
    if (inst && inst.services) {
      const svcs = inst.settings.services;
      for (var i = 0; i < svcs.length; i++) {
        if (svcs[i].loadDefaultsLater) {
          try {
            // BrowserComm.sBrowserModels();
          }
          catch (e) {
            console.error(e);
          }
        }
      }
    }

    return aiT;
  }

  static postLoadLocationConfig(serviceTemplates: ZServiceTemplate[]) {
    ModelSets.addDefaultModels(serviceTemplates);
  }

  static async getTemplateKeyNames(): Promise<TemplateNameKey[]> {
    const result: TemplateNameKey[] = [];
    if (!this.aiTemplates) {
      await this.postLoad();
    }
    this.aiTemplates.promptTemplates.map(t => {
      result.push({ key: t.id, name: t.model });
    });

    return result;
  }

  static async formInferenceTemplate(infTemp: ChatSettingsInferenceTemplate | null, defaultValue: string = 'none', reduce: boolean = true): Promise<ChatSettingsCustomTemplate> {
    const template = new ChatSettingsCustomTemplate();
    const value = !infTemp || infTemp.template === 'default' ? defaultValue : infTemp.template;
    switch (value) {
      case 'custom':
        template.preInst = infTemp.customTemplate.preInst;
        template.postInst = infTemp.customTemplate.postInst;
        template.preUser = infTemp.customTemplate.preUser;
        template.postUser = infTemp.customTemplate.postUser;
        template.preAsst = infTemp.customTemplate.preAsst;
        template.postAsst = infTemp.customTemplate.postAsst;
        template.leadingAsst = infTemp.customTemplate.leadingAsst;
        break;
      case 'none':
      case '':
        break;
      default:

        if (!this.aiTemplates) {
          await this.postLoad();
        }

        let aiTemplate = this.aiTemplates.promptTemplates.find(m => m.id === value);
        if (!aiTemplate) {
          aiTemplate = this.aiTemplates.promptTemplates.find(m => m.id.endsWith('/' + value));
        }
        if (!aiTemplate) {
          console.error('no template found for : ' + value);
          break;
        }

        if (aiTemplate.template.type != 'string') {
          console.warn('no string template found for : ' + value);
          break;
        }

        aiTemplate.template.items.map(i => {
          switch (i.role) {
            case 'system':
              template.preInst = (i.before ?? '').replace(/\n/g, '\\n');
              template.postInst = (i.after ?? '').replace(/\n/g, '\\n');
              template.preInst = (i.before ?? '').replace(/ /g, '\\s');
              template.postInst = (i.after ?? '').replace(/ /g, '\\s');
              break;
            case 'user':
              template.preUser = (i.before ?? '').replace(/\n/g, '\\n');
              template.postUser = (i.after ?? '').replace(/\n/g, '\\n');
              template.preUser = (i.before ?? '').replace(/ /g, '\\s');
              template.postUser = (i.after ?? '').replace(/ /g, '\\s');
              break;
            case 'assistant':
              template.preAsst = (i.before ?? '').replace(/\n/g, '\\n');
              template.postAsst = (i.after ?? '').replace(/\n/g, '\\n');
              template.leadingAsst = i.leading ? i.leading.replace(/\n/g, '\\n') : template.preAsst;
              template.preAsst = (i.before ?? '').replace(/ /g, '\\s');
              template.postAsst = (i.after ?? '').replace(/ /g, '\\s');
              template.leadingAsst = i.leading ? i.leading.replace(/ /g, '\\s') : template.preAsst;
              break;
          }
        });

        //   const end = '<ctl123>\\n';
        //   template.preInst = '';
        //   template.postInst = end;
        //   template.preUser = 'User:\\n';
        //   template.postUser = end;
        //   template.preAsst = 'Model:\\n';
        //   template.postAsst = end;
        //   break;
        // case 'phi3':
        //   const endphi = '\\n<|end|>\\n';
        //   template.preInst = '<|system|>\\n';
        //   template.postInst = endphi;
        //   template.preUser = '<|user|>\\n';
        //   template.postUser = endphi;
        //   template.preAsst = '<|assistant|>\\n';
        //   template.postAsst = endphi;
        break;
    }

    return reduce ? this.changeToNewLine(template) : template;
  }

  static changeToNewLine(temp: ChatSettingsCustomTemplate): ChatSettingsCustomTemplate {
    temp.preInst = temp.preInst.replace(/\\n/g, '\n');
    temp.postInst = temp.postInst.replace(/\\n/g, '\n');
    temp.preUser = temp.preUser.replace(/\\n/g, '\n');
    temp.postUser = temp.postUser.replace(/\\n/g, '\n');
    temp.preAsst = temp.preAsst.replace(/\\n/g, '\n');
    temp.postAsst = temp.postAsst.replace(/\\n/g, '\n');
    temp.leadingAsst = temp.leadingAsst.replace(/\\n/g, '\n');

    temp.preInst = temp.preInst.replace(/\\s/g, ' ');
    temp.postInst = temp.postInst.replace(/\\s/g, ' ');
    temp.preUser = temp.preUser.replace(/\\s/g, ' ');
    temp.postUser = temp.postUser.replace(/\\s/g, ' ');
    temp.preAsst = temp.preAsst.replace(/\\s/g, ' ');
    temp.postAsst = temp.postAsst.replace(/\\s/g, ' ');
    temp.leadingAsst = temp.leadingAsst.replace(/\\s/g, ' ');
    return temp;
  }

  static getClosestModel(modelNameOrId: string, services: ZService[]): Model {
    const models: Model[] = [];
    services.map(s => {
      s.models.map(m => {
        models.push(m);
      });
    });

    let model = models.find(m => m.id === modelNameOrId);
    if (model) {
      return model;
    }

    model = models.find(m => m.name === modelNameOrId);
    if (model) {
      return model;
    }

    model = models.find(m => m.id.endsWith(modelNameOrId));
    if (model) {
      return model;
    }

    return null;
  }

  static addServiceFromTemplate(services, temp: ZServiceTemplate) {
    const browser = new ZService();
    browser.name = temp.commName;
    browser.apiURL = temp.apiURL;
    browser.apiKey = temp.apiKey;
    browser.commType = temp.commType;
    browser.isSecure = true;
    browser.useDefaultModels = true;
    services.push(browser);
  }

  static addDefaultModels(serviceTemplates: ZServiceTemplate[]) {
    //alert...
    if ((window as any).ai) {
      if (ModelSets.instance.settings.services.filter(svc => svc.commType === 'browser').length === 0) {
        var template = serviceTemplates.filter(s => s.commType === 'browser');
        if (template.length > 0) {
          const xService = new ZService();
          const newService = JSON.parse(JSON.stringify(template[0]));

          const keys = Object.keys(newService);
          for (var i = 0; i < keys.length; i++) {
            xService[keys[i]] = newService[keys[i]];
          }

          ModelSets.instance.settings.addService(template[0], xService);
          //          const newRealService = ModelSets.instance.settings.services.find(s => s.commType == 'browser');
        }

        BrowserCommExtra.addDefaults(ModelSets.instance.settings, true, serviceTemplates);
      }
    }
  }

  static getCoreModel(modelId: string, modelServiceUid: string, services: ZService[]) {
    if (!modelServiceUid || !(services || ModelSets.instance)) {
      return null;
    }
    const service = (services ?? ModelSets.instance.settings.services).find((s) => s.uid === modelServiceUid);
    if (service) {
      const model = service.models.find(s => s.id === modelId);
      if (model) {
        return model;
      }
    }

    return null;
  }

  static serviceVoiceModel(convSet: ConversationSet) {
    const currentModel = convSet.currentModel;
    if (!currentModel) {
      return null;
    }

    const service = ModelSets.getService(currentModel);
    if (!service) {
      return null;
    }

    const voiceModel = service.models.find((m) => m.isSelected && m.architecture && m.architecture.modality === 'voice');
    if (voiceModel && !voiceModel.serviceUid) {
      voiceModel.serviceUid = service.uid;
    }
    return voiceModel?.isSelected ? voiceModel : null;
  }

  static serviceListenModel(convSet: ConversationSet) {
    const currentModel = convSet.currentModel;
    if (!currentModel) {
      return null;
    }

    const service = ModelSets.getService(currentModel);
    if (!service) {
      return null;
    }

    const listenModel = service.models.find((m) => m.isSelected && m.architecture && m.architecture.modality === 'audio');
    if (listenModel && !listenModel.serviceUid) {
      listenModel.serviceUid = service.uid;
    }

    return listenModel?.isSelected ? listenModel : null;
  }

  static getService(model: Model, services: ZService[] = null) {
    if (!model || !(services || ModelSets.instance)) {
      return null;
    }
    return (services ?? ModelSets.instance.settings.services).find((s) => s.uid === model.serviceUid);
  }

  static getKeyFor(name: string) {
    const service = ModelSets.instance.settings.services.find((s) => s.commType === name);
    return service ? service.apiKey : null;
  }

  static getServiceForCommName(name: string) {
    return ModelSets.instance.settings.services.find((s) => s.commType === name);
  }

  _getServiceForCommName(name: string) {
    return this.settings.services.find((s) => s.commType === name);
  }

  static getAuthFor(service: ZService) {
    return ModelSets.instance.tempStorage.auths[(service as any).commType];
  }

  async load(data: CCXChatData, convSet: ConversationSet = null) {
    if (this.modelData) {
      return;
    }

    //ZService.debugServices(data.zSettings.services, 'ModelSets.load1');

    const settings = data.zSettings;
    this.settings = settings;

    if (convSet) {
      this.tempStorage = convSet.tempStorage;
    }

    const allModels = await this.syncModelSelections(settings);

    // hack hack: copyover model sub info from openrouter to openai
    const openaiModels = allModels.filter((model) => model.comm && model.comm.name === 'openai');
    const openrouterModels = allModels.filter((model) => model.comm && model.comm.name === 'openrouter');
    openaiModels.forEach((model) => {
      const orModel = openrouterModels.find((m) => m.id === 'openai/' + model.id);
      if (orModel) {
        for (let key in orModel) {
          if (!(key in model) || model[key] === null || model[key] === undefined || model[key] === '') {
            model[key] = orModel[key];
          }
        }
      }
    });

    //ZService.debugServices(data.zSettings.services, 'ModelSets.load2');

    this.modelData = allModels;

    // ensure model selections on chat page
    // allModels.filter(m => m.isSelected).map(async m => {
    //   await eventBus.emit('select-model', { chatId: 'chat', model: m, isSelected: m.isSelected, isShift: false });
    // })

    // await fetch('/assets/data/models.json')
    //   .then((response: Response) => response.json())
    //   .then((data) => {
    //     this.modelData = data;
    //     this.parse();
    //   });
  }

  async syncModelSelections(settings: ZSettings) {
    // load stale services/models
    const allModels = await Comm.getAllModelsJson();

    // load saved services/models
    const services = settings.services; //.map(s => s.commType); //  ['openai', 'openrouter'];
    // add in missing stale services into saved services
    const keys = Object.keys(allModels);
    for (var i = 0; i < keys.length; i++) {
      const service = allModels[keys[i]];

      const existingService = services.find((s) => s.commType === keys[i]);
      if (!existingService) {
        const newService = new ZService();
        newService.commType = keys[i];
        services.push(newService);
      }
    }

    return await this.syncModelSelections2(settings);
  }

  async syncModelSelections2(settings: ZSettings) {
    const services = settings.services; //.map(s => s.commType); //  ['openai', 'openrouter'];
    const allModels: Model[] = [];
    //;
    for (var i = 0; i < services.length; i++) {
      const comm = Comm.getCommForService(services[i]);
      let xmodels = null;
      //ZService.debug(services[i], 'Models:syncModelSelections-1');
      if (services[i].useDefaultModels || services[i].useRemoteConfig) {
        xmodels = await comm.getModelsClass(services[i]);
      }
      else {
        xmodels = Model.loadModelsFromServices(services[i], comm);
      }

      //ZService.debug(services[i], 'Models:syncModelSelections-2');

      let modelIndex = 0;
      xmodels.forEach((model) => {

        // if (model.id.indexOf('gpt-4o') > 0) {
        //   //debugger;
        // }

        try {
          if (model.architecture && model.architecture.modality) {
            model.type = model.architecture.modality;
          } else if (services[i].commType === 'groq') {
            model.type = 'chat';
            model.id = 'groq/' + model.id;
          }

          allModels.push(model);

          if (model.id === 'gpt-4o') {
            //debugger;
          }

          const existingModel = this.findModelInServices(model, services[i]);
          if (existingModel) {
            model.isSelected = existingModel.isSelected;
          }
          else {

            if (services[i].models && services[i].models.find(m => m.id === model.id)) {
              //debugger;
            }

            services[i].models.push(model);
          }
        } catch (e) {
          debugger;
        }
        ++modelIndex;
      });

      //ZService.debug(services[i], 'Models:syncModelSelections-3');
    }

    return allModels;
  }

  findModelInServices(model: Model, service: ZService) {
    return service.models.find((m) => m.id === model.id && m.name === model.name
      && m.azureInfo?.apiBase === model.azureInfo?.apiBase
      && m.azureInfo?.apiType === model.azureInfo?.apiType
      && m.azureInfo?.apiVersion === model.azureInfo?.apiVersion
      && m.azureInfo?.modelName === model.azureInfo?.modelName
      && m.azureInfo?.name === model.azureInfo?.name);
  }

  static getDefaultModel() {
    return ModelSets.instance && ModelSets.instance.modelData ? ModelSets.instance.modelData.find((model) => model.id === 'gpt-4o') : null;
  }

  getModels() {
    return ModelSets.instance.modelData;
  }

  static findModelById(uniqueId: string) {
    var parts = uniqueId.split(':');
    var serviceId = parts[0];
    var modelId = parts[1];

    for (var i = 0; i < ModelSets.instance.services.length; i++) {
      var service = ModelSets.instance.services[i];
      if (service.id === serviceId) {
        for (var j = 0; j < service.models.length; j++) {
          var model = service.models[j];
          if (model.id === modelId) {
            return model;
          }
        }
      }
    }

    return null;
  }

  static findModelByUID(uid: string) {
    const allModels = ModelSets.instance.modelData;
    for (var j = 0; j < allModels.length; j++) {
      if (allModels[j].uid === uid) {
        return allModels[j];
      }
    }

    return null;
  }

  parse() {
    for (var i = 0; i < this.modelData.length; i++) {
      var element = this.modelData[i] as any;

      var service = new Service();
      service.id = element.service.id;
      service.name = element.service.name;

      service.models = [];

      var elementModels = element.models;
      for (var j = 0; j < elementModels.length; j++) {
        var elementModel = elementModels[j];

        var model = new Model();
        //model.service = service;
        model.id = elementModel.id;
        model.name = elementModel.name;
        model.type = elementModel.type;
        model.contextLength = elementModel.maxtokens;

        //model.uniqueId = model.service.id + ':' + model.id;

        // hack hack
        if (model.id === 'gpt-3.5-turbo-16k') {
          model.instruction = 'Write text backwards';
        }

        service.models.push(model);
      }

      this.services.push(service);
    }
  }
}

export class ConversationHeader {
  title: string;
  uid: string;
  created: Date;
  updated: Date;
  isSelected?: boolean = false;
  isStarred?: boolean = false;
}

export class Section {
  type: string;
  key: string;
  value: string;
}

export class Conversation {
  messages: Message[] = [];
  uid?: string;
  instructions?: string = '';
  instructionsPrompt?: ChatPrompt = null;
  created: Date;
  updated: Date;
  instructionsLink?: string = '';
  instructionsLinkTitle?: string = '';
  sections?: Section[] = [];

  error?: { text: string; id: number } = null;

  constructor() {
    this.uid = 'C' + generateUID(8);
    this.created = new Date();
    this.updated = this.created;
  }

  static fromJSON(json: any): Conversation {
    let newObj = Object.create(Conversation.prototype);
    return Object.assign(newObj, json);
  }

  static fromError(error: any): Conversation {
    let newObj = Object.create(Conversation.prototype);
    newObj.error = error;
    return newObj;
  }

  static fromServerChat(items: CCX_HistoryItemsRecord[]) {
    const conv = new Conversation();

    const item0 = items[0];
    conv.uid = item0.chatId;
    conv.created = new Date(item0.created);
    conv.updated = conv.created;
    conv.instructions = item0.instructions;

    const mAsst = 'Asst-' + generateUID(8);

    items.map(c => {
      const msg = new Message();
      msg.author = 'user';
      msg.authorId = 'user';
      msg.message = c.request;
      msg.type = 'user'
      msg.isError = false;
      msg.isDone = true;
      msg.uid = 'MSG-' + generateUID(8);
      msg.isSecure = true;
      conv.messages.push(msg);

      if (c.response) {
        const msg2 = new Message();
        msg2.author = 'assistant';
        msg2.authorId = mAsst;
        msg2.message = c.response;
        msg2.type = 'assistant'
        msg2.isError = false;
        msg2.isDone = true;
        msg2.uid = 'MSG-' + generateUID(8);
        msg2.isSecure = true;
        conv.messages.push(msg2);
      }
    });

    return conv;
  }

  toServerChat(): CCX_HistoryItemsRecord[] {
    const items: CCX_HistoryItemsRecord[] = [];
    let seqId = 0;

    for (let i = 0; i < this.messages.length; i++) {
      const msg = this.messages[i];

      if (msg.type === 'user') {
        const item: CCX_HistoryItemsRecord = {
          userId: 0,
          chatId: this.uid,
          seqId: seqId++,
          request: msg.message,
          instructions: this.instructions,
          created: this.created
        };

        // Check if the next message is an assistant response
        if (i + 1 < this.messages.length && this.messages[i + 1].type === 'assistant') {
          item.response = this.messages[i + 1].message;
          i++; // Skip the next message as it has been processed
        }

        items.push(item);
      }
    }

    return items;
  }

  addErrorMessage(message: string) {
    return this.addMessage(message, 'system', 'system', 'system', true, null, false, null);
  }

  addMessage(message: string, author: string, type: string, id: string, isError: boolean = false, model: Model = null, isSecureMsg: boolean = false, data: Message = null): Message {
    var msg = new Message();
    msg.author = author;
    msg.type = type;
    msg.authorId = id;
    msg.isError = isError;
    msg.message = message;
    msg.images = data?.images;
    msg.files = data?.files;
    msg.voice = data?.voice;

    if (model) {
      msg.modelName = Model.fullId(model);
      msg.isSecure = isSecureMsg; //Model.isSecure(model);
    }

    this.messages.push(msg);

    this.updated = new Date();

    return msg;
  }

  getLastMessage(): Message | null {
    return this.messages.length > 0 ? this.messages[this.messages.length - 1] : null;
  }

  getLastMessageByAuthor(authorId: string): Message | null {
    for (var i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].authorId === authorId && !this.messages[i].isDone) {
        return this.messages[i];
      }
    }
    return null;
  }

  getLastMessageByType(type: string) {
    for (var i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].type === type) {
        return this.messages[i];
      }
    }
    return null;
  }

  removeLastMessageByType(type: string) {
    for (var i = this.messages.length - 1; i >= 0; i--) {
      if (this.messages[i].type === type) {
        this.messages.splice(i, 1);
        return;
      }
    }

    this.updated = new Date();
  }

  clear() {
    this.messages = [];
    this.uid = 'C' + generateUID(8);
    this.created = new Date();
    this.updated = this.created;
  }

  clearInstructions(uid: string = this.uid) {
    this.instructions = '';
    this.instructionsPrompt = new ChatPrompt();
    this.instructionsPrompt.prompt = '';
    this.instructionsPrompt.name = '';
    this.instructionsLink = '';
    this.instructionsLinkTitle = '';
    eventBus.emit('ccx-instructions-changed', { uid: uid, source: 'external' });
  }

  loadInstructions(prompt: any, append = false) {
    if (append && this.instructions && this.instructions.length > 0) {
      this.instructions = this.instructions + '\r\n\r\n' + prompt.prompt;
      this.instructionsPrompt = new ChatPrompt();
      this.instructionsPrompt.prompt = this.instructions;
      this.instructionsPrompt.name = '';

      this.instructionsLink = prompt.link ?? '';
      this.instructionsPrompt.link = prompt.link ?? '';

      this.instructionsLinkTitle = prompt.linkTitle ?? '';
      this.instructionsPrompt.linkTitle = prompt.linkTitle ?? '';
    }
    else {
      this.instructions = prompt.prompt;
      this.instructionsPrompt = prompt;

      this.instructionsLink = prompt.link ?? '';
      this.instructionsPrompt.link = prompt.link ?? '';

      this.instructionsLinkTitle = prompt.linkTitle ?? '';
      this.instructionsPrompt.linkTitle = prompt.linkTitle ?? '';
    }

    eventBus.emit('ccx-instructions-changed', { uid: this.uid, source: 'external' });
  }

  saveInstructions(ui: UISettings) {
    // split
    const existing = XMgr.instance.savedPrompts.find((p) => p.name === this.instructionsPrompt.name);
    if (existing) {
      if (!ui.ask('Overwrite existing prompt?')) {
        return;
      }

      existing.prompt = this.instructions;
    }
    else {
      XMgr.instance.savedPrompts.push({
        name: this.instructionsPrompt.name,
        prompt: this.instructions //,
        //voice: this.promptVoice
      });
    }

    XMgr.instance.savePrompts();
    eventBus.emit('ccx-instructions-loaded');
  }
}

export class PreReadFile {
  name: string;
  contentBase64: string;
}

export class Message {
  author: string;
  authorId: string;
  message: string;
  type: string; //user, bot, system
  isError: boolean;
  isDone: boolean = true;
  isEditing: boolean = false;

  uid?: string;
  modelName?: string;
  serviceName?: string;
  isSecure?: boolean;
  images?: string[];
  files?: PreReadFile[];
  voice?: string;
  isSpoken?: boolean = false;
  audioFileName?: string;

  static md?: MarkdownIt;
  static index? = 0;

  constructor() {
    this.uid = 'm' + generateUID(8);
  }

  static fromError(errMsg: string) {
    const msg = new Message();
    msg.author = 'system';
    msg.authorId = 'system';
    msg.message = errMsg;
    msg.type = 'system';
    msg.isError = true;
    msg.isDone = true;
    return msg;
  }

  static getInstructionMessage(instruction: string) {
    const msg = Message.addMessage(instruction,
      'system', 'system', '', false, null);
    msg.isDone = false;
    return msg;
  }

  static addInstruction(msgs: Message[], instruction: string, model: Model) {
    const msg = Message.addMessage(instruction,
      'system', 'system', model.uid, false, model);
    msg.isDone = false;
    msgs.push(msg);
  }

  static addUser(msgs: Message[], message: string, model: Model) {
    const msg = Message.addMessage(message,
      'user', 'user', model.uid, false, model);
    msg.isDone = false;
    msgs.push(msg);
  }

  static addAssistant(msgs: Message[], message: string, model: Model) {
    const msg = Message.addMessage(message,
      'assistant', 'assistant', model.uid, false, model);
    msg.isDone = false;
    msgs.push(msg);
  }

  static addMessage(message: string, author: string, type: string, id: string, isError: boolean = false, model: Model = null, isSecureMsg: boolean = false, data: Message = null): Message {
    var msg = new Message();
    msg.author = author;
    msg.type = type;
    msg.authorId = id;
    msg.isError = isError;
    msg.message = message;
    msg.images = data?.images;
    msg.files = data?.files;

    if (model) {
      msg.modelName = Model.fullId(model);
      msg.isSecure = isSecureMsg; //Model.isSecure(model);
    }

    return msg;
  }

  add(text: string) {
    this.message += text;
  }

  static extractCodeBlocks(text: string, defaultToSource: boolean = false): string {

    if (defaultToSource && text.indexOf('```') == -1) {
      return text;
    }

    const regex = /```(?:\w+\n+)?([\s\S]*?)\n```/gm;
    let matches;
    let codeBlocks = [];

    while ((matches = regex.exec(text)) !== null) {
      if (matches[1]) {
        codeBlocks.push(matches[1]);
      }
    }

    return codeBlocks.join('\n\n');
  }

  static copyTextToClipboard(text: string, codeOnly: boolean = false) {
    Message.copyToClipboard(codeOnly, text);
  }

  static async initMd() {
    this.index = 0;
    if (this.md) {
      return;
    }

    await LazyKatex.loadKatex();
    this.md = MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
      highlight: (str, lang) => {
        if (lang && hljs.getLanguage(lang)) {
          try {
            const ret = `<div class="code-block code-grid">
            <pre class="hljs code-grid-code"><code id="cc-${this.index}">` +
              hljs.highlight(str, { language: lang, ignoreIllegals: true }).value +
              `</code></pre>
        </div>`;
            return ret;
          } catch (__) { }
        }
        return '<pre class="hljs"><code>' + this.md.utils.escapeHtml(str) + '</code></pre>';
      }
    }).use(window.katex);
  }

  static messageToText(text: string, format: string) {
    const mdText = text;
    switch (format) {
      case 'markdown':
        return mdText;
      case 'html':
        return this.md.render(mdText).replace(/^<p>/, '').replace(/<\/p>\n$/, '');
      case 'text':
      default:
        let tight = this.md.render(mdText).replace(/^<p>/, '').replace(/<\/p>\n$/, '');
        return html2text.htmlToText(tight);
    }
  }

  static async copyToClipboard(codeOnly: boolean = false, text: string, format: string = 'text') {
    if (navigator.clipboard) {
      var msg = '';
      var hasCode = text.indexOf('```') > -1;

      if (codeOnly && hasCode) {
        msg = Message.extractCodeBlocks(text);
      }
      else {
        msg = text;
      }

      await this.initMd();
      let toPaste = '';

      if (format === 'html') {
        toPaste += `<style>
.code-grid {
  display: grid;
  grid-template-rows: auto 1fr;
}

</style>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/default.min.css">
<script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/highlight.min.js"></script>

<!-- and it's easy to individually load additional languages -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/languages/go.min.js"></script>
`
      }

      msg = this.messageToText(msg, format);

      await navigator.clipboard.writeText(msg);
    }
  }
}

export enum EditMode {
  Conversation,
  Settings,
  Models,
  Model
}

interface AzureModel {
  id: string;
  name: string;
  sku: {
    name: string;
    capacity: number;
  };
  properties: {
    model: {
      format: string;
      name: string;
      version: string;
    };
    currentCapacity: number;
    capabilities: Record<string, string>;
    provisioningState: string;
  };
  systemData: {
    createdBy: string;
    createdAt: string;
    lastModifiedBy: string;
    lastModifiedAt: string;
  };
  etag: string;
}

interface AzureResponse {
  value: AzureModel[];
}

export class TextReply {
  text: string;
  isDone: boolean = false;
  ms?: number;
  isError?: boolean = false;

  static create(text: string, isDone: boolean = false, ms: number = 0) {
    const reply = new TextReply();
    reply.text = text;
    reply.isDone = isDone;
    reply.ms = ms;
    return reply;
  }
}

export class AzureConfigModel {
  apiBase: string;
  apiType: string;
  apiVersion: string;
  gptVersion: string;
  maxTokens: number;
  modelName: string;
  name: string;
}

interface AITemplates {
  [key: string]: {
    many_with_sys?: {
      topKey?: string;
      system?: string;
      user_prev?: string;
      assistant_prev?: string;
      user?: string;
      type?: string; //array or string
    };
    one_with_sys?: {
      topKey?: string;
      system?: string;
      user?: string;
    };
    one_without_sys?: {
      user?: string;
      mode?: string;
    };
  } | null;
}

export interface TemplateNameKey {
  key: string;
  name: string;
}

export interface AICondition {
  key: string;
  value: any;
}

export class AIProperty {
  visibility: number;
  key: string;
  name: string;
  source: string;
  required: boolean;
  password?: boolean;
  acquire?: string;
  acquireText?: string;
  value?: string;
  type?: string = 'string';
  afterButton?: string;
  condition?: AICondition;
  placeholder?: string;

  constructor(
    visibility: number,
    key: string,
    name: string,
    source: string,
    required: boolean,
    password?: boolean,
    acquire?: string,
    value?: string,
    acquireText?: string,
    type?: string,
    afterButton?: string,
    condition?: AICondition,
    placeholder?: string
  ) {
    this.visibility = visibility;
    this.key = key;
    this.name = name;
    this.source = source;
    this.required = required;
    this.password = password;
    this.acquire = acquire;
    this.value = value;
    this.acquireText = acquireText;
    this.type = type;
    this.afterButton = afterButton;
    this.condition = condition;
    this.placeholder = placeholder;
  }
}

export class AIService {
  id: string;
  name: string;
  icon: string;
  comm: string;
  properties: AIProperty[];
  modelsUrl: string;
  specialCondition?: string;
  addModels?: boolean;
  modelProperties?: AIProperty[];

  constructor(
    id: string,
    name: string,
    icon: string,
    comm: string,
    properties: AIProperty[],
    modelsUrl: string,
    specialCondition: string,
    addModels: boolean,
    modelProperties: AIProperty[]
  ) {
    this.id = id;
    this.name = name;
    this.icon = icon;
    this.comm = comm;
    this.properties = properties;
    this.modelsUrl = modelsUrl;
    this.specialCondition = specialCondition;
    this.addModels = addModels;
    this.modelProperties = modelProperties;
  }
}

export class AIConfig {
  version: number;
  services: AIService[];

  constructor(version: number, services: AIService[]) {
    this.version = version;
    this.services = services;
  }

  static async load() {
    const configData = await fetch('/assets/data/allservices.json')
      .then((response: Response) => response.json());

    const services = configData.services.map((service: any) => {
      if (service.specialCondition) {
        if (service.specialCondition === 'window.ai') {
          if (!(window as any).ai) {
            return null;
          }
        }
      }

      const properties = !service.properties ? [] : service.properties.map((prop: any) => new AIProperty(
        prop.visibility,
        prop.key,
        prop.name,
        prop.source,
        prop.required,
        prop.password,
        prop.acquire,
        prop.value,
        prop.acquireText,
        prop.type,
        prop.afterButton,
        prop.condition,
        prop.placeholder
      ));

      return new AIService(
        service.id,
        service.name,
        service.icon,
        service.comm,
        properties,
        service.modelsUrl,
        service.specialCondition,
        service.addModels,
        service.modelProperties
      );
    }).filter(s => s != null);

    const config = new AIConfig(configData.version, services);

    return config;
  }
}

export class SendMessageData {
  model: Model;
  msgs: Message[];
  areMsgsComplete: boolean = false;
  infTemp: ChatSettingsInferenceTemplate | null;
  update: any;
  preInstructions: string = '';
  chatId: string;
  customTemplate: ChatSettingsCustomTemplate | null;
  sections: Section[] | null;

  constructor(model: Model, msgs: Message[], update: any, chatId: string, preInstructions: string = '') {
    this.model = model;
    this.msgs = msgs;
    this.update = update;
    this.chatId = chatId;
    this.preInstructions = preInstructions;
  }
}