declare global {
  interface Window {
    katex: any;
  }
}

export class LazyKatex {
  static async loadKatex(): Promise<void> {
    if (typeof window.katex === 'undefined') {
      const katexModule = await import('@xtthaop/markdown-it-katex');
      window.katex = katexModule.default; // or katexModule if it exports directly
    }
  }
}