import { ThrottlerRequest } from "global/x-mgr/x-mgr";
import { ChatSettingsInferenceTemplate, Message, Model, ModelSets } from "./Model";
import { OpenAIComm } from "./OpenAIComm";
import { ZService } from "./ZSettingsfn";
import { fetchWithToken } from "global/utils";
import { XAuthLocal } from "global/auth/xauth";
import { Comm } from "./Comm";

export class AzureOpenAIComm extends OpenAIComm {
    async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {
        var apiKey = this.apiKey;
        if (model && model.azureInfo) {
            const service = ModelSets.getService(model);
            if (service.useRemoteConfig && service.remoteConfigURL) {
                const auth = ModelSets.getAuthFor(service);
                try {
                    apiKey = await this.requestApiKeyFromRemoteConfig(service, model, auth);
                } catch (e) {
                    console.error(e);
                    apiKey = 'unauthorized';
                    throw new Error('unauthorized');
                }
            }
        }

        const key = 'api-key';
        const value = `${apiKey}`;
        return { key, value };
    }

    private async requestApiKeyFromRemoteConfig(service: ZService, model: Model, auth: XAuthLocal): Promise<string> {
        const abortController = new AbortController();
        const signal = abortController.signal;
        const key = model.id;

        const requestSlots = async (token: string) => {
            try {
                const iPri = 5;

                const request: ThrottlerRequest = {
                    key: key,
                    owner: await auth.getMyUserName(),
                    app: 'crosscodex',
                    instance: '1',
                    requestedSlots: 1,
                    priority: iPri
                };
                console.log('th:requesting');
                var apiURL = service.remoteConfigURL;
                if (apiURL.endsWith('/')) {
                    apiURL = apiURL.substring(0, apiURL.length - 1);
                }
                const response = await fetchWithToken(apiURL + '/api/throttle/request', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(request),
                    signal,
                    token: token
                }, token);
                return await response.json();
            } catch (error) {
                console.log('th:requesting...error');
                if (error.name === 'AbortError') {
                    console.log('Request slots aborted');
                } else {
                    throw error;
                }
            }
        };

        const token = await auth.getToken();
        const response = await requestSlots(token);
        return response.values.OpenAIKey;
    }

    getURLwithSuffix(url, model: Model) {
        // this.fixModelId(model.id)
        // if url has / on end, remove it
        if (model.azureInfo && model.azureInfo.apiBase) {
            url = model.azureInfo.apiBase;
        }

        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }

        var suffix = '/chat/completions';
        if (model.id.indexOf('tts') > -1 || model.id.indexOf('speech') > -1) {
            suffix = '/audio/speech';
        } else if (model.id.indexOf('whisper') > -1) {
            suffix = '/audio/transcriptions';
        }

        const apiVersion = model.azureInfo?.apiVersion || this.apiVersion;
        const modelId = model.azureInfo?.modelName || model.id;

        return url + '/' + 'openai/deployments/' + modelId + suffix + '?api-version=' + apiVersion;
    }

    async getModels() {
        //const models = this.ge
    }

    async getModelsClass(service: ZService = null): Promise<Model[]> {
        ZService.debugServices([service], 'AzureOpenAIComm.getModelsClass:1');
        if (this.wasCalled) {
            return service ? service.models : [];
        }

        this.wasCalled = true;
        try {
            var models = Model.loadModelsFromService(service, this);
            if (service.useRemoteConfig && service.remoteConfigURL) {

                //if (!service.models || service.models.length == 0) {
                // fetch remote config
                const publicData = await fetch(service.remoteConfigURL + "/api/public?app=crosscodex");
                let newModels = [];
                if (publicData.status != 200) {
                    return [];
                }
                try {
                    const config = await publicData.json();
                    newModels = config.models.map((m: any) => {
                        return Model.constructAzureConfigModel(m);
                    });
    
                    service.config = config;
                }
                catch (ez) {
                    const text = await publicData.text();
                    console.error('azureopenai failed to return data', ez);
                }
                
                if (service.apiURL && service.apiURL.indexOf('api.openai.com') >= 0) {
                    service.apiURL = '';
                    service.apiKey = '';
                }

                newModels.map(nm => {
                    const existing = models.find(m => m.id == nm.id && m.azureInfo.apiBase == nm.azureInfo.apiBase);
                    if (existing) {
                        existing.azureInfo = nm.azureInfo;
                    }
                    else {
                        models.push(nm);
                    }
                });

                const json = await Comm.getAllModelsJson();
                const orModels = Model.loadModels(json.openrouter, this);
                models.map(m => {
                    var orModel = orModels.find(om => om.id.endsWith(m.id)) ?? orModels.find(om => om.id.replace('.', '').endsWith(m.id));
                    if (!orModel) {
                        if (m.id === 'speech' || m.id.indexOf('tts') > -1) {
                            m.architecture = { modality: 'voice', tokenizer: 'gpt', instruct_type: null };
                        }
                        if (m.id.indexOf('whisper') > -1) {
                            m.architecture = { modality: 'audio', tokenizer: 'gpt', instruct_type: null };
                        }
                    }
                    if (orModel && (!m.architecture || !m.architecture.modality)) {
                        m.architecture = orModel.architecture;
                    }
                    if (orModel && (!m.contextLength || m.contextLength == 0)) {
                        m.contextLength = orModel.contextLength;
                    }
                    if (orModel && (!m.pricing || m.pricing.completion == '?')) {
                        m.pricing = orModel.pricing;
                    }
                })

                service.models = models; //HACK??
            }

            models.map(m => {
                m.serviceUid = service.uid;
            });

            this.isConnected = true;

            if (models.filter(m => m.isSelected).length === 0) {
                const gpt4o = models.find(m => m.id === 'gpt-4o');
                if (gpt4o) {
                    gpt4o.isSelected = true;
                }
            }

            return models;
        }
        catch (e) {
            console.error('getModelsClass: ' + e);
            this.isConnected = false;
            return [];
        }
    }

    async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
        console.log('AzureOpenAIComm.callDirect');
        return super.callDirect(instruction, infTemp, model);
    }

    async callX(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
        console.log('AzureOpenAIComm.call');
        return super.callX(instruction, msgs, infTemp, temperature, update, model, chatId);
    }
}
