import { ZServiceTemplate, ZSettings } from "./ZSettingsfn";
import { eventBus } from "global/event-bus";

export class BrowserCommExtra {

  static async addDefaults(zsettings: ZSettings, force: boolean = false, templates: ZServiceTemplate[] = null) {
    if (!(window as any).ai) {
      return;
    }

    window.setTimeout(async () => {
      const services = zsettings.services;
      var bc = services.find(s => s.commType === 'browser');
      if (!bc) {
        return;
      }
      const availableModels = BrowserCommExtra.sBrowserModels();
      const model = availableModels['browser'].data[0];
      model.isSelected = true;
      model.serviceUid = bc.uid;

      let coreModel = bc.models.find(m => m.id === 'window.ai' || m.id === 'nano');
      if (coreModel) {
        coreModel.isSelected = true;
        coreModel.serviceUid = bc.uid;
      }
      await eventBus.emit('select-model', { chatId: 'chat', model: model, isSelected: true, isShift: false, serviceUid: bc.uid });
      BrowserCommExtra.ensureAndSave(zsettings);
    }, 1500)
  }

  static ensureAndSave(zsettings: ZSettings) {
    const service = zsettings.services.find(z => z.name === this.name);
    if (service && service.models && service.models.length > 0) {
      service.models[0].isSelected = true;
      service.apiKey = 'window.ai';
      zsettings.save('services');
    }
  }

  static sBrowserModels() {
    if (!(window as any).ai) {
      return {};
    }

    const models = {
      "browser": {
        "keyUrl": "",
        "data": []
      }
    };

    models['browser'].data.push({
      "id": "window.ai",
      "object": "model",
      "created": 1693721698,
      "owned_by": "browser",
      "active": true,
      "context_window": 4096,
      "isSelected": true
    });

    return models;
  }
}